import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Modal,
  Box,
  Typography,
  TextField,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  borderRadius: "8px",
  p: 0,
  width: { xs: "90%", md: "40%" },
};

const CommanModal = ({
  open,
  headerText,
  subheaderText,
  toggle,
  body,
  minWidth,
  footer = true,
}) => {
  // style.minWidth = minWidth ? minWidth : style.minWidth;
  const inputRef = useRef(null);
  const [againopen, setAgainOpen] = useState(false);
  useEffect(() => {
    let timeout = setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
        const length = inputRef.current.value.length;
        inputRef.current.setSelectionRange(length, length);
      }
    });

    return () => clearTimeout(timeout);
  }, [againopen, inputRef]);
  return (
    <div>
      <Modal
        open={open}
        onClose={toggle}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={style}>
          <div
            style={{ borderBottom: "1px solid #E5E7EB" }}
            className="custom-header"
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                id="modal-title"
                sx={{
                  fontFamily: "Instrument Sans",
                  fontSize: "24px",
                  color: "#6B7280",
                }}
              >
                {headerText}
              </Typography>
              <IconButton onClick={toggle}>
                <CloseIcon />
              </IconButton>
            </div>
            <Typography
              className="font-size-14"
              sx={
                subheaderText.length <= 0
                  ? { display: "none" }
                  : { mt: 1, color: "#6c757d" }
              }
            >
              {subheaderText}
            </Typography>
          </div>

          {body}

          {footer && (
            <Box
              sx={{ display: "flex", justifyContent: "end", mt: 3, mb: 3 }}
            ></Box>
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default CommanModal;
