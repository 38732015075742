import React, { useState } from "react";
import { BootStrapInput } from "../CommonComponents/Input";
import { useDispatch } from "react-redux";
import {
  loginAction,
  setSelectedAccountAction,
} from "../../Redux/Actions/Common.actions";
import { Box, Typography, Grid, Paper, CircularProgress } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import logo from "../../Assests/Images/vodexLogoBlack.svg";
import { axiosClient } from "../../Constants/Axios";
import { toast } from "react-toastify";
import Eva from "../../Assests/Images/Eva.svg";
import Joey from "../../Assests/Images/Joey.svg";
import Hen from "../../Assests/Images/hen.svg";
import WavesSVG from "../CommonComponents/CommonUiComponent/WavesSVG";

const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#5865F2",
    },
  },
  typography: {
    fontFamily: "Roboto, sans-serif",
  },
});

function Login() {
  const [state, setState] = useState({
    email: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);

  const { email, password } = state;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onChange = (e) => {
    setState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const loginFunction = async () => {
    setLoading(true);
    const toastId = toast.loading("Logging In...");

    let obj = {
      email,
      password,
    };
    try {
      let res = await dispatch(loginAction(obj));

      axiosClient.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${res.token}`;
      axiosClient.defaults.headers.common["dbUrl"] = `${
        res?.accounts[0]?.accountUrl || "Vodex_1718870853046"
      }`;
      dispatch(setSelectedAccountAction(res?.accounts[0]));
      navigate("/main/getting-started");
      toast.update(toastId, {
        render: "Welcome",
        type: "success",
        isLoading: false,
        autoClose: 3000,
      });
    } catch (error) {
      toast.update(toastId, {
        render: "Login failed. Please try again.",
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          background: "linear-gradient(180deg, #0C0759 0%, #4F46E5 100%)",
          minHeight: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          paddingY: "4rem",
          paddingX: {
            xs: "2rem",
            md: "4rem",
            lg: "6rem",
          },
          position: "relative",
          overflow: "hidden"
        }}
      >
        <Grid container spacing={2} alignItems="center" sx={{ color:"white"}}>
          <Grid item xs={12} md={6}>
            <Box sx={{ mb: 10 }}>
              <Box sx={{ textAlign: "left" }}>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "2.5rem",
                  }}
                >
                  Meet Your Sales Team
                </Typography>
                <Typography
                  sx={{
                    fontWeight: "semibold",
                    fontSize: "22px",
                  }}
                >
                  Your next Gen AI agents
                </Typography>
              </Box>
            </Box>

            <Grid
              container
              textAlign="center"
              spacing={{ xs: 2, md: 4 }}
              sx={{
                position: "relative",
                zIndex: 2,
              }}
            >
              <Grid item xs={4}>
                <img
                  src={Eva}
                  alt="Eva, AI sales agent"
                  style={{
                    width: "100%",
                    maxWidth: "200px",
                    height: "auto",
                    maxHeight: "250px",
                  }}
                />
                <Typography sx={{ marginTop: "0.5rem" }}>
                  Eva, AI sales agent
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <img
                  src={Joey}
                  alt="Joey, AI sales agent"
                  style={{
                    width: "100%",
                    maxWidth: "200px",
                    height: "auto",
                    maxHeight: "250px",
                    marginTop: "6rem",
                  }}
                />
                <Typography sx={{ marginTop: "0.5rem", zIndex: 2 }}>
                  Joey, AI sales agent
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <img
                  src={Hen}
                  alt="Hen, AI sales agent"
                  style={{
                    width: "100%",
                    maxWidth: "200px",
                    height: "auto",
                    maxHeight: "250px",
                  }}
                />
                <Typography sx={{ marginTop: "0.5rem" }}>
                  Hen, AI sales agent
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={6}>
            <Paper
              elevation={6}
              sx={{
                paddingX: "2rem",
                paddingY: "6rem",
                maxWidth: "500px",
                margin: "auto",
                borderRadius: "12px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                position: "relative",
                zIndex: 2,
              }}
            >
              <img
                src={logo}
                alt="logo"
                style={{
                  marginBottom: "1rem",
                  maxWidth: "100%",
                  height: "32px",
                  width: "32px",
                }}
              />

              <Typography
                variant="h5"
                align="center"
                gutterBottom
                sx={{
                  color: "#1C203E",
                  fontWeight: "600",
                  fontSize: "22px",
                }}
              >
                Welcome back to ZEN!
              </Typography>
              <Typography
                variant="body1"
                align="center"
                sx={{ color: "#6C7594", marginBottom: "2rem" }}
              >
                Please enter your credentials to sign in!
              </Typography>

              <form noValidate autoComplete="off">
                <BootStrapInput
                  value={email}
                  name={"email"}
                  label={"Email"}
                  onChange={onChange}
                  required={false}
                  className={"mb-3 w-100"}
                  style={{
                    borderColor: "gray",
                    textColor: "gray",
                  }}
                />
                <BootStrapInput
                  value={password}
                  name={"password"}
                  label={"Password"}
                  onChange={onChange}
                  type={"password"}
                  required={false}
                  className={"mb-3 w-100"}
                />

                <button
                  type="button"
                  onClick={loginFunction}
                  style={{
                    background: "#4F46E5",
                    color: "#fff",
                    padding: "7px",
                    border: "none",
                    borderRadius: "8px",
                    fontWeight: "semibold",
                    fontSize: "14px",
                    width: "100%",
                    marginTop: "1rem",
                    cursor: loading ? "not-allowed" : "pointer",
                    opacity: loading ? 0.6 : 1,
                  }}
                  disabled={loading}
                >
                  {loading ? <CircularProgress size={24} color="inherit" /> : "Login"}
                </button>
              </form>
            </Paper>
          </Grid>
        </Grid>

        <WavesSVG
          style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            width: "100%",
            height: "auto",
            opacity: 0.6,
            zIndex: 1,
          }}
        />
      </Box>
    </ThemeProvider>
  );
}

export default Login;
