import React from "react";
import FirstPage from "./FirstPage";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
  useLocation,
} from "react-router-dom";
import ProjectPage from "./ProjectPage";
import SDRSetup from "./SDRSetup";
import Hubspot from "./Hubspot";
import CompanyDetails from "./CompanyDetails";
import RedirectConnectionPage from "./RedirectConnectionPage";
import SDRSetupMeeting from "./SDRSetupMeeting";
import CancelIcon from "@mui/icons-material/Cancel";
function OnboardingRoutes() {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  let url = ["/get-started", "/get-started/redirect-to-vodex"];

  return (
    <>
      {" "}
      {!url?.includes(pathname) && (
        <CancelIcon
          titleAccess="Continue to dashboard"
          onClick={() => {
            localStorage.removeItem("projectEditData");
            navigate("/main/projects");
          }}
          className="m-3 cursor-pointer"
          style={{ float: "right", borderRadius: "5px" }}
        ></CancelIcon>
      )}
      <Routes>
        <Route path="/create-project" element={<ProjectPage />} />
        <Route path="/sdr-setup-meeting" element={<SDRSetupMeeting />} />
        <Route path="/sdr-setup" element={<SDRSetup />} />
        <Route path="/hubspot-setup" element={<Hubspot />} />
        <Route path="/company-details" element={<CompanyDetails />} />
        {/* <Route path="/get-started" element={<FirstPage />} /> */}
        <Route path="/redirect-to-vodex" element={<RedirectConnectionPage />} />
      </Routes>
    </>
  );
}

export default OnboardingRoutes;
