import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Button,
  Divider,
  Popover,
} from "@mui/material";
import NotificationsIcon from "@mui/icons-material/NotificationsOutlined";
import CustomScrollbar from "../../CommonComponents/CustomScrollbar";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import DoubleTick from "../../../Assests/Images/Icons/DoubleTick.png";
import Subtract from "../../../Assests/Images/Icons/Subtract.svg";
import bellIcon from "../../../Assests/Images/Icons/bellIcon.svg";

// const notifications = [
//   {
//     id: 1,
//     name: "Sara Johnson",
//     action: "mentioned you in a comment in a thread",
//     context: "Project List",
//     time: "2 mins ago",
//     avatar: "https://randomuser.me/api/portraits/women/1.jpg",
//   },
//   {
//     id: 2,
//     name: "Nick Jonas",
//     action: "created a new goal in the",
//     context: "design and development",
//     time: "30 mins ago",
//     avatar: "https://randomuser.me/api/portraits/men/2.jpg",
//   },
//   {
//     id: 3,
//     name: "Matt Hardy",
//     action: "requested to upgrade plan.",
//     time: "1 hr ago",
//     avatar: "https://randomuser.me/api/portraits/men/3.jpg",
//     buttons: true,
//   },
//   {
//     id: 4,
//     name: "Netty Miller",
//     action: "uploaded a design file.",
//     context: "landing_page_Version2.fig",
//     time: "3 hrs ago",
//     avatar: "https://randomuser.me/api/portraits/women/4.jpg",
//   },
// ];

const NotificationPanel = () => {
  const [notifications, setNofifications] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "notification-popover" : undefined;
  
  const systemConstantAccount = useSelector((state) => state.AccountReducer.systemConstantAccount );
  const projectList = useSelector((state) => state.ProjectReducer.projectList );

  useEffect(() => {
    const notificationData = systemConstantAccount?.find(
      (o) => o.type === "NOTIFICATION"
    );

    if (notificationData?.details && notificationData?.details?.length > 0) {
      setNofifications([...notificationData?.details?.reverse()]);
    }
  }, [systemConstantAccount]);

  const getProjectName = (id) => {
    const projectName = projectList?.find((o) => o._id === id)?.projectName;
    return projectName;
  };

  const getUrl = (data) => {
    return `/main/projects/${data?.projectId}/training-logs`;
  };

  //  today and older
  const today = moment().startOf("day");
  const todayNotifications = notifications
    .filter((notification) =>
      moment(notification.timeStamp).isSame(today, "day")
    )
    .sort((a, b) => b.timeStamp - a.timeStamp);

  const olderNotifications = notifications
    .filter(
      (notification) => !moment(notification.timeStamp).isSame(today, "day")
    )
    .sort((a, b) => b.timeStamp - a.timeStamp);

  // time since notification
  const getTimeSinceNotification = (timestamp) => moment(timestamp).fromNow();

  const renderNotifications = (notifications, label) => {
    return (
      <>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            height: "50px",
            background: "#F3F7FD",
          }}
        >
          <Typography
            className="font-size-18"
            variant="subtitle2"
            color="#6B7280"
            fontFamily="Red Hat Display"
            fontWeight={600}
            ml="33px"
            fontSize="18px"
          >
            {label}
          </Typography>
        </Box>
        {notifications?.map((notification,index) => (
          <React.Fragment key={index}>
            <ListItem alignItems="flex-start">
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingTop: "16px",
                }}
              >
                <ListItemAvatar
                  style={{ minWidth: 0, padding: "0 18px 0 13px" }}
                >
                  <img alt={notification.name} src={Subtract} />
                </ListItemAvatar>
                <Box sx={{ width: "500px" }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "16px",
                    }}
                  >
                    <Typography
                      className="font-size-18 font-weight-500 "
                      sx={{
                        width: "410px",
                        color: "#000712",
                      }}
                      component="span"
                      variant="body2"
                    >
                      {notification.msg === "Live Agent required." ? (
                        <a
                          style={{ color: "#000712" }}
                          href={notification?.meetingUrl}
                          target="_blank"
                        >
                          {" "}
                          {notification?.msg}{" "}
                        </a>
                      ) : (
                        <Link
                          className="font-weight-500, font-size-18"
                          style={{
                            color: "#000712",
                            textDecoration: "none",
                          }}
                          to={getUrl(notification)}
                        >
                          {" "}
                          {notification.msg}{" "}
                        </Link>
                      )}
                    </Typography>
                    <Typography
                      className="font-weight-400 font-size-14"
                      sx={{
                        color: "#6B7280",
                        fontFamily: "Red Hat Display",
                      }}
                    >
                      {getTimeSinceNotification(notification.timeStamp)}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      component="span"
                      variant="body2"
                      sx={{
                        fontSize: "18px",
                        fontWeight: 500,
                        color: "#6B7280",
                        fontFamily: "Red Hat Display",
                      }}
                    >
                      Project Name : {getProjectName(notification?.projectId)}
                      <Typography
                        component="span"
                        variant="body2"
                        color="primary"
                      >
                        {notification?.context}
                      </Typography>
                    </Typography>
                    <Typography
                      variant="caption"
                      display="block"
                      sx={{
                        fontSize: "14px",
                        fontWeight: 400,
                        color: "#6B7280",
                        fontFamily: "Red Hat Display",
                      }}
                    >
                      {moment(notification?.timeStamp || "").format(
                        "MMMM Do YYYY - h:mm a"
                      )}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              {notification.buttons && (
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-end"
                >
                  <Button size="small" color="primary">
                    Accept
                  </Button>
                  <Button size="small" color="secondary">
                    Decline
                  </Button>
                </Box>
              )}
            </ListItem>
          </React.Fragment>
        ))}
      </>
    );
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-bewtween",
          alignItems: "center",
          paddingTop: "4px",
        }}
        onClick={handleClick}
      >
        <img src={bellIcon} alt="" srcSet="" />
        <Typography ml={2} fontSize="14px">
          Notifications
        </Typography>
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box
          sx={{
            width: 600,
            bgcolor: "background.paper",
            borderRadius: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "30px 33px",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Red Hat Display",
                color: "#6B7280",
                fontSize: "24px",
                fontWeight: 600,
              }}
              variant="h6"
            >
              Notifications
            </Typography>
            <Button size="small" color="primary">
              <span className="mr-3">
                <img src={DoubleTick} />
              </span>{" "}
              Mark all as read
            </Button>
          </Box>
          <CustomScrollbar height={"400px"}>
            <List sx={{ paddingTop: 0 }}>
              {todayNotifications.length > 0 &&
                renderNotifications(todayNotifications, "Today")}
              {olderNotifications.length > 0 &&
                renderNotifications(olderNotifications, "Older")}
            </List>
          </CustomScrollbar>

          {/* <Box display="flex" justifyContent="center" mt={2}>
            <Button size="small" color="primary">
              View all notifications
            </Button>
          </Box> */}
        </Box>
      </Popover>
    </>
  );
};

export default NotificationPanel;
