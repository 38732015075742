import React, { useEffect, useState } from "react";
import TopRightLogo from "../CommonComponents/TopRightLogo";
import { BootStrapInput } from "../CommonComponents/Input";
import { Dropzone, FileMosaic } from "@dropzone-ui/react";
import "../../Assests/Css/Common.css";
import { useOnboardingState } from "./OnboardingContext";
import { getXPathIdAction } from "../../Redux/Actions/Common.actions";
import AddIcon from "@mui/icons-material/Add";
import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch } from "react-redux";
import { getSitemapUrlsAction } from "../../Redux/Actions/AI.actions";
import { debounce, RequiredStar } from "../CommonComponents/Common";
import CustomScrollbar from "../CommonComponents/CustomScrollbar";
import { useNavigate } from "react-router-dom";
import WelcomeCard from "../CommonComponents/ProgressContainer";
import {
  ArrowBackIcon,
  CloudUploadIcon,
  FormHeader,
  InfoIcon,
} from "../CommonComponents/IconsPage";
import CustomModalInput from "../CommonComponents/CustomModalInput";

function SDRSetupMeeting() {
  const {
    onBoardingState,
    setOnBoardingState,
    setWebsiteUrls,
    setFiles,
    files,
    websiteUrl,
    updateProject,
  } = useOnboardingState();

  const { introMessageForMeetings, mainUrl, projectId } = onBoardingState;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [xpathId, setXpathId] = useState("");
  const updateFiles = (incommingFiles) => {
    setFiles(incommingFiles);
  };

  const fetchData = async (value) => {
    let obj = {
      website: value,
      skip_ethics: false,
    };
    let res = await dispatch(getSitemapUrlsAction(obj));
    if (res?.urls?.length > 0) {
      let localUrls = res.urls.map((url) => url.split(value)[1]);
      setWebsiteUrls([...localUrls]);
    }
  };
  const debouncedFetchData = debounce(fetchData, 5000);

  const onChange = (e) => {
    if (e.target.name === "mainUrl") {
      debouncedFetchData(e.target.value);
    }
    setOnBoardingState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };
  const onChangeWeb = (value, idx) => {
    let copy = websiteUrl;
    copy[idx] = value;

    setWebsiteUrls([...copy]);
  };

  const deleteWeb = async (idx) => {
    let copy = websiteUrl;
    copy.splice(idx, 1);
    setFiles([...copy]);
  };
  const addWeb = async (idx) => {
    let copy = websiteUrl;
    setWebsiteUrls(["", ...copy]);
  };
  const label = { inputProps: { "aria-label": "Switch demo" } };

  useEffect(() => {
    const init = async () => {
      let xpathIdRes = await dispatch(getXPathIdAction(projectId));
      setXpathId(xpathIdRes?.data);
    };
    init();
  }, []);
  const [openModals, setOpenModals] = useState({
    greeting: false,
    context: false,
    voicemail: false,
    rules: false,
  });
  const toggle = (name) => {
    setOpenModals((prev) => ({
      ...prev,
      [name]: !prev[name],
    }));
  };
  return (
    <div
      style={{
        height: "100%",
      }}
      className="white-bg"
    >
      <TopRightLogo black={true} />
      <br />
      <Grid container className="mt-14 ml-5">
        <Grid item xs={12} md={4}>
          {" "}
          <WelcomeCard value={"50"} />
        </Grid>
        <Grid item xs={12} md={8}>
          <Box>
            <Typography
              className="font-size-15 default-border"
              color="black"
              sx={{
                padding: "15px",
                width: "65%",
                marginBottom: "10px",
              }}
            >
              <InfoIcon className="mr-2" />
              It’s just 3 steps to go! Your AI assistant is waiting for you
              there.
            </Typography>
            <Typography className="font-size-15" color="black">
              <ArrowBackIcon
                onClick={() => navigate("/get-started/sdr-setup")}
                className="mr-2 cursor-pointer"
              />
            </Typography>
            <FormHeader className="color-black font-size-22">
              Meeting training
              <Typography className="font-size-12 color-black">
                Let’s collaborate to build your project and create something
                extraordinary.
              </Typography>
            </FormHeader>{" "}
            <CustomScrollbar height="46vh">
              <Grid container spacing={1}>
                <Grid item lg={12}>
                  <CustomModalInput
                    label={"Context For Meeting"}
                    value={introMessageForMeetings}
                    name={"introMessageForMeetings"}
                    onChange={onChange}
                    className={"w-80"}
                    open={openModals["greeting"]}
                    toggle={() => toggle("greeting")}
                    headerText={`Hey Hi I’m Your Meeting bot`}
                    subheaderText={
                      "Mention what you expect me to speak on meeting"
                    }
                    required={true}
                  />
                </Grid>
                <Grid item lg={12}>
                  <Dropzone
                    style={{
                      border: "2px dashed #ced4da",
                      borderRadius: "10px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      padding: "20px",
                      backgroundColor: "#f8f9fa",
                    }}
                    className="w-80"
                    onChange={updateFiles}
                    value={files}
                    accept=".pdf, .xls, .xlsx, application/pdf, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    label="Drop your files here"
                    iconContent={<CloudUploadIcon />}
                  >
                    {files.map((file) => (
                      <FileMosaic {...file} preview />
                    ))}
                  </Dropzone>
                </Grid>
                <Grid item lg={12}>
                  <BootStrapInput
                    label={"Website Base Url"}
                    value={mainUrl}
                    name={"mainUrl"}
                    onChange={onChange}
                    className={"w-75"}
                    required={true}
                  />
                  <IconButton
                    sx={{
                      background: "var(--default-gradient-color)",
                      borderRadius: "5px",
                      color: "white",
                    }}
                    title="Training Status"
                    onClick={addWeb}
                    className="mt-7 ml-2"
                  >
                    <AddIcon />
                  </IconButton>
                  <CustomScrollbar height="20vh">
                    {websiteUrl.map((web, idx) => (
                      <div className="mt-2" key={`web${idx + 1}`}>
                        <TextField
                          variant="outlined"
                          value={web}
                          onChange={(e) => onChangeWeb(e.target.value, idx)}
                          sx={{
                            borderRadius: "10px",
                            "& .MuiOutlinedInput-root": {
                              borderRadius: "5px",
                              paddingRight: "0px", // Align the icon with the border
                            },
                            "& .MuiInputBase-input": {
                              padding: "10px 14px", // Adjust padding inside the input
                            },
                          }}
                          className={"m-0 w-50"}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  sx={{
                                    borderRadius: "5px",
                                    color: "#6B7280",
                                  }}
                                  title="Training Status"
                                  onClick={() => deleteWeb(idx)}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                    ))}
                  </CustomScrollbar>
                </Grid>

                <Grid item>
                  <label className="color-black">
                    Product Demo <RequiredStar />{" "}
                    <span className="font-size-10">
                      (Do you want to record product demo?)
                    </span>
                  </label>
                  <Grid
                    container
                    style={{
                      padding: "30px",
                      borderRadius: "8px",
                      minWidth: "132%",
                    }}
                    className="default-border   "
                  >
                    <Grid item lg={10}>
                      <Typography className="color-black font-size-14 ">
                        Step - 1 Download the chrome extension
                        <br />
                        Step - 2 Copy mentioned Id and paste it when extension
                        will ask
                        <br />
                        {xpathId}
                      </Typography>
                    </Grid>
                    <Grid item lg={2}>
                      <Typography className="color-black font-size-14 mt-3">
                        <Switch {...label} defaultChecked />
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CustomScrollbar>
          </Box>
        </Grid>
      </Grid>

      <div className="d-flex justify-content-center mt-1">
        <button
          className="skip-button  mr-2"
          onClick={() => navigate("/get-started/hubspot-setup")}
        >
          Skip & Next
        </button>
        <button
          className="gradient-button "
          onClick={() => updateProject("/get-started/hubspot-setup")}
        >
          Save & Next
        </button>
      </div>
    </div>
  );
}

export default SDRSetupMeeting;
