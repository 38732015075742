import React, { useState } from "react";
import TopRightLogo from "../CommonComponents/TopRightLogo";
import Steppers from "../CommonComponents/Steppers";
import {
  BootStrapInput,
  Input,
  MultiLineGrowInput,
  MultiLineInput,
} from "../CommonComponents/Input";
import "../../Assests/Css/Common.css";
import { useOnboardingState } from "./OnboardingContext";

import { Box, Grid, Typography } from "@mui/material";
import { useDispatch } from "react-redux";

import CustomScrollbar from "../CommonComponents/CustomScrollbar";
import { useNavigate } from "react-router-dom";
import WelcomeCard from "../CommonComponents/ProgressContainer";
import { FormHeader, InfoIcon } from "../CommonComponents/IconsPage";
import CustomModalInput from "../CommonComponents/CustomModalInput";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
let items = [
  {
    indx: 1,
  },
  {
    indx: 2,
  },
  {
    indx: 3,
  },
];

function SDRSetup() {
  const { onBoardingState, setOnBoardingState, updateProject } =
    useOnboardingState();

  const navigate = useNavigate();

  const {
    greetingMessageForCalls,
    voicemailMessage,
    context,
    agentName,
    organization,
    botRules,
  } = onBoardingState;
  const dispatch = useDispatch();

  const onChange = (e) => {
    setOnBoardingState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const [openModals, setOpenModals] = useState({
    greeting: false,
    context: false,
    voicemail: false,
    rules: false,
  });
  const toggle = (name) => {
    setOpenModals((prev) => ({
      ...prev,
      [name]: !prev[name],
    }));
  };
  return (
    <div
      style={{
        height: "100%",
      }}
      className="white-bg"
    >
      <TopRightLogo black={true} />
      <br />
      <Grid container className="mt-14 ml-5">
        <Grid item xs={12} md={4}>
          {" "}
          <WelcomeCard value={"25"} />
        </Grid>
        <Grid item xs={12} md={8}>
          <Box>
            <Typography
              className="font-size-15 default-border"
              color="black"
              sx={{
                padding: "15px",
                width: "65%",
                marginBottom: "10px",
              }}
            >
              <InfoIcon className="mr-2" />
              It’s just 4 steps to go! Your AI assistant is waiting for you
              there.
            </Typography>
            <Typography className="font-size-15" color="black">
              <ArrowBackIcon
                onClick={() => navigate("/get-started/create-project")}
                className="mr-2 cursor-pointer"
              />
            </Typography>
            <FormHeader className="color-black font-size-22">
              Call Training
              <Typography className="font-size-12 color-black">
                Let’s collaborate to build your project and create something
                extraordinary.
              </Typography>
            </FormHeader>{" "}
            <CustomScrollbar height="46vh">
              <Grid container spacing={1}>
                <Grid item lg={12}>
                  <BootStrapInput
                    label={"Agent Name"}
                    value={agentName}
                    name={"agentName"}
                    onChange={onChange}
                    className={"w-80"}
                  />
                </Grid>
                <Grid item lg={12}>
                  <BootStrapInput
                    label={"Organization Name"}
                    value={organization}
                    name={"organization"}
                    onChange={onChange}
                    className={"w-80"}
                  />
                </Grid>
                <Grid item lg={12}>
                  <CustomModalInput
                    label={"Greeting Message"}
                    value={greetingMessageForCalls}
                    name={"greetingMessageForCalls"}
                    onChange={onChange}
                    className={"w-80"}
                    open={openModals["greeting"]}
                    toggle={() => toggle("greeting")}
                    headerText={`Hey Hi I’m ${agentName}`}
                    subheaderText={
                      "Mention what you expect me to speak on greeting"
                    }
                  />
                </Grid>

                <Grid item lg={12}>
                  <CustomModalInput
                    label={"Voicemail Message"}
                    value={voicemailMessage}
                    name={"voicemailMessage"}
                    onChange={onChange}
                    className={"w-80"}
                    open={openModals["voicemail"]}
                    toggle={() => toggle("voicemail")}
                    headerText={`Hey Hi I’m ${agentName}`}
                    subheaderText={
                      "Mention what you expect me to speak on voicemail"
                    }
                  />
                </Grid>

                <Grid item lg={12}>
                  <CustomModalInput
                    label={"Context For Call"}
                    value={context}
                    name={"context"}
                    onChange={onChange}
                    className={"w-80"}
                    open={openModals["context"]}
                    toggle={() => toggle("context")}
                    headerText={`Hey Hi I’m ${agentName}`}
                    subheaderText={
                      "Mention what you expect me to speak on call"
                    }
                  />
                </Grid>
                <Grid item lg={12}>
                  <CustomModalInput
                    label={"Rules for call"}
                    value={botRules}
                    name={"botRules"}
                    onChange={onChange}
                    className={"w-80"}
                    open={openModals["rules"]}
                    toggle={() => toggle("rules")}
                    headerText={`Hey Hi I’m ${agentName}`}
                    subheaderText={
                      "Mention what rules you expect me to follow on call"
                    }
                  />
                </Grid>
              </Grid>
            </CustomScrollbar>
          </Box>
        </Grid>
      </Grid>
      <div className="d-flex justify-content-center mt-1">
        <button
          className="skip-button  mr-2"
          onClick={() => navigate("/get-started/sdr-setup-meeting")}
        >
          Skip & Next
        </button>
        <button
          className="gradient-button "
          onClick={() => updateProject("/get-started/sdr-setup-meeting")}
        >
          Save & Next
        </button>
      </div>
    </div>
  );
}

export default SDRSetup;
