import { dealsType } from "../Types/All.types";

const initialState = {
  dealsList: [],
  onboardingPageData: {},
  selectedDeal: {},
};

function DealsReducer(state = initialState, action) {
  switch (action.type) {
    case dealsType.GET_DEALS_LIST: {
      return { ...state, dealsList: action.payload };
    }
    case dealsType.GET_SELECTED_DEAL: {
      return { ...state, selectedDeal: action.payload };
    }
    default:
      return state;
  }
}

export default DealsReducer;
