import { API } from "../Constants/ApiRoutes";
import { BASEURL, axiosClient } from "../Constants/Axios";

export const loginService = async (data) => {
  try {
    let res = await axiosClient.post(`${BASEURL}${API.COMMON.LOGIN}`, data);
    return res;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const saveCompanyDetailsService = async (data) => {
  try {
    let res = await axiosClient.post(
      `${BASEURL}${API.COMMON.ACCOUNT_SYSTEM_CONSTANT}`,
      data
    );
    return res.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const fileUploadService = async (formData) => {
  try {
    let res = await axiosClient.post(
      `${BASEURL}${API.COMMON.FILE_UPLOAD}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return res.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const promptTrainingStartService = async (projectId) => {
  try {
    let res = await axiosClient.get(
      `${BASEURL}${API.COMMON.START_TRAINING}/${projectId}`
    );
    return res.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getCalederSlotsService = async (projectId) => {
  try {
    let res = await axiosClient.get(
      `${BASEURL}${API.COMMON.CALENDER_EVENTS}/${projectId}`
    );
    return res.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};
export const getGlobalSystemConstantService = async () => {
  try {
    let res = await axiosClient.get(
      `${BASEURL}${API.COMMON.GLOBAL_SYSTEM_CONSTANT}`
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getAccountSystemConstantService = async () => {
  try {
    let res = await axiosClient.get(
      `${BASEURL}${API.COMMON.ACCOUNT_SYSTEM_CONSTANT}`
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};


export const getXpathByProjectIdService = async (projectId) => {
  try {
    let res = await axiosClient.get(
      `${BASEURL}${API.COMMON.GET_XPATH_ID}/${projectId}`
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const rescheduleEventService = async (data) => {
  try {
    let res = await axiosClient.patch(
      `${BASEURL}${API.COMMON.RESCHEDULE_EVENT}`,{...data}
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};


export const registerForNotificationService = async (data) => {
  try {
    let res = await axiosClient.put(
      `${BASEURL}${API.COMMON.NOTIFICATION_TOKEN}`,{...data}
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};


export const getDealsHourlyService= async (str) => {
  try {
    let res = await axiosClient.get(
      `${BASEURL}${API.COMMON.DEALS_HOURLY}`
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getDealsWeeklyService= async (str) => {
  try {
    let res = await axiosClient.get(
      `${BASEURL}${API.COMMON.DEALS_WEEKLY}?${str}`
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};
export const getFlowCacheDataService= async () => {
  try {
    let res = await axiosClient.get(
      `${BASEURL}${API.COMMON.FLOW_CACHED_DATA}/account?pageIndex=1&pageSize=5000`
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};


export const getAccountDetailsService = async () => {
  try {
    let res = await axiosClient.get(
      `${BASEURL}${API.COMMON.ACCOUNT}`
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};