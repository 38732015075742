import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  LinearProgress,
  Typography,
  IconButton,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import CheckIcon from "@mui/icons-material/Check";
import styles from "../FirstStage/dashboardStyles";
import welcomeImage1 from "../../../Assests/Images/welcome_1.png";

const ProspectingStep = ({ step, initiateNow, activateNow, isCollapsed }) => (
  <Box sx={{ display: isCollapsed ? "none" : "flex", width: "100%" }}>
    <Box
      sx={{ display: "flex", flexDirection: "column", width: "50%", gap: 1.5 }}
    >
      <Box sx={{ display: "flex", justifyContent: "start", paddingLeft: 3 }}>
        <Box
          sx={{
            width: 2,
            height: "100%",
            backgroundColor: "#E1E4E8",
            borderRadius: 0.5,
            marginLeft: 3,
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "35%",
              backgroundColor: "#007BFF",
              borderRadius: 2,
              transition: "height 0.3s ease-in-out",
            }}
          />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1.25 }}>
          {step.description.map((desc, index) => (
            <Typography
              key={index}
              sx={{
                color: "#656E7F",
                marginLeft: 1.5,
                fontSize: 13,
                fontWeight: 500,
              }}
            >
              {desc}
            </Typography>
          ))}
        </Box>
      </Box>
      <Box sx={{ display: "flex", gap: 2, marginLeft: 6 }}>
        <Button
          sx={{
            bgcolor: "#4F46E5",
            color: "white",
            paddingX: 2,
            fontSize: 12,
            fontWeight: 500,
            textTransform: "none",
            "&:hover": { bgcolor: "#574fbd" },
          }}
          onClick={step.id === 1 ? initiateNow : activateNow}
        >
          {step.id === 1 ? "Initiate now" : "Activate now"}
        </Button>
        {step.id === 1 && (
          <Button
            variant="outlined"
            sx={{
              borderColor: "#5F6A8A",
              color: "#5F6A8A",
              fontSize: 12,
              fontWeight: 500,
              textTransform: "none",
            }}
          >
            I'll do it, later
          </Button>
        )}
      </Box>
    </Box>
    <Box sx={{ width: "50%", padding: 2, paddingTop: 0, maxHeight: 170 }}>
      <img
        src={welcomeImage1}
        alt="Prospecting"
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          borderRadius: "10px",
        }}
      />
    </Box>
  </Box>
);

const StepHeader = ({ step, isCollapsed, onToggle, secondStageCompleted }) => {
  const isStepOneCompleted =
    secondStageCompleted && step.id === 1 && isCollapsed;

  return (
    <Box
      px={2}
      py={1}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        bgcolor: isStepOneCompleted ? "#EBFAF2" : "transparent",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <Box
          sx={{
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
            width: 25,
            height: 25,
            borderRadius: "50%",
            border: "1px solid",
            color: isStepOneCompleted ? "#28764B" : "white",
            backgroundColor: isStepOneCompleted ? "#3D9E69" : "#4F46E5",
          }}
        >
          {isStepOneCompleted ? (
            <CheckIcon sx={{ fontSize: 16, color: "white" }} />
          ) : (
            <Typography
              sx={{ textAlign: "center", fontSize: 14, fontWeight: 500 }}
            >
              {step.id}
            </Typography>
          )}
        </Box>
        <Typography
          variant="h6"
          sx={{
            color: isStepOneCompleted ? "#28764B" : "#1A1D23",
            fontSize: 14,
            fontWeight: 600,
          }}
        >
          {step.title}
        </Typography>
      </Box>
      <IconButton onClick={onToggle}>
        {isCollapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />}
      </IconButton>
    </Box>
  );
};

const SecondStage = ({
  steps,
  initiateNow,
  activateNow,
  secondStageCompleted,
}) => {
  const [collapsedSteps, setCollapsedSteps] = useState(
    steps.reduce((acc, step) => ({ ...acc, [step.id]: step.id === 2 }), {})
  );

  useEffect(() => {
    setCollapsedSteps((prevState) => ({
      ...prevState,
      1: secondStageCompleted,
      2: !secondStageCompleted,
    }));
  }, [secondStageCompleted]);

  const toggleCollapse = (stepId) =>
    setCollapsedSteps((prevState) => ({
      ...prevState,
      [stepId]: !prevState[stepId],
    }));

  return (
    <Box sx={{ ...styles.box, marginTop: 2 }}>
      <Typography variant="h5" sx={{ fontSize: 18, fontWeight: 700 }}>
        Kick start in just
        <span style={{ color: "#4F46E5" }}>
          {secondStageCompleted ? " 1 Last step!" : " 2 steps!"}
        </span>
      </Typography>

      <Box sx={{ width: "100%" }}>
        <LinearProgress
          variant="determinate"
          value={secondStageCompleted ? 75 : 5}
          sx={styles.progress}
        />
      </Box>

      <Box sx={styles.stepBox}>
        {steps.map((step) => (
          <Box key={step.id} borderBottom="1px solid #DBE0EB">
            <StepHeader
              step={step}
              isCollapsed={collapsedSteps[step.id]}
              onToggle={() => toggleCollapse(step.id)}
              secondStageCompleted={secondStageCompleted}
            />
            <ProspectingStep
              step={step}
              initiateNow={initiateNow}
              activateNow={activateNow}
              isCollapsed={collapsedSteps[step.id]}
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default SecondStage;
