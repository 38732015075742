import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { useParams } from "react-router-dom";
import Select from "react-select";
import { Box, Grid } from "@mui/material";
import { updateProjectsAction } from "../../../Redux/Actions/Project.action";
import CustomScrollbar from "../../CommonComponents/CustomScrollbar";

const customStyles = {
  control: (provided) => ({
    ...provided,
    border: "1px solid #d1d1d1",
    boxShadow: "none",
    borderRadius: "5px",
    padding: "2px",
    fontSize: "16px",
    fontFamily: "Instrument Sans",
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: "5px",
    marginTop: "5px",
    padding: "0",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "#5fbec6" : "#eaf5f6",
    color: state.isFocused ? "white" : "black",
    padding: "10px 15px",
    cursor: "pointer",
    fontFamily: "Instrument Sans",
    fontWeight: 500
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#333",
    fontFamily: "Instrument Sans",
    fontWeight: 500
  }),
};

const CallSettings = ({ toggle }) => {
  const reducer = useSelector((state) => state);
  const { projectId } = useParams();

  const {
    AccountReducer: { systemConstant },
    ProjectReducer: {
      selectedProject: { callSettings },
    },
  } = reducer;

  console.log("cal;ll", callSettings);
  const [state, setState] = useState({
    callerIdNumber: "",
    callTransferNumber: "",
    asrEngine: "",
    gateway: "",
    voice: "",
    languageCode: "",
    model: "001",
  });

  const [constantArray, setConstantArray] = useState({
    asrEngines: [],
    gateways: [],
    voices: [],
    modelOptions: [],
    languageCodes: [],
  });

  const { asrEngines, gateways, voices, modelOptions, languageCodes } =
    constantArray;

  useEffect(() => {
    let voicesCopy = systemConstant?.voices;
    voicesCopy = voicesCopy?.map((voic) => {
      return {
        ...voic,
        isDisabled: voic.disabled,
      };
    });
    setConstantArray({ ...systemConstant, voices: voicesCopy });
  }, [systemConstant]);

  useEffect(() => {
    if (!isEmpty(callSettings)) {
      let localCallSetting = callSettings;
      setState((prev) => ({
        ...prev,
        ...localCallSetting,
      }));
    }
  }, [callSettings]);

  const {
    asrEngine,
    gateway,
    voice,
    languageCode,
    model,
    callTransferNumber,
    callerIdNumber,
  } = state;

  const dispatch = useDispatch();

  const onChange = (e) => {
    e.persist();
    setState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSave = async (e) => {
    e.preventDefault();
  };

  const variantArray = [
    "primary",
    "secondary",
    "success",
    "danger",
    "warning",
    "info",
    "light",
    "dark",
  ];

  const resultArray = voices?.map((item, index) => ({
    item: item,
    variant: variantArray[index % variantArray.length],
  }));

  const saveSettings = async () => {
    let obj = {
      callSettings: {
        model,
        asrEngine,
        languageCode,
        voice,
        callerIdNumber,
        callTransferNumber,
        gateway,
      },
    };

    await dispatch(updateProjectsAction(obj, projectId));
  };
  return (
    <>
      <CustomScrollbar height="70vh">
        <Box className="w-100" sx={{ margin: "auto", padding: "30px 40px" }}>
          <Box className="mb-5">
            <label className="modal-input-label">
              Gateway <span className="required-star">*</span>
            </label>
            <Select
              styles={customStyles}
              maxMenuHeight={160}
              className="basic-single"
              classNamePrefix="select"
              value={gateways?.find((name) => name.value === gateway)}
              isDisabled={false}
              isSearchable={true}
              options={gateways}
              getOptionLabel={(e) => e.name}
              onChange={(e) => {
                setState((prev) => ({
                  ...prev,
                  gateway: e.value,
                }));
              }}
              placeholder="Select Gateway"
            />
          </Box>
          <Box className="mb-5">
            <label className="modal-input-label">Model</label>
            <Select
              styles={customStyles}
              maxMenuHeight={160}
              className="basic-single"
              classNamePrefix="select"
              value={modelOptions?.find((name) => name.value === model)}
              isDisabled={false}
              isSearchable={true}
              options={modelOptions}
              getOptionLabel={(e) => e.name}
              onChange={(e) => {
                setState((prev) => ({
                  ...prev,
                  model: e.value,
                }));
              }}
              placeholder="Select Model"
            />
          </Box>
          <Box className="mb-5">
            <label className="modal-input-label">Voice</label>

            <Select
              styles={customStyles}
              maxMenuHeight={160}
              className="basic-single"
              classNamePrefix="select"
              value={voices?.find((name) => name.value === voice)}
              isDisabled={false}
              isSearchable={true}
              options={voices}
              getOptionLabel={(e) => e.name}
              onChange={(e) => {
                setState((prev) => ({
                  ...prev,
                  voice: e.value,
                }));
              }}
              placeholder="Select Voice"
            />
          </Box>
          <Box className="mb-5">
            <label className="modal-input-label">
              Language Code <span className="required-star">*</span>
            </label>

            <Select
              styles={customStyles}
              maxMenuHeight={180}
              className="basic-single"
              classNamePrefix="select"
              value={asrEngines?.find((name) => name.value === asrEngine)}
              isDisabled={false}
              isSearchable={true}
              options={asrEngines}
              getOptionLabel={(e) => e.name}
              onChange={(e) => {
                setState((prev) => ({
                  ...prev,
                  asrEngine: e.value,
                }));
              }}
              placeholder="Select Engine"
            />
          </Box>
          <Box className="mb-5">
            <label className="modal-input-label">
              Language Code <span className="required-star">*</span>
            </label>

            <Select
              styles={customStyles}
              maxMenuHeight={180}
              className="basic-single"
              classNamePrefix="select"
              value={languageCodes?.find((name) => name.value === languageCode)}
              isDisabled={false}
              isSearchable={true}
              options={languageCodes}
              getOptionLabel={(e) => e.name}
              onChange={(e) => {
                setState((prev) => ({
                  ...prev,
                  languageCode: e.value,
                }));
              }}
              placeholder="Select Language Codes"
            />
          </Box>
        </Box>
      </CustomScrollbar>
      <div className="d-flex justify-content-center mb-8 mt-5">
        <button
          style={{ width: "384px", height: "44px" }}
          className="gradient-button"
          onClick={saveSettings}
        >
          Save Settings
        </button>
      </div>
    </>
  );
};

export default CallSettings;
