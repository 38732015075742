import { useState } from "react";
import {
  Button,
  List,
  ListItem,
  Box,
  styled,
  Menu,
  MenuItem,
  Avatar,
} from "@mui/material";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  SIDEBAR_DATA,
  SIDEBAR_DATA_ACCOUNT,
} from "../../Configs/sidebar.config";
import { LogoutOutlined, SettingsOutlined } from "@mui/icons-material";
import NotificationPanel from "../../Pages/Dashboard/Notifications";
import { toast } from "react-toastify";
import profileImg from "../../../Assests/Images/Icons/Frame 3.png";
import SettingsIcon from "@mui/icons-material/Settings";
import CommanModal from "../CommanModal";
import CallSettings from "../../ProjectSection/Projects/CallSettingsScreen";

const Container = styled(Box)`
  padding: 8px;
  color: white;
  & > ul {
    padding: 10px 0 0 5px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    & > a {
      text-decoration: none;
      color: inherit;
    }
    & > a > li > svg {
      margin-right: 20px;
    }
  }
`;

const ComposeButton = styled(Button)`
  background: #c2e7ff;
  color: #001d35;
  border-radius: 16px;
  padding: 15px;
  min-width: 140px;
  text-transform: none;
`;

const SideBarContent = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [settingModal, setSettingModal] = useState(false);
  const { projectId } = useParams();
  const location = useLocation();
  const { pathname } = location;

  const obj = {
    projects: "/main/projects",
    deals: `/main/${projectId}/deals/deals-page`,
    calender: `/main/${projectId}/calender`,
    project: `/main/${projectId}/project-settings`,
    training: `/main/${projectId}/training-logs`,
  };
  const obj1 = {
    projects: "/main/projects",
    dashboard: "/main/dashboard",
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    navigate("/");
    localStorage.clear();
    toast.success("User Logout Successfully");
  };

  const toggle = () => {
    setSettingModal(!settingModal);
  };
  return (
    <Container>
      <List>
        {projectId ? (
          <>
            {SIDEBAR_DATA.map((data) => (
              <NavLink title={data.title} key={data.name} to={obj[data.name]}>
                <ListItem
                  style={
                    pathname.includes(data.name)
                      ? {
                          borderRadius: "10px",
                          color: "#13729A",
                        }
                      : { color: "#858B97" }
                  }
                >
                  <data.icon fontSize="small" />
                  {data.title}
                </ListItem>
              </NavLink>
            ))}
            <ListItem
              title="Project Settings"
              onClick={toggle}
              style={
                pathname.includes("project")
                  ? {
                      borderRadius: "10px",
                      color: "#13729A",
                    }
                  : { color: "#858B97" }
              }
            >
              <SettingsIcon fontSize="small" />
            </ListItem>
          </>
        ) : (
          SIDEBAR_DATA_ACCOUNT.map((data) => (
            <NavLink title={data.title} key={data.name} to={obj1[data.name]}>
              <ListItem
                style={
                  pathname.includes(data.name)
                    ? {
                        borderRadius: "10px",
                        color: "#13729A",
                      }
                    : {
                        color: "#858B97",
                      }
                }
              >
                <data.icon fontSize="small" />
                {data.title}
              </ListItem>
            </NavLink>
          ))
        )}
      </List>

      <List
        style={{
          position: "fixed",
          bottom: 0,
          left: 5,
        }}
      >
        <ListItem
          style={{
            color: "#858B97",
          }}
        >
          <SettingsOutlined sx={{ color: "#858B97" }} onClick={handleClick} />
        </ListItem>

        <ListItem
          style={{
            color: "#858B97",
          }}
        >
          <NotificationPanel />
        </ListItem>
        <ListItem
          style={{
            color: "#858B97",
          }}
        >
          <LogoutOutlined titleAccess="Logout" onClick={handleLogout} />
        </ListItem>
        <ListItem
          style={{
            color: "#858B97",
            marginLeft: "-10px",
          }}
        >
          <Avatar alt="Remy Sharp" src={profileImg} />
        </ListItem>

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          {/* <MenuItem
            onClick={() => {
              navigate("/main/company-details");
              handleClose();
            }}
          >
            Company Details
          </MenuItem> */}
          <MenuItem
            onClick={() => {
              navigate("/main/live-agent-details");
              handleClose();
            }}
          >
            Live Agent Settings
          </MenuItem>
        </Menu>
      </List>
      <CommanModal
        body={<CallSettings toggle={toggle} />}
        headerText={"Bot Settings"}
        open={settingModal}
        subheaderText={""}
        toggle={toggle}
        minWidth={"40vw"}
        footer={false}
      />
    </Container>
  );
};

export default SideBarContent;
