import React from "react";
import { BootStrapInput } from "../../../Components/CommonComponents/Input";

const AIDetailsStep = ({ projectUpdate, onboardingState, handleChange }) => {
  const { projectName, agentName } = onboardingState;
  return (
    <>
      <BootStrapInput
        value={projectName}
        name={"projectName"}
        label={"Project Name"}
        placeholder={"Example your project name can be “On-boarding”"}
        onChange={handleChange}
        required={false}
        disabled={Boolean(projectUpdate) ?? false}
        className={"mb-6 w-100 p-0"}
      />
      <BootStrapInput
        value={agentName}
        name={"agentName"}
        label={"Name your AI SDR"}
        placeholder={"Intro your AI SDR like “Luna”"}
        onChange={handleChange}
        required={false}
        className={"mb-3 w-100"}
      />
    </>
  );
};

export default AIDetailsStep;
