import React from "react";
import { Box, Typography, Avatar, Stack, IconButton } from "@mui/material";
import { LinkedIn } from "@mui/icons-material";
import lead1 from "../../../Assests/Images/lead1.png";
import pin from "../../../Assests/Images/Icons/Pin.svg";
import phone from "../../../Assests/Images/Icons/Phone.svg";
import email from "../../../Assests/Images/Icons/mail.svg";
import CustomScrollbar from "../../../Components/CommonComponents/CustomScrollbar";

const contactDetails = [
  { label: "+144-274-****", icon: phone },
  { label: "l********@gmail.com", icon: email },
  { label: "San Francisco, USA", icon: pin },
];

const ProspectingLead = () => {
  return (
    <Box p={1.5}>
      <Typography color="#1A1D23" fontSize={18} fontWeight={700} mb={2}>
        Prospecting your lead
      </Typography>
      <Box display="flex" justifyContent="space-between" gap={3} mb={2}>
        <Box bgcolor="#f2f5fa" width={2 / 3} display="flex" alignItems="center" p={2} borderRadius={2}>
          <Avatar alt="Luke Buchanan" src={lead1} sx={{ width: 64, height: 64, mr: 2 }} />
          <Box>
            <Typography color="#1A1D23" fontSize={14} fontWeight={600}>
              Luke Buchanan
              <IconButton sx={{ p: 0 }}>
                <LinkedIn color="primary" />
              </IconButton>
            </Typography>
            <Typography color="#8792A7" fontSize={12}>Chief Executive Officer</Typography>
            <Typography color="#8792A7" fontSize={12}>@ Redi Health</Typography>
          </Box>
        </Box>

        <Stack bgcolor="#f2f5fa" direction="column" spacing={1} alignItems="flex-end" width={1 / 3} p={2} borderRadius={2}>
          {contactDetails.map(({ label, icon }) => (
            <Box display="flex" gap={1} justifyContent="flex-end" key={label}>
              <Typography color="#8792A7" fontSize={12}>{label}</Typography>
              <img alt={icon} src={icon} />
            </Box>
          ))}
        </Stack>
      </Box>
      <Box bgcolor="#f2f5fa" borderRadius={2} p={2}>
        <CustomScrollbar height="36vh" color="inherit">
          <Typography fontSize={16} fontWeight={600} sx={{ mb: 1 }}>
            Personality Analysis: Auditory
          </Typography>
          <Typography fontSize={16} fontWeight={600} gutterBottom>Introduction</Typography>
          <Typography variant="body2" paragraph>
            Based on the information provided, <strong>Luke Buchanan</strong>{" "}
            appears to be predominantly auditory in his personality style.
            Here's the analysis supporting this conclusion:
          </Typography>
          <Typography fontSize={16} fontWeight={600} sx={{ mb: 1 }}>
            1. Engagement in Auditory Activities
          </Typography>

          <Box component="ul" sx={{ pl: 2 }}>
            <Typography component="li" variant="body2">
              <strong>Hosting a Podcast:</strong> Vignesh is actively involved
              in the UNPAK3D Podcast, where he and his co-host engage in
              conversations with entrepreneurs and industry leaders. This
              indicates a strong preference for auditory communication and
              sharing information through spoken words.
            </Typography>
            <Typography component="li" variant="body2" sx={{ mt: 1 }}>
              <strong>Use of Auditory Language:</strong> In his posts, Vignesh
              frequently uses auditory-related verbs and phrases such as "chat,"
              "sit down with," "delve into," "reveal," "talk through,"
              "discuss," "listen," "subscribe," and "tune in." This choice of
              language suggests an inclination towards auditory processing.
            </Typography>
          </Box>
        </CustomScrollbar>
      </Box>
    </Box>
  );
};

export default ProspectingLead;
