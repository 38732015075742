import React, { lazy } from "react";
import Box from "@mui/material/Box";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Sidebar from "./Sidebar";
import { Routes, Route } from "react-router-dom";
import GettingStarted from "../Dashboard/GettingStarted";
import Dashboard from "../../Components/Pages/Dashboard/Dashboard";
import ProjectDashboard from "../Projects/ProjectDashboard";
import CustomScrollbar from "../../Components/CommonComponents/CustomScrollbar";
const ErrorComponent = lazy(() =>
  import("../../Components/CommonComponents/CommonUiComponent/ErrorComponent")
);

const theme = createTheme({
  palette: {
    primary: {
      main: "#1976d2",
    },
  },
});

export default function DashboardLayout() {
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex", height: "100vh", flexDirection: "row" }}>
        <Sidebar />
        <CustomScrollbar>
        <Box sx={{ flexGrow: 1, m: 0.15, overflow: "auto" }}>
          <Routes>
            <Route path="/getting-started" element={<GettingStarted />}  errorElement={<ErrorComponent />}/>
            <Route path="/dashboard" element={<Dashboard />} errorElement={<ErrorComponent />}/>
            <Route path="/projects/*" element={<ProjectDashboard errorElement={<ErrorComponent />} />} />

          </Routes>
        </Box>
        </CustomScrollbar>
      </Box>
    </ThemeProvider>
  );
}
