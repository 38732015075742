import React, { useState } from "react";
import {
  Box,
  Typography,
  Switch,
  FormControlLabel,
  IconButton,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import Feedback from "../Feedback";

const FinalizingLead = () => {
  const [isToggled, setIsToggled] = useState(false);
  const [selectedRating, setSelectedRating] = useState(null);
  const [feedback, setFeedback] = useState("");

  const handleToggleChange = (event) => {
    setIsToggled(event.target.checked);
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        bgcolor="#EEF2FB"
        px={1}
        py={0.5}
        borderRadius={2}
        mt={2}
      >
        <Box display="flex" alignItems="center">
          <IconButton
            size="small"
            disableRipple
            style={{ color: "#6E6E6E", marginRight: 2 }}
          >
            <InfoIcon fontSize="small" />
          </IconButton>
          <Typography color="#1A1D23" fontSize={13}>
            Are you willing to move this lead to Deals? If yes, enable the
            toggle button
          </Typography>
        </Box>
        <FormControlLabel
          control={
            <Switch
              checked={isToggled}
              onChange={handleToggleChange}
              color="primary"
            />
          }
          label=""
        />
      </Box>
      <Feedback
        selectedRating={selectedRating}
        setSelectedRating={setSelectedRating}
        feedback={feedback}
        setFeedback={setFeedback}
      />
    </>
  );
};

export default FinalizingLead;
