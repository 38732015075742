import React, { useState } from "react";
import QualifyingQuestions from "./QualifyingQuestions";
import CallSimulation from "./CallSimulation";
import FinalizingLead from "./FinalizingLead";
import { Box, Dialog, DialogContent } from "@mui/material";
import Steps from "../../Shared/Steps";
import FooterContent from "../FooterContent";
import CallSimulationSuccess from "./CallSimulationSuccess";

const headerSteps = [
  {
    step: 1,
    label: "Qualifying Questions",
  },
  {
    step: 2,
    label: "Call Simulation",
  },
  {
    step: 3,
    label: "Finalizing Lead",
  },
];
const ModalContent = ({ openThirdStageModal, setOpenThirdStageModal }) => {
  const [currentStep, setCurrentStep] = useState(0);

  const handleBack = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleNext = () => {
    if (currentStep === 4) {
      setCurrentStep(2);
    } else if (currentStep === 1) {
      setCurrentStep(4);
    } else if (currentStep < 2) {
      setCurrentStep(currentStep + 1);
    }
  };

  const renderStepContent = () => {
    switch (currentStep) {
      case 0:
        return <QualifyingQuestions />;
      case 1:
        return <CallSimulation />;
      case 2:
        return <FinalizingLead />;
      case 4:
        return <CallSimulationSuccess />;
      default:
        return null;
    }
  };
  const closeModal = () => {
    setCurrentStep(0);
    setOpenThirdStageModal(false);
  };

  const getNextButtonLabel = (currentStep) => {
    if (currentStep === 1) return "Trigger Call";
    if (currentStep === 2) return "Submit";
    if (currentStep === 4) return "Okay,Continue";
    return "Save & Continue";
  };

  return (
    <Dialog
      open={openThirdStageModal}
      fullWidth
      disableEscapeKeyDown
      sx={{
        "& .MuiDialog-paper": {
          width: 650,
          maxWidth: "100%",
        },
      }}
    >
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          paddingBottom: 2.5,
        }}
      >
        <Steps
          currentStep={currentStep === 4 ? 1 : currentStep}
          setCurrentStep={setCurrentStep}
          steps={headerSteps}
          closeModal={closeModal}
        />
        <Box
          style={{
            flexGrow: 1,
            overflowY: "auto",
            marginBottom: 2.5,
            marginTop: 8,
          }}
        >
          {renderStepContent()}
        </Box>
        <FooterContent
          currentStep={currentStep}
          handleBack={handleBack}
          handleNext={handleNext}
          showBackButton={[0, 2, 4].includes(currentStep) ? false : true}
          nextButtonLabel={getNextButtonLabel(currentStep)}
        />
      </DialogContent>
    </Dialog>
  );
};

export default ModalContent;
