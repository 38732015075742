import React from "react";
import img from "../../Assests/Images/vodexLogo.svg";
import img1 from "../../Assests/Images/vodexLogoBlack.svg";
function TopRightLogo({ black = false }) {
  return (
    <div className="top-logo-container d-flex">
      {black ? (
        <>
          {" "}
          <img src={img1} />
          <p className="font-size-38 mt-3 ml-1 gradient-text"> Zen</p>
        </>
      ) : (
        <>
          {" "}
          <img src={img} />
          <p className="font-size-38 mt-3 ml-1"> Zen</p>
        </>
      )}
    </div>
  );
}

export default TopRightLogo;
