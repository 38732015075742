import React, { useState } from "react";
import TopRightLogo from "../CommonComponents/TopRightLogo";
import Steppers from "../CommonComponents/Steppers";
import { Input, MultiLineGrowInput } from "../CommonComponents/Input";
import "../../Assests/Css/Common.css";
import Select from "react-select";
import CenterContainer from "../CommonComponents/CenterContainer";
import timezones from "../../Components/CommonComponents/timezones.json";
import { useDispatch } from "react-redux";
import {
  promptTrainingStartAction,
  saveCompanyDeatilsAction,
} from "../../Redux/Actions/Common.actions";
import { toast } from "react-toastify";
import { useOnboardingState } from "./OnboardingContext";
import { convertTime, timeArray } from "../CommonComponents/Common";
import { getCompanySummaryAction } from "../../Redux/Actions/AI.actions";
import { Box, Grid, Typography } from "@mui/material";
import WelcomeCard from "../CommonComponents/ProgressContainer";
import {
  ArrowBackIcon,
  FormHeader,
  InfoIcon,
} from "../CommonComponents/IconsPage";
import { useNavigate } from "react-router-dom";
import CustomScrollbar from "../CommonComponents/CustomScrollbar";

function CompanyDetails() {
  const navigate = useNavigate();
  const {
    onBoardingState: {
      projectId,
      mainUrl,
      from,
      to,
      days,
      timezone,
      homepageUrl,
      summary,
    },
    setOnBoardingState,
    updateProject,
  } = useOnboardingState();

  const dispatch = useDispatch();
  const onChange = (e) => {
    setOnBoardingState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const hoursToMinutes = (hours) => {
    return hours * 60;
  };
  const daysToArray = (days) => {
    if (days === 7) {
      return Array(7).fill(1);
    } else if (days === 5) {
      return [1, 1, 1, 1, 1, 0, 0];
    } else {
      return [];
    }
  };

  const processData = (inputData) => {
    const { from, to, days } = inputData;
    const fromMinutes = hoursToMinutes(parseInt(from));
    const toMinutes = hoursToMinutes(parseInt(to));
    const daysArray = daysToArray(parseInt(days));
    return {
      fromMinutes,
      toMinutes,
      daysArray,
    };
  };

  const weekOptions = [
    { label: "Whole Week", value: "7" },
    { label: "5 days", value: "5" },
  ];

  const startTraing = async () => {
    await dispatch(promptTrainingStartAction(projectId));
  };

  const getCompanySummary = async () => {
    let res = await dispatch(
      getCompanySummaryAction({ websites: [homepageUrl] })
    );
    setOnBoardingState((prev) => ({
      ...prev,
      summary: res.summary,
    }));
  };

  return (
    <div
      style={{
        height: "100%",
      }}
      className="white-bg"
    >
      <TopRightLogo black={true} />
      <br />
      <Grid container className="mt-14 ml-5">
        <Grid item xs={12} md={4}>
          {" "}
          <WelcomeCard value={"95"} />
        </Grid>
        <Grid item xs={12} md={8}>
          <Box>
            <Typography
              className="font-size-15 default-border"
              color="black"
              sx={{
                padding: "15px",
                width: "65%",
                marginBottom: "10px",
              }}
            >
              <InfoIcon className="mr-2" />
              It’s just 1 steps to go! Your AI assistant is waiting for you
              there.
            </Typography>
            <Typography className="font-size-15" color="black">
              <ArrowBackIcon
                onClick={() => navigate("/get-started/hubspot-setup")}
                className="mr-2 cursor-pointer"
              />
            </Typography>
            <FormHeader className="color-black font-size-22">
              Company Profile
              <Typography className="font-size-12 color-black">
                Let’s collaborate to build your project and create something
                extraordinary.
              </Typography>
            </FormHeader>{" "}
            <CustomScrollbar height="46vh">
              <Box
                sx={{
                  height: "auto",
                  // marginTop: "45px",
                }}
              >
                <>
                  <p className="color-black"> Agent Time Availability</p>
                  <div className="d-flex">
                    <Select
                      maxMenuHeight={180}
                      className="basic-single  w-40 "
                      classNamePrefix="select"
                      value={timeArray?.find((e) => e.value === from)}
                      isSearchable={true}
                      options={timeArray}
                      getOptionLabel={(e) => e.label}
                      onChange={(e) =>
                        setOnBoardingState((prev) => ({
                          ...prev,
                          from: e.value,
                        }))
                      }
                      placeholder="Select start time"
                      getOptionValue={(e) => e.label}
                    />

                    <Select
                      maxMenuHeight={180}
                      className="basic-single ml-1 w-40"
                      classNamePrefix="select"
                      value={timeArray?.find((e) => e.value === to)}
                      isSearchable={true}
                      options={timeArray}
                      getOptionLabel={(e) => e.label}
                      onChange={(e) =>
                        setOnBoardingState((prev) => ({
                          ...prev,
                          to: e.value,
                        }))
                      }
                      placeholder="Select end time"
                      getOptionValue={(e) => e.label}
                    />
                  </div>
                  <p className="color-black mt-3"> Agent Days Availability</p>
                  <span>
                    <Select
                      maxMenuHeight={180}
                      className="basic-single w-80"
                      classNamePrefix="select"
                      value={weekOptions.find((e) => e.value === days)}
                      isSearchable={true}
                      options={weekOptions}
                      getOptionLabel={(e) => e.label}
                      onChange={(e) =>
                        setOnBoardingState((prev) => ({
                          ...prev,
                          days: e.value,
                        }))
                      }
                      placeholder="Select "
                      getOptionValue={(e) => e.label}
                    />
                  </span>
                  <p className="color-black mt-3">Timezone</p>
                  <span>
                    <Select
                      maxMenuHeight={100}
                      className="basic-single  w-80"
                      classNamePrefix="select"
                      value={timezones.find((e) => e.offset === timezone)}
                      isSearchable={true}
                      options={timezones}
                      getOptionLabel={(e) => `${e.text} ${e.abbr} `}
                      onChange={(e) =>
                        setOnBoardingState((prev) => ({
                          ...prev,
                          timezone: e.offset,
                        }))
                      }
                      placeholder="Select "
                      getOptionValue={(e) => e.offset}
                    />
                  </span>
                  <p className="color-black mt-3">Company Summary</p>
                  <Input
                    placeholder={"Company Homepage Url"}
                    value={homepageUrl}
                    name={"homepageUrl"}
                    onChange={onChange}
                    className={"mr-1  w-58"}
                  />{" "}
                  <button
                    onClick={getCompanySummary}
                    className="gradient-button mt-1 "
                  >
                    Get Company Summary
                  </button>
                  <br />
                  {summary && (
                    <MultiLineGrowInput
                      placeholder={"Company Summary"}
                      value={summary}
                      name={"summary"}
                      onChange={onChange}
                      className={"mr-3 mt-5 mb-4 w-80"}
                    />
                  )}
                  <div className="d-flex "> </div>
                </>
              </Box>
            </CustomScrollbar>
          </Box>
        </Grid>
      </Grid>

      <div className="d-flex justify-content-center mt-1">
        <button
          className="gradient-button  mr-1"
          onClick={() => updateProject("/main/projects")}
        >
          Save & Continue to project
        </button>
        {/* <button className="gradient-button  mr-1" onClick={startTraing}>
          Start Training
        </button> */}
      </div>
    </div>
  );
}

export default CompanyDetails;
