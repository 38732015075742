import { getCompanySummaryService, getSitemapsUrlService, getStageDummyService, getTrainingLogsService } from "../../Services/AI.services";



export const getStageDummyAction= (stages) => async () => {
  let res = await getStageDummyService(stages);
  return res
};

export const getTrainingLogsAction= (sessionId,type) => async () => {
  let res = await getTrainingLogsService(sessionId,type);
  return res
};

export const getSitemapUrlsAction= (data) => async () => {
  let res = await getSitemapsUrlService(data);
  return res
};

export const getCompanySummaryAction= (data) => async () => {
  let res = await getCompanySummaryService(data);
  
  return res
};