import React, { useEffect, useRef, useState } from "react";
import {
  Card,
  Typography,
  Avatar,
  IconButton,
  Button,
  Box,
} from "@mui/material";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import AddIcon from "@mui/icons-material/Add";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import lead1 from "../../../Assests/Images/lead1.png";
import lead2 from "../../../Assests/Images/lead2.png";
import pin from "../../../Assests/Images/Icons/Pin.svg";
import phone from "../../../Assests/Images/Icons/Phone.svg";
import email from "../../../Assests/Images/Icons/mail.svg";

const leads = [
  {
    name: "Claire Coder",
    title: "Founder",
    company: "Aunt Flow",
    location: "Boston, USA",
    phone: "+144-528-****",
    email: "c*******@gmail.com",
    img: lead1,
  },
  {
    name: "Luke Buchanan",
    title: "Chief Executive Officer",
    company: "Redi Health",
    location: "San Francisco, USA",
    phone: "+144-274-****",
    email: "l*******@gmail.com",
    img: lead2,
  },
  {
    name: "Alice Default",
    title: "Co-founder and CEO",
    company: "Double",
    location: "Los Angeles, USA",
    phone: "+144-336-****",
    email: "a*******@gmail.com",
    img: lead1,
  },
  {
    name: "Claire Coder",
    title: "Founder",
    company: "Aunt Flow",
    location: "Boston, USA",
    phone: "+144-528-****",
    email: "c*******@gmail.com",
    img: lead1,
  },
  {
    name: "Luke Buchanan",
    title: "Chief Executive Officer",
    company: "Redi Health",
    location: "San Francisco, USA",
    phone: "+144-274-****",
    email: "l*******@gmail.com",
    img: lead2,
  },
  {
    name: "Alice Default",
    title: "Co-founder and CEO",
    company: "Double",
    location: "Los Angeles, USA",
    phone: "+144-336-****",
    email: "a*******@gmail.com",
    img: lead1,
  },
];

const styles = {
  carouselContainer:{
    display: "flex",
    overflowX: "hidden",
    gap: 2,
    paddingY: 1,
    scrollBehavior: "smooth",
    "&::-webkit-scrollbar": { display: "none" },
    "-msOverflowStyle": "none",
  },
  carouselButtons:{
    position: "absolute",
    zIndex: 1,
    backgroundColor: "#F1F4FA",
    borderRadius: 2,
    padding: 0.5,
    "&:hover": { backgroundColor: "#f0f0f0" },
  },
  carouselButtonWrapper: {
    position: "absolute",
    height: "100%",
    width: 120,
    display: "flex",
    justifyContent: "right",
    alignItems: "center",
    zIndex: 1,
  },
  createLead: {
    color: "#5F6A8A",
    borderColor: "#D1D9EF",
    textTransform: "none",
    fontSize: 12,
    fontWeight: 500,
  }
}

const LeadCard = ({ lead }) => (
  <Card
    variant="outlined"
    sx={{
      width: 220,
      padding: 2,
      borderRadius: 1.5,
      flexShrink: 0,
      borderColor: "#E0E0E0",
      transition: "transform 0.3s",
      "&:hover": {
        transform: "scale(1.05)",
        borderColor: "#4F46E5",
        backgroundColor:"#F8F8FE"
      },
    }}
  >
    <Box display="flex" flexDirection="column" alignItems="center">
      <Avatar
        alt={lead.name}
        src={lead.img}
        sx={{ width: 80, height: 80, marginBottom: 1 }}
      />
      <Box display="flex" justifyContent="space-between" gap={0.5}>
        <Typography
          align="center"
          color="#1A1D23"
          fontSize={14}
          fontWeight={600}
        >
          {lead.name}
        </Typography>
        <IconButton color="primary" size="small" sx={{ padding: 0 }}>
          <LinkedInIcon />
        </IconButton>
      </Box>
      <Typography variant="subtitle2" color="textSecondary">
        {lead.title}
      </Typography>
      <Typography variant="body2" color="textSecondary">
        @ {lead.company}
      </Typography>
      <Typography variant="body2" color="textSecondary" mt={1}>
        <img alt="phone" src={phone} style={{ marginRight: 4 }} />
        {lead.phone}
      </Typography>
      <Typography variant="body2" color="textSecondary">
        <img alt="email" src={email} style={{ marginRight: 4 }} />
        {lead.email}
      </Typography>
      <Typography variant="body2" color="textSecondary">
        <img alt="pin" src={pin} style={{ marginRight: 4 }} />
        {lead.location}
      </Typography>
    </Box>
  </Card>
);

const FindingPerfectLead = ({ openCreateLead }) => {
  const carouselRef = useRef(null);
  const [showLeft, setShowLeft] = useState(false);
  const [showRight, setShowRight] = useState(true);

  const scroll = (direction) => {
    const scrollAmount = 400;
    if (carouselRef.current) {
      carouselRef.current.scrollLeft +=
        direction === "left" ? -scrollAmount : scrollAmount;
      updateButtonVisibility();
    }
  };

  const updateButtonVisibility = () => {
    const carousel = carouselRef.current;
    if (carousel) {
      const maxScrollLeft = carousel.scrollWidth - carousel.clientWidth;
      setShowLeft(carousel.scrollLeft > 0);
      setShowRight(carousel.scrollLeft < maxScrollLeft);
    }
  };

  useEffect(() => {
    updateButtonVisibility();
    carouselRef.current?.addEventListener("scroll", updateButtonVisibility);
    return () => {
      carouselRef.current?.removeEventListener(
        "scroll",
        updateButtonVisibility
      );
    };
  }, []);

  return (
    <Box p={1}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={1.5}
      >
        <Box>
          <Typography
            color="#1A1D23"
            fontSize={18}
            fontWeight={700}
            gutterBottom
          >
            Finding your perfect Lead
          </Typography>
          <Typography
            color="#656E7F"
            fontSize={13}
            gutterBottom
          >
            Choose your lead or add Lead manually
          </Typography>
        </Box>
        <Button
          variant="outlined"
          startIcon={<AddIcon />}
          sx={styles.createLead}
          onClick={openCreateLead}
        >
          Create Lead
        </Button>
      </Box>

      <Box sx={{ position: "relative", display: "flex", alignItems: "center" }}>
        {showLeft && (
          <Box
            sx={{
              ...styles.carouselButtonWrapper,
              left: 0,
              background:
                "linear-gradient(90deg, #FFFFFF 32.99%, rgba(255, 255, 255, 0) 60.45%)",
            }}
          >
            <IconButton
              onClick={() => scroll("left")}
              sx={{ ...styles.carouselButtons, left: 0 }}
            >
              <ChevronLeftIcon fontSize="small" />
            </IconButton>
          </Box>
        )}

        <Box ref={carouselRef} sx={styles.carouselContainer}>
          {leads.map((lead, index) => (
            <LeadCard key={index} lead={lead} />
          ))}
        </Box>

        {showRight && (
          <Box
            sx={{
              ...styles.carouselButtonWrapper,
              right: 0,
              background:
                "linear-gradient(270deg, #FFFFFF 32.99%, rgba(255, 255, 255, 0) 60.45%)",
            }}
          >
            <IconButton
              onClick={() => scroll("right")}
              sx={{
                ...styles.carouselButtons,
                zIndex: 1,
              }}
            >
              <ChevronRightIcon fontSize="small" />
            </IconButton>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default FindingPerfectLead;
