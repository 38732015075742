import React, { useEffect, useState } from "react";
import "../../Assests/Css/Onboarding.css";
import Steppers from "../CommonComponents/Steppers";
import TopRightLogo from "../CommonComponents/TopRightLogo";
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import {
  BootStrapInput,
  Input,
  MultiLineGrowInput,
} from "../CommonComponents/Input";
import { useDispatch, useSelector } from "react-redux";
import {
  getHubspotPipeLineAction,
  hubspotRedirectAction,
} from "../../Redux/Actions/Hubspot.actions";
import Select from "react-select";
import CenterContainer from "../CommonComponents/CenterContainer";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useOnboardingState } from "./OnboardingContext";
import { getStageDummyAction } from "../../Redux/Actions/AI.actions";
import { useNavigate } from "react-router-dom";
import WelcomeCard from "../CommonComponents/ProgressContainer";
import {
  ArrowBackIcon,
  FormHeader,
  InfoIcon,
} from "../CommonComponents/IconsPage";
import CustomScrollbar from "../CommonComponents/CustomScrollbar";
import hubspotImg from "../../Assests/Images/hubspot.png";
import gmailImg from "../../Assests/Images/gmail.png";
import StageModal from "../CommonComponents/CommanModal";
function Hubspot() {
  const navigate = useNavigate();
  const {
    onBoardingState,
    pipelineName,
    setPipelineName,

    savePipelineData,
  } = useOnboardingState();
  const { projectId, portalId } = onBoardingState;
  const [isGeneratingStage, setIsGeneratingStage] = useState(false);
  const [code, setCode] = useState("");
  const [selectedPipeline, setSelectedPipeline] = useState({});
  const [selectedStage, setSelectedStage] = useState([]);
  const [isChange, setIschange] = useState(false);
  const [dummyStageData, setDummyStageData] = useState([]);
  const dispatch = useDispatch();
  const reducer = useSelector((state) => state);
  const {
    HubspotReducer: { hubspotPipelineList },
    AccountReducer: { systemConstant },
  } = reducer;

  const [localPipelineData, setLocalPipeLineData] = useState([]);
  useEffect(() => {
    console.log("hubspotPipelineList", hubspotPipelineList);
    if (hubspotPipelineList?.length > 0) {
      setLocalPipeLineData([systemConstant?.pipeline, ...hubspotPipelineList]);
    }
  }, [hubspotPipelineList]);

  const attachCode = async () => {
    await dispatch(hubspotRedirectAction(code, projectId));
    await dispatch(getHubspotPipeLineAction());
  };
  const [openStage, setOpenStage] = useState(false);
  useEffect(() => {
    const init = async () => {
      await dispatch(getHubspotPipeLineAction());
    };

    init();
  }, []);

  const toggle = () => {
    setOpenStage(!openStage);
  };

  const [selectedTriggers, setSelectedTriggers] = useState({
    first: null,
    second: null,
  });

  const handleSelectTrigger = (stage, type) => {
    setSelectedTriggers((prev) => ({ ...prev, [type]: stage }));
  };
  const handleWhenToMoveChange = (id, value) => {
    setSelectedStage((prevStages) =>
      prevStages.map((stage) =>
        stage.id === id
          ? { ...stage, whenToMove: value, aiSuggested: false }
          : stage
      )
    );
  };

  useEffect(() => {
    const stageDummyInit = async () => {
      setIsGeneratingStage(true);
      const simplifiedStages = selectedStage?.map((stage) => ({
        stage_id: stage.id,
        stage_name: stage.label,
      }));
      let dummyRes = await dispatch(
        getStageDummyAction({ stages: simplifiedStages })
      );
      console.log(dummyRes);
      console.log("selectedStage", selectedStage);
      if (dummyRes?.pipeline_stages?.length > 0) {
        let selectedStage_Ai = selectedStage.map((stage) => {
          let desc =
            dummyRes?.pipeline_stages?.find((id) => id.stage_id === stage.id)
              ?.stage_description || "";
          return { ...stage, whenToMove: desc, aiSuggested: true };
        });

        console.log(selectedStage_Ai);

        setSelectedStage(selectedStage_Ai);
      }

      setOpenStage(true);

      setIsGeneratingStage(false);
    };
    if (selectedStage?.length > 0) {
      console.log("callled");
      stageDummyInit();
    }
  }, [isChange]);

  const handleArrowClick = (rowId) => {
    const aiFetchedValue = dummyStageData?.find(
      (dumm) => dumm?.stage_id === rowId
    )?.stage_description;
    handleWhenToMoveChange(rowId, aiFetchedValue);
  };
  console.log("localPipelineData", localPipelineData);
  return (
    <div
      style={{
        height: "100%",
      }}
      className="white-bg"
    >
      <TopRightLogo black={true} />
      <br />
      <Grid container className="mt-14 ml-5">
        <Grid item xs={12} md={4}>
          {" "}
          <WelcomeCard value={"75"} />
        </Grid>
        <Grid item xs={12} md={8}>
          <Box>
            <Typography
              className="font-size-15 default-border"
              color="black"
              sx={{
                padding: "15px",
                width: "65%",
                marginBottom: "10px",
              }}
            >
              <InfoIcon className="mr-2" />
              It’s just 2 steps to go! Your AI assistant is waiting for you
              there.
            </Typography>
            <Typography className="font-size-15" color="black">
              <ArrowBackIcon
                onClick={() => navigate("/get-started/sdr-setup-meeting")}
                className="mr-2 cursor-pointer"
              />
            </Typography>
            <FormHeader className="color-black font-size-22">
              Integrations
              <Typography className="font-size-12 color-black">
                Let’s collaborate to build your project and create something
                extraordinary.
              </Typography>
            </FormHeader>{" "}
            <CustomScrollbar height="46vh">
              <Box
                sx={{
                  height: "auto",
                  // marginTop: "45px",
                }}
              >
                {portalId ? (
                  <button className="gradient-button  w-80">
                    <img src={hubspotImg} height={"35px"} /> Connected to
                    Hubspot : Portal Id {portalId}
                  </button>
                ) : (
                  <a
                    href={`https://app.hubspot.com/oauth/authorize?client_id=1c046638-6595-4604-a5ac-f3997df36304&redirect_uri=https://vodex-testing.surge.sh/get-started/redirect-to-vodex?authType=hubspot&scope=crm.objects.carts.write%20crm.schemas.deals.read%20crm.objects.carts.read%20crm.schemas.deals.write%20crm.dealsplits.read_write%20oauth%20crm.objects.owners.read%20crm.objects.contacts.write%20crm.objects.custom.read%20crm.objects.custom.write%20crm.lists.write%20crm.lists.read%20crm.objects.deals.read%20crm.objects.deals.write%20crm.objects.contacts.read`}
                    // target="_blank"
                    className="btn skip-button w-80"
                  >
                    <img src={hubspotImg} height={"35px"} /> Connect to Hubspot
                  </a>
                )}
                <br />
                <br />
                {onBoardingState?.email ? (
                  <button className="gradient-button  w-100">
                    <img src={gmailImg} height={"25px"} /> Connected to Gmail
                    Account : {onBoardingState?.email}
                  </button>
                ) : (
                  <a
                    href={` https://accounts.google.com/o/oauth2/v2/auth?access_type=offline&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fgmail.readonly%20https%3A%2F%2Fmail.google.com%2F%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile&prompt=consent&response_type=code&client_id=808909189797-sm3gandff008bpn4tc5rb74lqo05u3a3.apps.googleusercontent.com&redirect_uri=https%3A%2F%2Fvodex-testing.surge.sh%2Fget-started%2Fredirect-to-vodex%3FauthType%3Dgmail`}
                    // target="_blank"
                    className="btn skip-button w-80"
                  >
                    <img src={gmailImg} height={"25px"} /> Connect to Gmail
                  </a>
                )}

                <div className="d-flex">
                  <Select
                    maxMenuHeight={180}
                    className="basic-single mb-5 mt-4 w-58 default-border default-border-radius"
                    classNamePrefix="select"
                    value={localPipelineData?.find(
                      (name) => name?.label === selectedPipeline?.label
                    )}
                    isSearchable={true}
                    options={localPipelineData || []}
                    getOptionLabel={(e) => e?.label}
                    onChange={(e) => {
                      setSelectedPipeline(e);
                      setSelectedStage(e?.stages);
                      setIschange(!isChange);
                    }}
                    placeholder="Select "
                    getOptionValue={(e) => e?.label}
                  />
                  <button
                    className="gradient-button mt-3 ml-3"
                    onClick={toggle}
                  >
                    {" "}
                    View
                  </button>
                </div>

                {selectedPipeline?.default && (
                  <BootStrapInput
                    onChange={(e) => setPipelineName(e.target.value)}
                    name={"pipelineName"}
                    value={pipelineName}
                    label={"New Pipeline Name"}
                    className={"w-80"}
                  />
                )}
              </Box>
            </CustomScrollbar>
          </Box>
        </Grid>
      </Grid>

      <div className="d-flex justify-content-center mt-1">
        <div className="d-flex justify-content-center mt-1">
          <button
            className="skip-button  mr-2"
            onClick={() => navigate("/get-started/company-details")}
          >
            Skip & Next
          </button>
          <button
            className="gradient-button "
            onClick={() => {
              savePipelineData(
                selectedPipeline,
                selectedStage,
                selectedTriggers
              );
            }}
          >
            Save & Next
          </button>
        </div>
      </div>

      <StageModal
        headerText={"Stage Configuration"}
        subheaderText={"Select the trigger respectively"}
        className={"w-90"}
        open={openStage}
        toggle={() => toggle()}
        body={
          <CustomScrollbar height="70vh">
            <Box justifyContent={"center"} display={"flex"}>
              <Table
                sx={{
                  maxWidth: 1050,
                  background: "white",
                }}
              >
                <TableHead className="gradient-text">
                  <TableRow>
                    <TableCell align="left" className="w-20">
                      Stage Name
                    </TableCell>

                    <TableCell align="left" className="w-50">
                      When to move
                    </TableCell>
                    <TableCell align="center" className="w-30">
                      Select Trigger Point
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {selectedStage.map((row) => (
                    <TableRow
                      key={row.id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {row.label}
                      </TableCell>

                      <TableCell
                        className="w-60"
                        align="left"
                        component="th"
                        scope="row"
                      >
                        <TextField
                          // inputRef={inputRef}
                          autoFocus
                          value={row.whenToMove}
                          onChange={(e) =>
                            handleWhenToMoveChange(row.id, e.target.value)
                          }
                          fullWidth
                          multiline
                          variant="standard"
                          sx={{
                            border: "none",
                            "& .MuiInputBase-root": {
                              padding: 0,
                              "&:before": {
                                borderBottom: "none",
                              },
                              "&:after": {
                                borderBottom: "none",
                              },
                            },
                            "& .MuiInputBase-input": {
                              padding: 0,
                              fontSize: "14px",
                              lineHeight: "1.5",
                              height: "auto",
                              resize: "none",
                            },
                            "& textarea": {
                              overflow: "hidden",
                              resize: "none",
                            },
                          }}
                          InputProps={{
                            disableUnderline: true,
                          }}
                          placeholder="Lorem Ipsum is simply dummy text of the printing and typesetting industry..."
                        />
                      </TableCell>

                      <TableCell align="center">
                        <FormControlLabel
                          control={
                            <Radio
                              checked={selectedTriggers.first === row.id}
                              onChange={() =>
                                handleSelectTrigger(row.id, "first")
                              }
                              name="firstTrigger"
                            />
                          }
                          // label="First"
                          labelPlacement="bottom"
                          style={{ color: "black" }}
                        />
                        {/* <FormControlLabel
                          control={
                            <Radio
                              checked={selectedTriggers.second === row.id}
                              onChange={() =>
                                handleSelectTrigger(row.id, "second")
                              }
                              name="secondTrigger"
                            />
                          }
                          label="Second"
                        /> */}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </CustomScrollbar>
        }
      />
    </div>
  );
}

export default Hubspot;
