import { toast } from "react-toastify";
import {
  deleteProjectService,
  getProjectByIdService,
  getProjectsService,
  saveProjectsService,
  updateProjectsService,
} from "../../Services/Project.service";
import { projectsType } from "../Types/All.types";

export const saveProjectsAction = (obj, toastId) => async () => {
  let res = await saveProjectsService(obj);
  if (res.status === 200) {
    toast.update(toastId, {
      render: "Data saved successfully!",
      type: "success",
      isLoading: false,
      autoClose: 3000,
    });
    return res.data;
  } else {
    alert(res.errMsg);
  }
};

export const updateProjectsAction = (obj, id, toastId,newPipline) => async () => {
  let res = await updateProjectsService(obj, id,newPipline);
  if (res?.status === 200) {
    toast.update(toastId, {
      render:res?.msg,
      type: "success",
      isLoading: false,
      autoClose: 3000,
    });
    return res.data;
  } else {
    toast.update(toastId, {
      render: res?.errMsg,
      type: "error",
      isLoading: false,
      autoClose: 3000,
    })
  }
};

export const getProjectsAction = () => async (dispatch) => {
  let res = await getProjectsService();
  await dispatch({
    type: projectsType.GET_PROJECTS_LIST,
    payload: res.data,
  });
};

export const getProjectByIdAction = (id) => async (dispatch) => {
  let res = await getProjectByIdService(id);
  await dispatch({
    type: projectsType.GET_SELECTED_PROJECT,
    payload: res,
  });
  return res
};

export const setSelectedProjectAction = (data) => async (dispatch) => {
  await dispatch({
    type: projectsType.GET_SELECTED_PROJECT,
    payload: data,
  });
};


export const deleteProjectsAction = (id) => async () => {
  let res = await deleteProjectService(id);
 return res
};