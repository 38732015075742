import React from "react";
import { Box, Container, Typography, Paper } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import TopRightLogo from "../CommonComponents/TopRightLogo";

const theme = createTheme({
  palette: {
    mode: "dark",
    background: {
      default: "#000",
    },
  },
  typography: {
    fontFamily: "Roboto, sans-serif",
  },
});
function CenterContainer({ text, body, logo, size, bg }) {
  return (
    <ThemeProvider theme={theme}>
      {logo && <TopRightLogo />}
      <Box
        sx={{
          height: "auto",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: bg || "background.default",
          marginTop: "50px",
        }}
      >
        <Container maxWidth={size || "sm"}>
          <Paper sx={{ padding: 4, bgcolor: "#000" }}>
            <Typography align="center" gutterBottom>
              {text}
            </Typography>
            {body}
          </Paper>
        </Container>
      </Box>
    </ThemeProvider>
  );
}

export default CenterContainer;
