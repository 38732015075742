import { Button } from "@mui/material";
import React from "react";

const FooterContent = ({ currentStep, handleBack, handleNext, nextButtonLabel, showBackButton = true }) => {
  return (
    <div
      style={{
        marginTop: 20,
        display: "flex",
        justifyContent: "end",
        gap: 8,
      }}
    >
      {showBackButton && (
        <Button
          sx={{
            textTransform: "none",
            border: "1px solid #D1D9EF",
            borderRadius: 2,
            color: "#5F6A8A",
          }}
          onClick={handleBack}
          disabled={currentStep === 0}
        >
          Back
        </Button>
      )}
      <button
        variant="contained"
        size="small"
        style={{
          background: "#4F46E5",
          borderRadius: 8,
          color: "white",
          padding: "10px 18px",
          border: "none",
          fontWeight: 600,
          outline: "none",
          fontSize: 14,
        }}
        onClick={handleNext}
      >
        {nextButtonLabel}
      </button>
    </div>
  );
};

export default FooterContent;
