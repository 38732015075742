import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import loader1 from "../../../Assests/Images/Backgrounds/ProspectingLead1.svg";
import loader2 from "../../../Assests/Images/Backgrounds/ProspectingLead2.svg";
import loader3 from "../../../Assests/Images/Backgrounds/ProspectingLead3.svg";
import SuccessBanner from "../../Shared/SuccessBanner";

const messages = [
  "Collecting Lead's data...",
  "Just relax, we are getting closer...",
  "Prospecting almost done...",
];
const images = [loader1, loader2, loader3];

const ProspectingLoader = ({ onComplete }) => {
  const [stage, setStage] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setStage((prev) => {
        if (prev === 2) {
          clearInterval(interval);
          onComplete();
        }
        return prev + 1;
      });
    }, 2000);
    return () => clearInterval(interval);
  }, [onComplete]);

  return (
    <Box p={1.5}>
      <Typography color="#1A1D23" fontSize={18} fontWeight={700} mb={2}>
        Prospecting your lead
      </Typography>
      <SuccessBanner title={messages[stage]} backgroundImage={images[stage]} />
    </Box>
  );
};

export default ProspectingLoader;
