import React from "react";
import { BootStrapInput } from "../../../Components/CommonComponents/Input";
import { Box, Typography } from "@mui/material";

const CreateLead = ({ leadDetails, handleChange }) => {
  const { leadName, jobTitle, company, phoneNumber, mailId, sourceLink } =
    leadDetails;

  return (
    <Box p={1}>
      <Typography variant="h6" fontWeight="700" gutterBottom marginBottom={2}>
        Upload Lead Manually
      </Typography>
      <Box display="flex" justifyContent="space-between" >
        <BootStrapInput
          value={leadName}
          name="leadName"
          label="Lead Name"
          placeholder="Enter Lead name here"
          onChange={handleChange}
          required={false}
          className="mb-3 w-48"
        />
        <BootStrapInput
          value={jobTitle}
          name="jobTitle"
          label="Job Title"
          placeholder="Executive, Manager, Director, etc..."
          onChange={handleChange}
          required={false}
          className="mb-3 w-49"
        />
      </Box>
      <Box display="flex" justifyContent="space-between" >
        <BootStrapInput
          value={company}
          name="company"
          label="Company"
          placeholder="Lead's company name here"
          onChange={handleChange}
          required={false}
          className="mb-3 w-48"
        />
        <BootStrapInput
          value={phoneNumber}
          name="phoneNumber"
          label="Phone Number"
          placeholder="Lead's phone number here"
          onChange={handleChange}
          required={false}
          className="mb-3 w-49"
        />
      </Box>

      <BootStrapInput
        value={mailId}
        name="mailId"
        label="Mail Id"
        placeholder="Enter Lead's mail id here"
        onChange={handleChange}
        required={false}
        className="mb-3 w-100"
      />
      <BootStrapInput
        value={sourceLink}
        name="sourceLink"
        label="Source Link"
        placeholder="Link like LinkedIn, website, etc..."
        onChange={handleChange}
        required={false}
        className="mb-3 w-100"
      />
    </Box>
  );
};

export default CreateLead;
