import React from "react";
import styles from "./dashboardStyles";
import { CheckCircleRounded } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";

const StepCompleted = () => (
  <Box
    sx={{
      display: "flex",
      justifyContent: "space-between",
      padding: 0.5,
      paddingRight: 1.5,
      bgcolor: "#EBFAF2",
      color: "#28764B",
      borderRadius: 2,
      marginTop: 1,
    }}
  >
    <Box sx={{ display: "flex", alignItems: "center", gap: 1, paddingX: 1 }}>
      <CheckCircleRounded
        sx={{ color: "#3D9E69", borderRadius: "50%", backgroundColor: "white" }}
      />
      <Typography sx={{ fontSize: 14, fontWeight: 600 }}>
        Create & Connect your first project
      </Typography>
    </Box>
    <Button sx={styles.completedButton}>COMPLETED</Button>
  </Box>
);

const OnBoardSummary = () => {
  return (
    <Box sx={styles.box}>
      <Typography variant="h5" sx={{ fontSize: 18, fontWeight: 700 }}>
        On board Summary
      </Typography>
      <StepCompleted />
      {["Project Name", "AI SDR Name", "Company URL", "Connected Hubspot"].map(
        (label, index) => (
          <Box
            key={index}
            display="flex"
            justifyContent="space-between"
            margin={1}
          >
            <Typography fontSize={13} fontWeight={500}>
              {label}
            </Typography>
            <Typography fontSize={13} fontWeight={500}>
              {
                [
                  "Onboarding",
                  "Luna",
                  "https://your-company-website.com",
                  "Onboarding CRM",
                ][index]
              }
            </Typography>
          </Box>
        )
      )}
    </Box>
  );
};

export default OnBoardSummary;
