import axios from "axios";
import { toast } from "react-toastify";

export const BASEURL = `${process.env.REACT_APP_BASE_URL}`;
// export const BASEURL = `${process.env.REACT_APP__LOCAL_BASE_URL}`;
export const BASEURL_AI = `${process.env.REACT_APP_BASE_URL_AI}`;
export const axiosClient = axios.create();
let loginData = JSON.parse(localStorage.getItem("login-data"));
let token = loginData?.token;
axiosClient.defaults.headers.common["Authorization"] = `Bearer ${token}`;
axiosClient.defaults.headers.post["Content-Type"] = "application/json";
axiosClient.defaults.headers.common[
  "dbUrl"
] = `${loginData?.accounts[0]?.accountUrl || "Vodex_1718870853046"}`;
axiosClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      toast.error(
        error?.response?.data?.errMsg || error?.response?.data?.detail
      );
    }
    if (error.toString() === "Error: Request failed with status code 403") {
      localStorage.clear();
      window.location.href = "/";
    }
  }
);
