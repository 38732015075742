import { accountType,  } from "../Types/All.types";

const initialState = {
    systemConstant:[],
    systemConstantAccount:[],
    selectedAccount:{}
  
};

function AccountReducer(state = initialState, action) {
  switch (action.type) {
    case accountType.SET_SYSTEM_CONSTANT: {
      return { ...state, systemConstant: action?.payload?.data[0] };
    }
    case accountType.SET_SYSTEM_CONSTANT_ACCOUNT: {
        return { ...state, systemConstantAccount: action?.payload?.data };
      }
      case accountType.SET_SELECTED_ACCOUNT: {
        return { ...state, selectedAccount: action?.payload };
      }
     
   
    default:
      return state;
  }
}

export default AccountReducer;
