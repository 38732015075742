import React from "react";
import "../../Assests/Css/Common-v.css";
import Line from "../../Assests/Images/Icons/Line 511.png";
import CheckMark from "../../Assests/Images/Icons/Checkmark.svg";
import Logo from "../../Assests/Images/Icons/Logo.png";
import Select from "react-select";
import { useDispatch } from "react-redux";
import {
  loginAction,
  setSelectedAccountAction,
} from "../../Redux/Actions/Common.actions";
import { axiosClient } from "../../Constants/Axios";
import { toast } from "react-toastify";
import { useState } from "react";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

const NewLogin = () => {
  const [state, setState] = useState({
    email: "",
    password: "",
  });

  const { email, password } = state;
  const dispatch = useDispatch();

  const onChange = (e) => {
    setState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const loginFunction = async () => {
    const toastId = toast.loading("Logging In...");

    let obj = {
      email,
      password,
    };
    let res = await dispatch(loginAction(obj));

    axiosClient.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${res.token}`;
    axiosClient.defaults.headers.common["dbUrl"] = `${
      res?.accounts[0]?.accountUrl || "Vodex_1718870853046"
    }`;
    dispatch(setSelectedAccountAction(res?.accounts[0]));
    // navigate("/main/dashboard");
    toast.update(toastId, {
      render: "Welcome",
      type: "success",
      isLoading: false,
      autoClose: 3000,
    });
  };

  return (
    <Box
      sx={{
        width: { xs: "95%", sm: "70%", lg: "100%" },
        display: { xs: "block", lg: "flex" },
        margin: { xs: "auto", lg: 0 },
      }}
      className="main-login-container"
    >
      <Box
        sx={{
          display: { xs: "none", lg: "block" },
          width: { lg: "60%" },
        }}
        className="img-container"
      >
        <div className="logo-container">
          <img src={Logo} alt="" />
          <h2>ZEN</h2>
        </div>
        <div className="img-overlay mt-100 ml-11">
          <h2>
            <span className="super-text">Supercharge</span> your Business with
            Sales, Trust{" "}
            <span style={{ color: "#6158F7", fontWeight: 700 }}>ZEN</span>, your
            most needed AI SDR.
          </h2>
          <span>
            <img src={Line} alt="" />
          </span>
          <div className="list-item-cont mt-1">
            <p className="list-items">
              <span className="mr-11">
                <img src={CheckMark} />
              </span>
              Unlimited Lead Generation
            </p>
            <p className="list-items">
              <span className="mr-11">
                <img src={CheckMark} />
              </span>
              Powerful AI Prospecting
            </p>
            <p className="list-items">
              <span className="mr-11">
                <img src={CheckMark} />
              </span>
              Easy Integration
            </p>
          </div>
        </div>
      </Box>

      <Box className="input-container mt-24">
        <Box
          sx={{
            height: "595px",
            width: { xs: "95%", md: "443px" },
          }}
        >
          <div>
            <span className="heading-small">Welcome to ZEN! Your AI SDR</span>
            <h3 className="heading-main">Get Started with your email!</h3>
          </div>
          <div className="form-container">
            <div className="w-100 mt-5 mb-5 ">
              <label className="input-label">Email</label>
              <input
                className="custom-input ip-placeholder"
                name="email"
                type="text"
                placeholder="Enter your registered email"
                value={email}
                onChange={onChange}
              />
            </div>
            <div>
              <label className="input-label">Password</label>
              <div className="password-input-container w-100">
                <input
                  value={password}
                  name="password"
                  type="password"
                  placeholder="Enter your password"
                  className="password-input ip-placeholder"
                  onChange={onChange}
                />
                <span className="forgot-password-link">Forgot Password?</span>
              </div>
            </div>

            <button onClick={loginFunction} className="custom-button mt-10">
              Login
            </button>
            <p className="small-text font-weight-500 mt-6">
              Don’t have an account? <span className="link-text-underline">Request Access</span>
            </p>
          </div>
        </Box>
      </Box>
    </Box>
  );
};

export default NewLogin;
