import React, { useState } from "react";
import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";

const renderSteps = (currentStep, steps, setCurrentStep) => {
  return (
    <Box display="flex" alignItems="center">
      {steps.map((step, index) => (
        <React.Fragment key={index}>
          {currentStep === index ? (
            <Box display="flex" gap={1} alignItems="center" mx={0.5}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                width={28}
                height={28}
                borderRadius={2}
                sx={{
                  backgroundColor: "#4F46E5",
                }}
              >
                <Button
                  sx={{ color: "white" }}
                  disableTouchRipple
                  onClick={() => setCurrentStep(index)}
                >
                  {step.step}
                </Button>
              </Box>
              <Typography
                color="#1C233E"
                fontSize={14}
                fontWeight={500}
                mt={0.5}
              >
                {step.label}
              </Typography>
            </Box>
          ) : (
            <Tooltip title={step.label} arrow>
              <Box display="flex" gap={1} alignItems="center" mx={0.5}>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width={28}
                  height={28}
                  borderRadius={2}
                  sx={{
                    backgroundColor: "#E4E8F1",
                    "&:hover": {
                      cursor: "pointer",
                    },
                  }}
                >
                  <Button
                    sx={{ color: "black" }}
                    disableTouchRipple
                    onClick={() => setCurrentStep(index)}
                  >
                    {step.step}
                  </Button>
                </Box>
              </Box>
            </Tooltip>
          )}
          {index < steps.length - 1 && (
            <Box width={40} height={2} bgcolor="#BDC3D2" mx={1} />
          )}
        </React.Fragment>
      ))}
    </Box>
  );
};

const Steps = ({ currentStep, setCurrentStep, steps, header, closeModal }) => {
  const [openDialog, setOpenDialog] = useState(false);

  const handleCloseModal = () => {
    setOpenDialog(true);
  };

  const handleConfirmClose = () => {
    closeModal();
    setOpenDialog(false);
  };

  const handleCancelClose = () => {
    setOpenDialog(false);
  };
  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        {header ? (
          <Typography px={1} color="#1C233E" fontSize={14} fontWeight={500}>
            {header}
          </Typography>
        ) : (
          renderSteps(currentStep, steps, setCurrentStep)
        )}
        <IconButton onClick={handleCloseModal} edge="end">
          <Close />
        </IconButton>
      </Box>
      <Dialog open={openDialog} onClose={handleCancelClose}>
        <DialogTitle>Confirm Close</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to close the modal?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmClose} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Steps;
