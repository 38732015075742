import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { BootstrapInput } from "../../../Components/CommonComponents/Input";
import CustomScrollbar from "../../../Components/CommonComponents/CustomScrollbar";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { toast } from "react-toastify";
import emptyStateIcon from "../../../Assests/Images/empty-state.svg";
import { Close } from "@mui/icons-material";
import { Link } from "react-router-dom";

const PersonalizeAIDetailsStep = ({
  files,
  setFiles,
  onboardingState,
  handleChange,
  websiteUrls,
  setWebsiteUrls,
}) => {
  const { mainUrl } = onboardingState;

  const onChangeWeb = (value, idx) => {
    let copy = websiteUrls;
    copy[idx] = value;

    setWebsiteUrls([...copy]);
  };

  const deleteWeb = async (idx) => {
    let copy = websiteUrls;
    copy.splice(idx, 1);
    setWebsiteUrls([...copy]);
  };

  const addWeb = async () => {
    let copy = websiteUrls;
    setWebsiteUrls(["", ...copy]);
  };
  const handleFileUpload = (event) => {
    const newFiles = Array.from(event.target.files).filter(
      (file) =>
        file.type === "application/pdf" ||
        file.type === "text/plain" ||
        file.name.endsWith(".exe")
    );

    if (newFiles.length > 0) {
      setFiles([...files, ...newFiles]);
    } else {
      toast.error("Please upload valid files (.pdf, .txt, .exe).");
    }
  };

  const handleRemoveFile = (index) => {
    const updatedFiles = files.filter((_, i) => i !== index);
    setFiles(updatedFiles);
  };

  return (
    <Box>
      <InputLabel shrink sx={{ marginBottom: "0px" }}>
        Upload your data (10mb max)
      </InputLabel>
      <Box
        sx={{
          border: "2px dashed #C4C4C4",
          borderRadius: 3,
          padding: "0 0 10px 0",
          textAlign: "center",
          marginBottom: "10px",
        }}
      >
        <img src={emptyStateIcon} alt="Empty State" />
        <Typography
          sx={{ color: "#7E8AAE", fontSize: 14, fontWeight: 500 }}
        >
          upload your files
        </Typography>
        <span style={{ color: "#7E8AAE", fontSize: 12 }}>
          Drag and drop files here or
        </span>

        <Button
          variant="text"
          component="label"
          sx={{
            textTransform: "none",
            "&:hover": {
              backgroundColor: "transparent",
            },
          }}
        >
          <span
            style={{
              color: "#7E8AAE",
              fontSize: 12,
              textDecoration: "underline",
            }}
          >
            choose files
          </span>
          <input
            type="file"
            hidden
            multiple
            accept=".pdf"
            onChange={handleFileUpload}
          />
        </Button>

        <Typography
          sx={{ color: "#7E8AAE", fontSize: 12, marginTop: 0 }}
        >
          Only .pdf, .txt, .exe files are accepted
        </Typography>
      </Box>
      {files?.length > 0 && (
        <CustomScrollbar height="6vh">
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: "4px" }}>
            {files.map((file, index) => (
              <Box
                key={index}
                sx={{ display: "flex", alignItems: "center", paddingX: 1 }}
              >
                <Link
                  to={file.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    fontSize: 14,
                    color: "#4F46E5",
                    display: "flex",
                    alignItems: "center",
                    mb: 1,
                  }}
                >
                  {file.name}
                </Link>
                <IconButton
                  onClick={() => handleRemoveFile(index)}
                  size="small"
                  sx={{ color: "#FF0000" }}
                >
                  <Close />
                </IconButton>
              </Box>
            ))}
          </Box>
        </CustomScrollbar>
      )}

      <BootstrapInput
        label="Upload URL"
        name="mainUrl"
        value={mainUrl}
        onChange={handleChange}
        placeholder="https://your-company-website.com"
        className={"mb-2 w-100"}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              onClick={addWeb}
              edge="end"
              size="small"
              outline="none"
              sx={{
                backgroundColor: "#F5F5F7",
                color: "#9CA3AF",
                borderRadius: 2,
                paddingX: "6px",
                margin: "12px",
                outline: "none",
                fontSize: 14,
                "&:hover": {
                  backgroundColor: "#ADBDD9",
                  color: "#F5F5F7",
                },
              }}
            >
              <AddIcon />
              Add Sub URL
            </IconButton>
          </InputAdornment>
        }
        sx={{
          fontWeight: 500,
          fontSize: 16,
          color: "#6C7594",
          marginBottom: "10px",
        }}
      />
      {websiteUrls.length > 0 && (
        <CustomScrollbar height="14vh">
          {websiteUrls.map((web, idx) => (
            <div className="mt-2" key={`web${idx + 1}`}>
              <TextField
                variant="outlined"
                value={web}
                onChange={(e) => onChangeWeb(e.target.value, idx)}
                sx={{
                  borderRadius: "10px",
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "5px",
                    paddingRight: 0,
                  },
                  "& .MuiInputBase-input": {
                    padding: "10px 14px",
                  },
                }}
                className={"m-0 w-100"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        sx={{
                          borderRadius: "5px",
                          color: "#6B7280",
                        }}
                        onClick={() => deleteWeb(idx)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          ))}
        </CustomScrollbar>
      )}
    </Box>
  );
};

export default PersonalizeAIDetailsStep;
