import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  emailRedirectAction,
  hubspotRedirectAction,
} from "../../Redux/Actions/Hubspot.actions";

function RedirectConnectionPage() {
  const dispatch = useDispatch();
  const location = useLocation();
  const locSearch = new URLSearchParams(location.search);
  let code = locSearch.get("code");
  let authType = locSearch.get("authType");
  const projectId = localStorage.getItem("newProjectId");
  const navigate = useNavigate();
  useEffect(() => {
    const init = async () => {
      if (authType === "gmail") {
        await dispatch(emailRedirectAction(code, projectId));
      } else {
        await dispatch(hubspotRedirectAction(code, projectId));
      }
      navigate("/main/getting-started?step=2");
    };
    init();
  }, []);
  return (
    <div className="d-flex justify-content-center mt-5">
      <h5>Redirecting...</h5>
    </div>
  );
}

export default RedirectConnectionPage;
