import React from "react";
import { Box } from "@mui/material";
import HeaderContent from "../../Shared/HeaderContent";
import SuccessBanner from "../../Shared/SuccessBanner";
import successIcon from "../../../Assests/Images/Backgrounds/CallSimulationSuccess.svg";

const CallSimulationSuccess = () => {
  return (
    <Box p={1}>
      <HeaderContent title="Call simulation" />
      <SuccessBanner
        confetti={true}
        title="Call triggered successfully"
        backgroundImage={successIcon}
      />
    </Box>
  );
};

export default CallSimulationSuccess;
