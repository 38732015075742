import {
  AppBar,
  Toolbar,
  Box,
  InputBase,
  styled,
  Menu,
  MenuItem,
  IconButton,
  ButtonBase,
} from "@mui/material";
import {
  Menu as MenuIcon,
  Tune,
  HelpOutlineOutlined,
  SettingsOutlined,
  AppsOutlined,
  AccountCircleOutlined,
  Search,
  LogoutOutlined,
} from "@mui/icons-material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import vodexImg from "../../../Assests/Images/image1.png";
import NotificationPanel from "../../Pages/Dashboard/Notifications";
import { AddIcon } from "../IconsPage";

const StyledAppBar = styled(AppBar)`
  background: #fff;
  box-shadow: none;
  border: none;
`;

const SearchWrapper = styled(Box)`
  background: #1d1d1d; // Updated to match dark theme
  margin-left: 95px;
  border-radius: 12px;
  min-width: 450px;
  max-width: 720px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  & > div {
    width: 100%;
  }

  @media (max-width: 868px) {
    min-width: 250px;
    margin-left: 65px;
  }
  @media (max-width: 679px) {
    min-width: 150px;
    margin-left: 20px;
  }
`;

const OptionsWrapper = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: end;
  & > svg {
    margin-left: 20px;
  }
  cursor: pointer;
`;

const Header = ({ toggleDrawer }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    navigate("/");
    localStorage.clear();
    toast.success("User Logout Successfully");
  };
  const AddProjectButton = styled(ButtonBase)({
    borderRadius: "10px",
    boxShadow: "0 0 20px rgba(0, 245, 255, 0.6)",
    border: "1px solid rgba(0, 245, 255, 0.6)",
    color: "white",
    padding: "20px",
    maxWidth: "250px",
    height: "40px",
    cursor: "pointer",
    marginTop: "-8px",
    "&:hover": {
      backgroundColor: "#000",
    },
    background: "linear-gradient(180deg, #00BFFF 0%, #015B79 100%)",
  });

  return (
    <StyledAppBar position="static">
      <Toolbar>
        <MenuIcon
          sx={{ cursor: "pointer", color: "white" }} // Updated to white for dark theme
          onClick={toggleDrawer}
        />
        <img
          src={vodexImg}
          alt="logo"
          style={{ width: 100, marginLeft: "5%" }}
        />
        {/* <SearchWrapper>
          <Search sx={{ color: "white" }} />
          <InputBase sx={{ color: "white" }} placeholder="Search..." />
          <Tune sx={{ color: "white" }} />
        </SearchWrapper> */}

        <OptionsWrapper>
          <button
            className="btn gradient-text m-2 "
            onClick={() => navigate("/get-started/create-project")}
            style={{ border: "1px solid #009199 " }}
          >
            Add New Project
          </button>
        </OptionsWrapper>
      </Toolbar>
    </StyledAppBar>
  );
};

export default Header;
