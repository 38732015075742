import { Box, Typography } from "@mui/material";
import React from "react";
import feedback from "../../../Assests/Images/Backgrounds/Feedback.svg";
import SuccessBanner from "../../Shared/SuccessBanner";

const FinalFeedback = () => {
  return (
    <Box p={1.5}>
      <Typography color="#1A1D23" fontSize={18} fontWeight={700} mb={2}>
        Hurray! Your AI SDR <span style={{ color: "#4F46E5" }}> Luna</span> is getting stronger!
      </Typography>
      <SuccessBanner
        title="It is becoming more powerful!"
        backgroundImage={feedback}
      />
    </Box>
  );
};

export default FinalFeedback;
