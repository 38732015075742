import { combineReducers } from "redux";
import ProjectReducer from "./Project.reducer";
import HubspotReducer from "./Hubspot.reducer";
import DealsReducer from "./Deals.reducer";
import AccountReducer from "./Account.reducer";
import { LOGIN_SUCCESS, LOGOUT, UPDATE_CURRENT_PATH } from "../Types/All.types";

const initialState = {
  page: { area: "home", subArea: "" },
  isAuthenticated: false,
  user: null,
};
function rootReducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload,
      };
    case LOGOUT:
      return initialState;
      case UPDATE_CURRENT_PATH:
        return { ...state, page: { area: action.area, subArea: action.subArea } };

    default:
      return state;
  }
  
}

const RootReducer = combineReducers({
  root: rootReducer,
  ProjectReducer: ProjectReducer,
  HubspotReducer: HubspotReducer,
  DealsReducer: DealsReducer,
  AccountReducer:AccountReducer
});

export default RootReducer;
