import React, { useEffect, useState } from "react";
import {
  Box,
  Drawer,
  Grid,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import Select from "react-select";

import ArrowRight from "../../Assests/Images/Icons/arrow-right.svg";
import Email from "../../Assests/Images/Icons/Email.png";
import Phone from "../../Assests/Images/Icons/Phone.png";
import Avatar from "../../Assests/Images/Icons/AvatarBox.png";
import Dots from "../../Assests/Images/Icons/Dots.png";
import plus from "../../Assests/Images/Icons/plus.svg";
import Edit from "../../Assests/Images/Icons/Edit.svg";
import Trash from "../../Assests/Images/Icons/trash.png";
import CustomScrollbar from "../CommonComponents/CustomScrollbar";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { saveCompanyDeatilsAction } from "../../Redux/Actions/Common.actions";
import { toast } from "react-toastify";

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    padding: "8px 3px",
    border: "1px solid #374151",
    borderRadius: "8px",
    outline: "none",
    boxShadow: "none",
    "&:hover": {
      border: "1px solid #374151",
    },
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: "5px",
    marginTop: "5px",
    padding: "0",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "#5fbec6" : "#eaf5f6",
    color: state.isFocused ? "white" : "black",
    padding: "10px 15px",
    cursor: "pointer",
    fontFamily: "Instrument Sans",
    fontWeight: 500,
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#333",
    fontFamily: "Instrument Sans",
    fontWeight: 500,
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: "13px",
    fontWeight: 500,
    color: "#8792a7",
  }),
};

const phoneSelect = {
  control: (provided) => ({
    ...provided,
    border: "none",
    boxShadow: "none",
    padding: "9px 2px",
    minWidth: "80px",
    "&:hover": {
      border: "none",
    },
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: "5px",
    marginTop: "5px",
    padding: "0",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "#5fbec6" : "#eaf5f6",
    color: state.isFocused ? "white" : "black",
    padding: "10px 15px",
    cursor: "pointer",
    fontFamily: "Instrument Sans",
    fontWeight: 500,
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#333",
    fontFamily: "Instrument Sans",
    fontWeight: 500,
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: "13px",
    fontWeight: 500,
    color: "#8792a7",
  }),
};
const countryOptions = [
  { value: "+91", label: "+91" },
  { value: "+1", label: "+1" },
  { value: "+44", label: "+44" },
];
const statusOptions = [
  { value: "Active", label: "Active" },
  { value: "Offline", label: "Offline" },
];

// Initial
const initialAgents = [
  {
    id: 1,
    photo: "https://via.placeholder.com/150",
    name: "John Doe",
    email: "john.doe@example.com",
    phone: "123-456-7890",
    status: "Active",
  },
];

const AgentSettings = ({ open, toggleDrawer }) => {
  const dispatch = useDispatch();
  const [agents, setAgents] = useState(initialAgents);
  const [editingAgent, setEditingAgent] = useState(null);
  const [isCreating, setIsCreating] = useState(false);
  const [newAgent, setNewAgent] = useState({
    photo: "https://via.placeholder.com/150",
    name: "",
    email: "",
    phone: "",
    status: "",
  });

  const systemConstantAccount = useSelector((state) => state.AccountReducer.systemConstantAccount );

  const [liveSettings, setLiveSettings] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [agent, setAgent] = useState(false);
  // New Phone State
  const [phoneData, setPhoneData] = useState({
    countryCode: "+91", // Default country code
    phoneNumber: "",
  });

  const handleCountryCodeChange = (selectedOption) => {
    setPhoneData((prev) => ({
      ...prev,
      countryCode: selectedOption.value,
    }));
  };

  const handlePhoneNumberChange = (e) => {
    const { value } = e.target;
    setPhoneData((prev) => ({
      ...prev,
      phoneNumber: value,
    }));
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate();
  useEffect(() => {
    const liveAgentData = systemConstantAccount?.find(
      (o) => o.type === "LIVE_AGENTS"
    );
    if (liveAgentData?.details && liveAgentData?.details?.length > 0) {
      setAgents([...liveAgentData?.details]);
    }
  }, [systemConstantAccount]);

  const addAgent = async () => {
    const toastId = toast.loading("Saving...");
    const combinedPhone = `${phoneData.countryCode}${phoneData.phoneNumber}`;
    const agentData = { ...newAgent, phone: combinedPhone };

    let obj = {
      type: "LIVE_AGENTS",
      details: {
        ...agents,
        ...agentData,
      },
    };
    await dispatch(saveCompanyDeatilsAction(obj, toastId));
    setAgents([...agents, agentData]);
    setIsCreating(false);
  };

  const editAgent = (id) => {
    const agent = agents.find((agent) => agent.id === id);
    setEditingAgent(agent);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditingAgent({ ...editingAgent, [name]: value });
  };

  const saveAgent = () => {
    setAgents(
      agents.map((agent) =>
        agent.id === editingAgent.id ? editingAgent : agent
      )
    );
    setEditingAgent(null);
  };

  const handleCreateInputChange = (e) => {
    const { name, value } = e.target;
    setNewAgent({ ...newAgent, [name]: value });
  };

  const DrawerBody = (
    <>
      <Box
        sx={{
          width: { xs: "100%", md: "874px" },
        }}
      >
        <Box height="110px">
          <Box
            padding="24px"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
              borderBottom: "1px solid #E5E7EB",
            }}
          >
            <span onClick={toggleDrawer(false)}>
              <img style={{ cursor: "pointer" }} src={ArrowRight} alt="hell" />
            </span>
            <Typography
              className="font-size-24 font-weight-600"
              fontFamily="Red Hat Display"
            >
              Settings
            </Typography>
          </Box>
        </Box>

        <Box sx={{ padding: { xs: "13px", sm: "30px" }, width: "100%" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: { xs: "column", md: "row" },
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                height: "62px",
                width: "370px",
                padding: "10px 20px",
                backgroundColor: "#F3F7FD",
                borderRadius: "12px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span
                className="unselected-option"
                style={{ cursor: "pointer" }}
                onClick={() => setLiveSettings(true)}
              >
                Live Agent
              </span>
            </Box>
            <Box sx={{ marginTop: { xs: "15px", md: 0 } }}>
              <button onClick={() => setAgent(true)} className="agent-grad-btn">
                <img
                  style={{ marginRight: "6px" }}
                  src={plus}
                  alt=""
                  srcSet=""
                />
                <span>Add Live Agent</span>
              </button>
            </Box>
          </Box>
        </Box>
        <CustomScrollbar height={"90vh"}>
          {agent === false && (
            <Box
              sx={{
                padding: { xs: "13px 13px 0 13px", sm: "30px 30px 0 30px" },
              }}
            >
              <Grid container spacing={2}>
                {agents?.map((agent, index) => (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    key={index}
                    display="flex"
                    justifyContent="center"
                  >
                    <Box
                      sx={{
                        height: "162px",
                        width: "100%",
                        maxWidth: "398px",
                        border: "1px solid #E5E7EB",
                        borderRadius: "8px",
                      }}
                    >
                      <Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            fontFamily: "Instrument sans",
                            padding: "20px",
                          }}
                        >
                          <Box>
                            <img
                              width={"43px"}
                              height="43px"
                              style={{
                                borderRadius: "100%",
                              }}
                              src={agent?.photo}
                              alt=""
                            />
                          </Box>
                          <Box sx={{ width: "230px" }}>
                            <p
                              style={{
                                color: "#6B7280",
                                fontWeight: 500,
                                fontSize: "20px",
                              }}
                            >
                              {agent?.name}
                            </p>
                            <span
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {agent.status === "Active" && (
                                <span
                                  style={{
                                    height: "8px",
                                    width: "8px",
                                    borderRadius: "100%",
                                    background: "#22C55E",
                                    marginRight: "10px",
                                  }}
                                ></span>
                              )}
                              <span
                                style={{
                                  fontWeight: 400,
                                  fontSize: "14px",
                                  color: "#6B7280",
                                }}
                              >
                                {agent?.status}
                              </span>
                            </span>
                          </Box>
                          <Box>
                            <IconButton onClick={handleClick}>
                              <img src={Dots} />
                            </IconButton>
                            <Menu
                              anchorEl={anchorEl}
                              open={Boolean(anchorEl)}
                              onClose={handleClose}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                              PaperProps={{
                                sx: {
                                  boxShadow: "none",
                                  border: "1px solid #E5E7EB",
                                  borderRadius: "8px",
                                },
                              }}
                            >
                              <MenuItem onClick={handleClose}>
                                <ListItemIcon>
                                  <img src={Edit} alt="" srcSet="" />
                                </ListItemIcon>
                                <Typography
                                  color="#6B7280"
                                  fontSize="16px"
                                  fontWeight="400"
                                  variant="inherit"
                                >
                                  Edit
                                </Typography>
                              </MenuItem>
                              <MenuItem onClick={handleClose}>
                                <ListItemIcon>
                                  <img src={Trash} alt="" srcSet="" />
                                </ListItemIcon>
                                <Typography
                                  fontSize="16px"
                                  fontWeight="400"
                                  color="#EF4444"
                                  variant="inherit"
                                >
                                  Delete
                                </Typography>
                              </MenuItem>
                            </Menu>
                          </Box>
                        </Box>
                        <Box sx={{ borderTop: "1px solid #E5E7EB" }}></Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: "16px 20px",
                          }}
                        >
                          <Typography
                            fontFamily="Red Hat Display"
                            fontSize="12px"
                            fontWeight={400}
                            color="#000712"
                          >
                            <img className="mr-2" src={Email} alt="" />{" "}
                            {agent?.email}
                          </Typography>
                          <Typography
                            fontFamily="Red Hat Display"
                            fontSize="12px"
                            fontWeight={400}
                            color="#000712"
                          >
                            <img className="mr-2" src={Phone} alt="" />{" "}
                            {agent?.phone}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
          )}
          {agent === true && (
            <Box sx={{ padding: "30px 30px 0 30px" }}>
              <h3 style={{ color: "#6B7280" }}>New Agent Creation</h3>
              <Box>
                <div className="w-100 mt-5 mb-5 ">
                  <label
                    style={{ color: "#6B7280", fontWeight: 500 }}
                    className="font-size-16"
                  >
                    Name
                  </label>
                  <input
                    style={{ borderColor: "#374151" }}
                    className="custom-input ip-placeholder"
                    name="name"
                    type="text"
                    placeholder="Enter your name"
                    value={newAgent.name}
                    onChange={handleCreateInputChange}
                  />
                </div>
                <div className="w-100 mt-5 mb-5 ">
                  <label
                    style={{ color: "#6B7280", fontWeight: 500 }}
                    className="font-size-16"
                  >
                    Email
                  </label>
                  <input
                    style={{ borderColor: "#374151" }}
                    className="custom-input ip-placeholder"
                    name="email"
                    type="text"
                    placeholder="Enter your email"
                    value={newAgent.email}
                    onChange={handleCreateInputChange}
                  />
                </div>
                <div className="w-100 mt-5 mb-5 ">
                  <label
                    style={{ color: "#6B7280", fontWeight: 500 }}
                    className="font-size-16"
                  >
                    Phone
                  </label>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      border: "1px solid #374151",
                      borderRadius: "8px",
                    }}
                  >
                    <Select
                      options={countryOptions}
                      styles={phoneSelect}
                      classNamePrefix="select"
                      className="basic-single"
                      isSearchable={false}
                      defaultValue={countryOptions[0]}
                      onChange={handleCountryCodeChange}
                    />
                    <input
                      type="text"
                      className="ip-placeholder"
                      name="phoneNumber"
                      placeholder="Enter your phone number"
                      value={phoneData.phoneNumber}
                      onChange={handlePhoneNumberChange}
                      style={{
                        flex: 1,
                        padding: "10px",
                        border: "none",
                        outline: "none",
                        fontSize: "16px",
                        fontWeight: 500,
                      }}
                    />
                  </div>
                </div>
                <div className="w-100 mt-5">
                  <label
                    style={{ color: "#6B7280", fontWeight: 500 }}
                    className="font-size-16"
                  >
                    Status
                  </label>
                  <Select
                    options={statusOptions}
                    styles={customStyles}
                    className="basic-single"
                    classNamePrefix="select"
                    isSearchable={true}
                    value={statusOptions.find(
                      (option) => option.value === newAgent.status
                    )}
                    onChange={(selectedOption) =>
                      handleCreateInputChange({
                        target: { name: "status", value: selectedOption.value },
                      })
                    }
                  />
                </div>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "100px",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <button
                    onClick={() => setAgent(false)}
                    className="gradient-text-button"
                  >
                    Close
                  </button>
                  <button
                    // disabled={
                    //   !newAgent.name ||
                    //   !newAgent.email ||
                    //   !newAgent.phone ||
                    //   !newAgent.status
                    // }
                    onClick={() => addAgent()}
                    className="grad-bg-btn"
                  >
                    Save
                  </button>
                </Box>
              </Box>
            </Box>
          )}
        </CustomScrollbar>
      </Box>
    </>
  );

  return (
    <>
      <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
        {DrawerBody}
      </Drawer>
    </>
  );
};

export default AgentSettings;
