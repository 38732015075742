import React from "react";
import { Box, Typography, CircularProgress } from "@mui/material";
import { styled } from "@mui/system";

const Container = styled(Box)({
  borderRadius: "20px",
  padding: "20px",
  maxWidth: "347px",
  minHeight: "457px",
  textAlign: "center",
});

const Header = styled(Typography)({
  fontWeight: "bold",
  marginBottom: "10px",
});

const SubText = styled(Typography)({
  color: "#555", // Example gray color for subtext
  marginBottom: "20px",
});

const ProgressContainer = styled(Box)({
  position: "relative",
  display: "inline-flex",
  justifyContent: "center",
  alignItems: "center",
  border: "5px #009199 solid",
  borderRadius: "50%",
});

const ProgressCircle = styled(CircularProgress)({
  // color: "linear-gradient(135deg, #43cea2, #185a9d)", // Gradient border for circle
  color: "#009199", // Gradient border for circle
});

const ProgressText = styled(Typography)({
  position: "absolute",
  fontWeight: "bold",
  fontSize: "24px",
});

const WelcomeCard = ({ value }) => {
  return (
    <Container className="progress-bg">
      <Header align="left" className="font-size-20 color-black">
        Welcome to Zen
      </Header>
      <SubText align="left" className="font-size-15">
        Enter your credentials to create a Vodex account. This may take a few
        minutes.
      </SubText>
      <ProgressContainer className="mt-8">
        <ProgressCircle
          variant="determinate"
          value={value}
          size={150}
          thickness={3.6}
        />

        <ProgressText className="color-black">{value}%</ProgressText>
      </ProgressContainer>
    </Container>
  );
};

export default WelcomeCard;
