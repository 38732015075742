import { API } from "../Constants/ApiRoutes";
import { BASEURL, axiosClient } from "../Constants/Axios";

export const saveProjectsService = async (data) => {
  try {
    let res = await axiosClient.post(`${BASEURL}${API.PROJECT.PROJECT}`, data);
    return res;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const updateProjectsService = async (data, id,newPipline) => {
  try {
    let res = await axiosClient.put(
      `${BASEURL}${API.PROJECT.PROJECT}/${id}?pipelineCreation=${newPipline ?true:false}`,
      data
    );
    return res;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getProjectsService = async (data, id) => {
  try {
    let res = await axiosClient.get(`${BASEURL}${API.PROJECT.GET_PROJECTS}`);
    return res.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getProjectByIdService = async (id) => {
  try {
    let res = await axiosClient.get(`${BASEURL}${API.PROJECT.PROJECT}/${id}`);
    return res.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};



export const deleteProjectService = async (id) => {
  try {
    let res = await axiosClient.delete(`${BASEURL}${API.PROJECT.PROJECT}/${id}`);
    return res.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};
