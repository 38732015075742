import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import HeaderContent from "../../Shared/HeaderContent";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const CallSimulation = () => {
  const [phone, setPhone] = useState("");

  const handlePhoneChange = (value) => {
    setPhone(value);
  };

  return (
    <Box p={1.5}>
      <HeaderContent
        title={"Ready for the call simulation?"}
        subtitle={"Here’s a set of questions to qualify the lead"}
      />
      <Box py={1} minHeight={155}>
        <Typography fontSize={14} fontWeight={500} gutterBottom>
          Phone Number
        </Typography>
        <PhoneInput
          country={"us"}
          value={phone}
          onChange={handlePhoneChange}
          inputProps={{
            name: "phone",
            required: true,
            placeholder: "Enter the phone number now.",
          }}
          inputStyle={{
            width: "100%",
            paddingLeft: 80,
            borderRadius: 4,
            height: 40,
            border: "1px solid #c4c4c4",
          }}
          containerStyle={{
            width: "100%",
          }}
          buttonStyle={{
            width: 70,
            border: "none",
            backgroundColor: "#F5F7FB",
            paddingLeft: 10,
            height: 40,
            display: "flex",
            alignItems: "center",
            border: "1px solid #c4c4c4",
          }}
          dropdownStyle={{
            fontSize: 14,
          }}
        />
      </Box>
    </Box>
  );
};

export default CallSimulation;
