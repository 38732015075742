import { Box, Dialog, DialogContent } from "@mui/material";
import React, { useState } from "react";
import Steps from "../../Shared/Steps";
import FooterContent from "../FooterContent";
import ProspectingLoader from "./ProspectingLoader";
import FindingPerfectLead from "./FindingPerfectLead";
import ProspectingLead from "./ProspectingLead";
import EmailCadence from "./EmailCadence";
import Feedback from "../Feedback";
import FinalFeedback from "./FinalFeedback";
import CreateLead from "./CreateLead";

const headerSteps = [
  {
    step: 1,
    label: "Finding Leads",
  },
  {
    step: 2,
    label: "Prospecting",
  },
  {
    step: 3,
    label: "Email",
  },
];

const ModalContent = ({ openSecondStageModal, setOpenSecondStageModal,completeSecondStep }) => {
  const [showLoader, setShowLoader] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedRating, setSelectedRating] = useState(null);
  const [feedback, setFeedback] = useState("");

  const [leadDetails, setLeadDetails] = useState({
    leadName: "",
    jobTitle: "",
    companyName: "",
    phoneNumber: "",
    mailId: "",
    sourceLink: "",
  });

  const handleChange = (e) => {
    setLeadDetails((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };
  const handleBack = () => {
    if (currentStep === 5) {
      setCurrentStep(0);
    } else {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleNext = () => {
    if(currentStep === 4){
      completeSecondStep();
      setCurrentStep(0);
    }else if (currentStep === 0 || currentStep === 5) {
      setShowLoader(true);
    } else if (currentStep < 4) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handleLoaderComplete = () => {
    setShowLoader(false);
    setCurrentStep(1);
  };

  const renderStepContent = () => {
    if (showLoader) {
      return <ProspectingLoader onComplete={handleLoaderComplete} />;
    }
    switch (currentStep) {
      case 0:
        return <FindingPerfectLead openCreateLead={() => setCurrentStep(5)} />;
      case 1:
        return <ProspectingLead />;
      case 2:
        return <EmailCadence />;
      case 3:
        return (
          <Feedback
            selectedRating={selectedRating}
            setSelectedRating={setSelectedRating}
            feedback={feedback}
            setFeedback={setFeedback}
          />
        );
      case 4:
        return <FinalFeedback />;
      case 5:
        return (
          <CreateLead leadDetails={leadDetails} handleChange={handleChange} />
        );
      default:
        return null;
    }
  };
  const closeModal = () => {
    setLeadDetails([]);
    setCurrentStep(0);
    setOpenSecondStageModal(false);
  };

  const getNextButtonLabel = (currentStep) => {
    if (currentStep === 3) return "Submit";
    if (currentStep === 4) return "Okay,close";
    return "Save & Continue";
  };

  const getActiveSteps = (currentStep) => {
    if (showLoader) return 1;
    return currentStep === 5 ? 0 : currentStep;
  };
  return (
    <Dialog
      open={openSecondStageModal}
      fullWidth
      disableEscapeKeyDown
      sx={{
        "& .MuiDialog-paper": {
          width: 700,
          maxWidth: "100%",
        },
      }}
    >
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          paddingBottom: 2.5,
        }}
      >
        <Steps
          currentStep={getActiveSteps(currentStep)}
          setCurrentStep={setCurrentStep}
          steps={headerSteps}
          header={(currentStep === 3 || currentStep === 4) && "Feedback "}
          closeModal={closeModal}
        />
        <Box
          style={{
            flexGrow: 1,
            overflowY: "auto",
            marginBottom: 2.5,
            marginTop: 8,
          }}
        >
          {renderStepContent()}
        </Box>
        {!showLoader && (
          <FooterContent
            currentStep={currentStep}
            handleBack={handleBack}
            handleNext={handleNext}
            showBackButton={[0, 3, 4].includes(currentStep) ? false : true}
            nextButtonLabel={getNextButtonLabel(currentStep)}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ModalContent;
