import React from "react";
import { Button, Typography } from "@mui/material";
import projectSuccessImg from "../../../Assests/Images/Backgrounds/ProjectSuccessful.svg";
import bellIcon from "../../../Assests/Images/Icons/bell.svg";
import SuccessBanner from "../../Shared/SuccessBanner";

const FinalizeAIDetailsStep = () => {
  return (
    <>
      <SuccessBanner
        confetti={true}
        title=" Project Created Successfully!"
        backgroundImage={projectSuccessImg}
      />
      <Typography
        sx={{
          fontWeight: 500,
          fontSize: 14,
          color: "#656E7F",
          marginBottom: 2,
          textAlign: "left",
        }}
      >
        I’m excited to welcome you, now you are officially entered to ZEN our
        next version powered by VODEX.
        <br />
        <span
          style={{
            marginTop: 5,
            display: "block",
            color: "#1A1D23",
            fontSize: 16,
            fontWeight: 600,
          }}
        >
          Finding you the best customer’s
        </span>
      </Typography>

      <Button
        variant="outlined"
        sx={{
          borderColor: "#EEF2FB",
          backgroundColor: "#F4F7FD",
          color: "#1A1D23",
          fontSize: 13,
          padding: 0.5,
          borderRadius: 1.5,
          textTransform: "none",
          width: "100%",
        }}
      >
        <img src={bellIcon} alt="bell" style={{ marginRight: 8 }} />
        Will notify you once done. check your registered email inbox after
        sometimes
      </Button>
    </>
  );
};

export default FinalizeAIDetailsStep;
