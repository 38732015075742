import { toast } from "react-toastify";
import {
  emailRedirectService,
  getHubspotPipelineService,
  hubspotRedirectService,
} from "../../Services/Hubspot.service";
import { hubspotType } from "../Types/All.types";

export const hubspotRedirectAction = (code, projectId) => async () => {
  let res = await hubspotRedirectService(code, projectId);
  if (res.status === 200) {
    toast.success(res?.data?.msg);
    return res;
  } else {
    toast.error(res?.errMsg);
  }
};

export const emailRedirectAction = (code, projectId) => async () => {
  let res = await emailRedirectService(code, projectId);
  if (res.status === 200) {
    toast.success(res?.data?.msg);
    return res;
  } else {
    toast.error(res?.errMsg);
  }
};
export const getHubspotPipeLineAction = () => async (dispatch) => {
  let res = await getHubspotPipelineService();
  if (res.status === 200) {
    await dispatch({
      type: hubspotType.GET_PIPELINE_LIST,
      payload: res?.data?.results,
    });
  } else {
    alert(res.errMsg);
  }
};
