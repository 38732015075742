import React, { createContext, useContext, useState, useEffect } from "react";
import {
  getProjectByIdAction,
  saveProjectsAction,
  updateProjectsAction,
} from "../../Redux/Actions/Project.action";
import { useDispatch, useSelector } from "react-redux";
import { redirect, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { fileUploadAction } from "../../Redux/Actions/Common.actions";
import useQueryParams from "../Hooks/useQueryParams";
import { convertTime, reverseConvertTime } from "../CommonComponents/Common";

const initialState = {
  projectId: localStorage.getItem("projectId") || "",
  projectName: "",
  projectDescription: "",
  language: "",
  greetingMessageForCalls: "",
  voicemailMessage: "",
  context: "",
  agentName: "",
  organization: "",
  botRules: "",
  introMessageForMeetings: "",
  mainUrl: "",
  pdfs: [],
  textFiles: [],
  languageCode: "en",
  portalId: "",
  from: "",
  to: "",
  days: "",
  timezone: "",
  homepageUrl: "",
  summary: "",
};

export const StateContext = createContext(null);

const constructPipelineData = async (
  pipelineData,
  stageData,
  triggerPointData
) => {
  const pipeline = {
    id: pipelineData.id,
    name: pipelineData.label,
    stages: [],
  };

  pipelineData.stages.forEach((stage) => {
    const stageId = stage.id;
    const stageName = stage.label;
    let triggerPoint = null;

    if (triggerPointData.first === stageId) {
      triggerPoint = 1;
    } else if (triggerPointData.second === stageId) {
      triggerPoint = 2;
    }

    const pipelineStageData = stageData.find((data) => data.id === stageId);

    if (pipelineStageData) {
      pipeline.stages.push({
        id: pipelineStageData.id,
        name: pipelineStageData.label,
        whenToMove: pipelineStageData.whenToMove,
        triggerPoint: triggerPoint,
        stageName: stageName,
      });
    }
  });

  return pipeline;
};

const hoursToMinutes = (hours) => {
  return hours * 60;
};
const daysToArray = (days) => {
  if (days === 7) {
    return Array(7).fill(1);
  } else if (days === 5) {
    return [1, 1, 1, 1, 1, 0, 0];
  } else {
    return [];
  }
};

const processData = (inputData) => {
  const { from, to, days } = inputData;
  const fromMinutes = hoursToMinutes(parseInt(from));
  const toMinutes = hoursToMinutes(parseInt(to));
  const daysArray = daysToArray(parseInt(days));
  return {
    fromMinutes,
    toMinutes,
    daysArray,
  };
};

export default function OnboardingContext({ children }) {
  const [onBoardingState, setOnBoardingState] = useState({ ...initialState });
  const [isEdit, setIsEdit] = useState(false);
  const [files, setFiles] = React.useState([]);
  const [websiteUrl, setWebsiteUrls] = useState([]);
  const [pipelineName, setPipelineName] = useState("");

  const [showCompanyDetails, setShowCompanyDetails] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { update, redirecting } = useQueryParams();
  const reducer = useSelector((state) => state);
  const {
    projectReducer,
    AccountReducer: { systemConstantAccount },
  } = reducer;

  const arrayA = [1, 1, 1, 1, 1, 1, 1];
  const arrayB = [1, 1, 1, 1, 1, 0, 0];

  const arraysEqual = (arr1, arr2) => {
    if (arr1?.length !== arr2?.length) return false;
    return arr1.every((value, index) => value === arr2[index]);
  };

  const checkWhichArrayMatches = (backendArray) => {
    if (arraysEqual(backendArray, arrayA)) {
      return "7";
    } else if (arraysEqual(backendArray, arrayB)) {
      return "5";
    }
  };

  React.useEffect(() => {
    const companyDetailsData = systemConstantAccount?.find(
      (o) => o.type === "COMPANY_DETAILS"
    );

    if (
      companyDetailsData?.companyDetails &&
      companyDetailsData?.companyDetails?.length > 0
    ) {
      setShowCompanyDetails(false);
    }
  }, [systemConstantAccount]);

  useEffect(() => {
    if (update === "true") {
      const editedData = JSON.parse(localStorage.getItem("projectEditData"));
      console.log("editedData", editedData?._id);

      let obj = editedData?.companyDetails;
      const fromHours = Number(obj?.original_timing?.startTime) / 60;
      const toHours = Number(obj?.original_timing?.endTime) / 60;

      setOnBoardingState((prev) => ({
        ...prev,
        ...editedData,
        projectId: editedData?._id,
        from: fromHours < 10 ? `0${fromHours}` : `${fromHours}`,
        to: toHours < 10 ? `0${toHours}` : `${toHours}`,
        days: checkWhichArrayMatches(obj?.days?.value),
        timezone: reverseConvertTime(obj?.timezone),
        summary: obj?.summary,
      }));
      localStorage.setItem("redirect-project-id", editedData?._id);
      setIsEdit(true);
    }
  }, [update]);

  useEffect(() => {
    console.log("redirecting", redirecting);
    const init = async () => {
      if (redirecting === "true") {
        let id = localStorage.getItem("redirect-project-id");
        let res = await dispatch(getProjectByIdAction(id));
        setOnBoardingState((prev) => ({
          ...prev,
          ...res,
          projectId: res?._id,
        }));
        // setIsEdit(true)
      }
    };
    init();
  }, [redirecting]);

  const {
    greetingMessageForCalls,
    voicemailMessage,
    context,
    projectName,
    projectDescription,
    projectId,
    language,
    agentName,
    organization,
    botRules,
    introMessageForMeetings,
    mainUrl,
    languageCode,
    from,
    to,
    timezone,
    homepageUrl,
    summary,
    days,
  } = onBoardingState;

  const getWebsiteUrls = () => {
    let obj = [];
    websiteUrl.forEach((url) => {
      obj.push(`${mainUrl}${url}`);
    });
    return obj;
  };
  const saveProject = async (nextUrl) => {
    const toastId = toast.loading("Saving...");
    const inputData = {
      from: from,
      to: to,
      days: days,
    };
    const result = processData(inputData);
    let obj = {
      greetingMessageForCalls,
      voicemailMessage,
      context,
      projectName,
      projectDescription,
      language,
      agentName,
      organization,
      botRules,
      introMessageForMeetings,
      websites: getWebsiteUrls(),
      languageCode: languageCode,
      companyDetails: {
        timing: {
          startTime: result.fromMinutes,
          endTime: result.toMinutes,
        },
        days: { value: result.daysArray },
        timezone: convertTime(timezone),
        homepageUrl,
        summary,
      },
    };

    let res = await dispatch(saveProjectsAction(obj, toastId));
    setOnBoardingState((prev) => ({
      ...prev,
      projectId: res._id,
    }));
    localStorage.setItem("redirect-project-id", res?._id);
    localStorage.setItem("projectId", res._id);
    navigate("/get-started/sdr-setup");
  };

  const uploadFiles = async (files) => {
    if (files.length > 0) {
      const pdfFiles = [];
      const otherFiles = [];

      const formdata = new FormData();
      for (const file of files) {
        console.log("file", file);
        formdata.append("file", file.file);
        formdata.append("fileName", "FileId");

        try {
          const response = await dispatch(fileUploadAction(formdata));
          const fileUrl = response.URL;

          if (fileUrl.includes(".pdf")) {
            pdfFiles.push({ name: file.name, url: fileUrl });
          } else {
            otherFiles.push({ name: file.name, url: fileUrl });
          }
        } catch (error) {
          console.error(`Error uploading file ${file.name}:`, error);
        }
        formdata.delete("file");
        formdata.delete("fileName");
      }

      setOnBoardingState((prev) => ({
        ...prev,
        pdfs: pdfFiles,
        textFiles: otherFiles,
      }));
      return { pdfFiles, otherFiles };
    } else return { pdfFiles: [], otherFiles: [] };
  };

  const updateProject = async (nextUrl) => {
    const inputData = {
      from: from,
      to: to,
      days: days,
    };
    const result = processData(inputData);
    const toastId = toast.loading("Saving...");
    const { pdfFiles, otherFiles } = await uploadFiles(files);
    let obj = {
      greetingMessageForCalls,
      voicemailMessage,
      context,
      projectName,
      projectDescription,
      language,
      agentName,
      organization,
      botRules,
      introMessageForMeetings,
      websites: getWebsiteUrls(),
      pdfs: pdfFiles || [],
      textFiles: otherFiles || [],
      languageCode: languageCode,
      companyDetails: {
        timing: {
          startTime: result.fromMinutes,
          endTime: result.toMinutes,
        },
        days: { value: result.daysArray },
        timezone: convertTime(timezone),
        homepageUrl,
        summary,
      },
    };

    await dispatch(updateProjectsAction(obj, projectId, toastId));
    navigate(nextUrl);
  };

  const savePipelineData = async (
    selectedPipeline,
    selectedStage,
    selectedTriggers
  ) => {
    const pipeline = await constructPipelineData(
      selectedPipeline,
      selectedStage,
      selectedTriggers
    );
    const toastId = toast.loading("Saving...");
    let obj = {
      pipeline: pipeline,
    };
    if (pipelineName && selectedPipeline?.default) {
      obj.pipeline.name = pipelineName;
    }
    let res = await dispatch(
      updateProjectsAction(
        obj,
        projectId,
        toastId,
        selectedPipeline?.default || false
      )
    );
    if (res) {
      if (showCompanyDetails) {
        navigate("/get-started/company-details");
      } else {
        navigate("/main/projects");
      }
    }
  };

  let contextValue = {
    onBoardingState,
    setOnBoardingState,
    initialState,
    files,
    setFiles,
    websiteUrl,
    setWebsiteUrls,
    saveProject,
    updateProject,
    savePipelineData,
    isEdit,
    showCompanyDetails,
    pipelineName,
    setPipelineName,
  };

  return (
    <StateContext.Provider
      value={{
        ...contextValue,
      }}
    >
      {children}
    </StateContext.Provider>
  );
}

export function useOnboardingState() {
  const context = useContext(StateContext);
  if (!context) {
    throw new Error(
      "useAppState must be used within the useOnboardingStateProvider"
    );
  }
  return context;
}
