import { useLocation } from "react-router-dom";

const useQueryParams = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const params = {};

  for (let [key, value] of queryParams.entries()) {
    params[key] = value;
  }

  return params;
};

export default useQueryParams;
