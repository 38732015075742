import React, { useState } from "react";
import { Box, Typography, IconButton, Paper, TextField } from "@mui/material";
import HeaderContent from "../../Shared/HeaderContent";
import editIcon from "../../../Assests/Images/Icons/Edit.svg";

const QualifyingQuestions = () => {
  const [questions, setQuestions] = useState([
    "Struggling with Lead Qualification? We Have a Solution",
    "Looking for Better Engagement? Let Us Help",
    "Need More Conversions? We've Got You Covered",
  ]);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [isEditing, setIsEditing] = useState(null);

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  const handleQuestionChange = (index, event) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index] = event.target.value;
    setQuestions(updatedQuestions);
  };

  const handleEditClick = (index) => {
    setIsEditing(index);
  };

  return (
    <Box p={1}>
      <HeaderContent
        title={"Tailored Qualifying Questions"}
        subtitle={"Here’s a set of questions to qualify the lead"}
      />
      <Box py={1}>
        {questions.map((question, index) => (
          <Paper
            key={index}
            elevation={1}
            onMouseEnter={() => handleMouseEnter(index)}
            onMouseLeave={handleMouseLeave}
            sx={{
              display: "flex",
              alignItems: "center",
              padding: "8px 12px",
              marginY: 1,
              borderRadius: 2,
              position: "relative",
              boxShadow: "none",
              border: "1px solid #CCD2DE",
            }}
          >
            <Typography
              sx={{ color:"#1A1D23", fontSize:14, fontWeight: 600, marginRight: 1 }}
            >
              Q{index + 1}:
            </Typography>
            <TextField
              variant="standard"
              value={question}
              onChange={(event) => handleQuestionChange(index, event)}
              sx={{ flex: 1 }}
              InputProps={{
                disableUnderline: true,
                style: {
                  fontSize: 14,
                },
              }}
              disabled={isEditing !== index}
            />
            {hoveredIndex === index && isEditing !== index && (
              <IconButton
                sx={{ position: "absolute", right: 8 }}
                onClick={() => handleEditClick(index)}
              >
                <img src={editIcon} alt="edit" />
              </IconButton>
            )}
          </Paper>
        ))}
      </Box>
    </Box>
  );
};

export default QualifyingQuestions;
