import { hubspotType } from "../Types/All.types";

const initialState = {
  hubspotPipelineList: [],
};

function HubspotReducer(state = initialState, action) {
  switch (action.type) {
    case hubspotType.GET_PIPELINE_LIST: {
      return { ...state, hubspotPipelineList: action.payload };
    }
    default:
      return state;
  }
}

export default HubspotReducer;
