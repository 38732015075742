export const dealsType = {
  GET_DEALS_LIST: "GET_DEALS_LIST",
  GET_SELECTED_DEAL: "GET_SELECTED_DEAL",
};
export const projectsType = {
  GET_PROJECTS_LIST: "GET_PROJECTS_LIST",
  GET_SELECTED_PROJECT: "GET_SELECTED_PROJECT",
  CLEAR_PROJECT_REDUCER: "CLEAR_PROJECT_REDUCER",
  PROJECT_ACCESS: "PROJECT_ACCESS",
};

export const hubspotType = {
  GET_PIPELINE_LIST: "GET_PIPELINE_LIST",
};


export const accountType = {
  SET_SYSTEM_CONSTANT: "SET_SYSTEM_CONSTANT",
  SET_SYSTEM_CONSTANT_ACCOUNT: "SET_SYSTEM_CONSTANT_ACCOUNT",
  SET_SELECTED_ACCOUNT:"SET_SELECTED_ACCOUNT"
};


export const UPDATE_CURRENT_PATH = "UPDATE_CURRENT_PATH";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const LOGOUT = "LOGOUT"