import React, { useState } from "react";
import "../../Assests/Css/Onboarding.css";
import Steppers from "../CommonComponents/Steppers";
import TopRightLogo from "../CommonComponents/TopRightLogo";
import { Box, Grid, styled, Typography } from "@mui/material";
import { BootStrapInput, Input } from "../CommonComponents/Input";
import { useDispatch } from "react-redux";
import { saveProjectsAction } from "../../Redux/Actions/Project.action";
import { useNavigate } from "react-router-dom";
import CenterContainer from "../CommonComponents/CenterContainer";
import { useOnboardingState } from "./OnboardingContext";
import WelcomeCard from "../CommonComponents/ProgressContainer";
import InfoIcon from "@mui/icons-material/Info";
const FormHeader = styled(Typography)({
  marginBottom: "20px",
  fontWeight: "bold",
});
function ProjectPage() {
  const {
    onBoardingState,
    setOnBoardingState,
    saveProject,
    isEdit,
    updateProject,
  } = useOnboardingState();
  const { projectName, projectDescription, languageCode } = onBoardingState;

  const onChange = (e) => {
    e.persist();
    setOnBoardingState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <div
      style={{
        height: "100%",
      }}
      className="white-bg"
    >
      <TopRightLogo black={true} />
      <br />
      <Grid container className="mt-17 ml-5">
        <Grid item xs={12} md={4}>
          {" "}
          <WelcomeCard value={0} />
        </Grid>
        <Grid item xs={12} md={8}>
          <Box>
            <Typography
              className="font-size-15 default-border"
              color="black"
              sx={{
                padding: "15px",
                width: "65%",
                marginBottom: "10px",
              }}
            >
              <InfoIcon className="mr-2" />
              It’s just 5 steps to go! Your AI assistant is waiting for you
              there.
            </Typography>
            <FormHeader className="color-black font-size-22">
              Project Creation
              <Typography className="font-size-12 color-black">
                Let’s collaborate to build your project and create something
                extraordinary.
              </Typography>
            </FormHeader>{" "}
            <BootStrapInput
              label={"Project Name"}
              name={"projectName"}
              value={projectName}
              onChange={onChange}
              className={"w-80 mb-4 ml-1"}
            />
            <BootStrapInput
              label={"Project Description"}
              name={"projectDescription"}
              value={projectDescription}
              onChange={onChange}
              className={"w-80 mb-4 ml-1"}
            />
            {/* <BootStrapInput
              name={"languageCode"}
              label={"Language"}
              onChange={onChange}
              className={"w-80 mb-4 ml-1"}
              value={languageCode}
            /> */}
          </Box>
        </Grid>
      </Grid>
      <div className=" d-flex justify-content-center mb-5 ">
        <button
          className="gradient-button ml-1  w-25 "
          onClick={
            isEdit ? () => updateProject("/get-started/sdr-setup") : saveProject
          }
        >
          Save & Next
        </button>
      </div>
    </div>
  );
}

export default ProjectPage;
