import React, { lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import TrainingLog from "../../Components/ProjectSection/TrainingData/TrainingLog";
const DealsBoard = lazy(() =>
  import("../../Components/ProjectSection/Deals/Deals")
);
const Projects = lazy(() =>
  import("../../Components/ProjectSection/Projects/Projects")
);
const ErrorComponent = lazy(() =>
  import("../../Components/CommonComponents/CommonUiComponent/ErrorComponent")
);

const NoRoute = () => <Navigate to="/main/dashboard" />;
const ProjectDashboard = () => {
  return (
    <Routes>
      <Route path="/" element={<Projects />} />
      <Route
        path={`/:projectId/deals`}
        element={<DealsBoard />}
        errorElement={<ErrorComponent />}
      />
      <Route
        path={`/:projectId/training-logs`}
        element={<TrainingLog />}
        errorElement={<ErrorComponent />}
      />
      <Route path="*" element={<NoRoute />} />
    </Routes>
  );
};

export default ProjectDashboard;
