import { API } from "../Constants/ApiRoutes";
import {  BASEURL_AI, axiosClient } from "../Constants/Axios";



export const getStageDummyService = async (stages) => {
  try {
    let res = await axiosClient.post(
      `${BASEURL_AI}${API.AI.GET_STAGE_DUMMY_DATA}`,stages
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};


export const getTrainingLogsService = async (sessionId,type) => {
  try {
    let res = await axiosClient.get(
      `${BASEURL_AI}${API.AI.GET_TRAINING_SESSION_DATA}/${sessionId}?type=${type}`
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};


export const getSitemapsUrlService = async (data) => {
  try {
    let res = await axiosClient.post(
      `${BASEURL_AI}${API.AI.GET_SITEMAP_URL_DATA}`,{...data}
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getCompanySummaryService = async (data) => {
  try {
    let res = await axiosClient.post(
      `${BASEURL_AI}${API.AI.COMPANY_SUMMARY}`,{...data}
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};