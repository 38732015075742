import moment from "moment";

export const renderThumb = ({ style, ...props }) => {
  const thumbStyle = {
    backgroundColor: "#6c757d",
    borderRadius: "10px",
  };
  return <div style={{ ...style, ...thumbStyle }} {...props} />;
};


export const getDateWithTime=(date)=>  moment(date).format("MMMM Do YYYY, h:mm:ss ")


export const getStageName = (id,selectedProject) => {
  let stages = selectedProject?.pipeline?.stages;
  let stageName = stages?.find((stage) => stage?.id === id);
  return stageName?.name;
};

export function debounce(func, delay) {
  let timeoutId;
  return (...args) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
}

export const timeArray = [
  { label: "00:00", value: "00" },
  { label: "01:00", value: "01" },
  { label: "02:00", value: "02" },
  { label: "03:00", value: "03" },
  { label: "04:00", value: "04" },
  { label: "05:00", value: "05" },
  { label: "06:00", value: "06" },
  { label: "07:00", value: "07" },
  { label: "08:00", value: "08" },
  { label: "09:00", value: "09" },
  { label: "10:00", value: "10" },
  { label: "11:00", value: "11" },
  { label: "12:00", value: "12" },
  { label: "13:00", value: "13" },
  { label: "14:00", value: "14" },
  { label: "15:00", value: "15" },
  { label: "16:00", value: "16" },
  { label: "17:00", value: "17" },
  { label: "18:00", value: "18" },
  { label: "19:00", value: "19" },
  { label: "20:00", value: "20" },
  { label: "21:00", value: "21" },
  { label: "22:00", value: "22" },
  { label: "23:00", value: "23" }
];



export function convertTime(input) {
  const hours = Math.floor(parseFloat(input));
  const minutes = Math.round((parseFloat(input) - hours) * 60);
  const formattedHours = hours >= 0 ? `+${hours}` : `-${Math.abs(hours)}`;
  const formattedMinutes = minutes.toString().padStart(2, '0');

  return `${formattedHours}:${formattedMinutes}`;
}

export  function reverseConvertTime(timeString) {
  if(timeString){
    const [sign, time] = timeString?.startsWith('+') 
    ? ['+', timeString.slice(1)] 
    : ['-', timeString.slice(1)];
  const [hours, minutes] = time.split(':').map(Number);
  const decimalTime = hours + minutes / 60;
  return sign === '-' ? -decimalTime : decimalTime;
  }
  
}

export const RequiredStar =()=><span style={{ color: "red" }}>*</span>



