
import React,{ Suspense, lazy, useEffect } from "react";
import "./App.css";
import "./Assests/Css/Common.css";
import { Scrollbars } from "react-custom-scrollbars";
import { renderThumb } from "./Components/CommonComponents/Common";
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Navigate
} from "react-router-dom";
import { SuspenseLoader } from "./Components/CommonComponents/CommonUiComponent";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  getAccountSystemConstantAction,
  getGlobalSystemConstantAction,
} from "./Redux/Actions/Common.actions";
import DashboardLayout from "./NewDevelopment/Layout/DashboardLayout";
import Login from "./Components/Pages/Login";
import NewLogin from "./Components/Pages/NewLogin";
import Register from "./Components/Pages/Register";
import OnboardingContext from "./Components/Onboarding/OnboardingContext";
import OnboardingRoutes from "./Components/Onboarding/OnboardingRoutes";
import { useDispatch, useSelector } from "react-redux";
const ErrorComponent = lazy(() => import("./Components/CommonComponents/CommonUiComponent/ErrorComponent"));

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
        <Route path="/" element={<Navigate to="/login" replace />} />
        <Route path="/login" element={<Login />} />
        <Route path="/new-login" element={<NewLogin />} errorElement={<ErrorComponent />}/>
        <Route path="/register" element={<Register />} errorElement={<ErrorComponent />}/>
        <Route path="/main/*" element={<DashboardLayout /> } errorElement={<ErrorComponent />}/>
        <Route
        path="/get-started/*"
        element={
          <OnboardingContext>
            <OnboardingRoutes />
          </OnboardingContext>
        }
      />
    </Route>
  )
);

function App() {
  const dispatch = useDispatch();
  const selectedAccount = useSelector((state) => state.AccountReducer.selectedProject);

  useEffect(() => {
    const init = async () => {
      dispatch(getGlobalSystemConstantAction());
      dispatch(getAccountSystemConstantAction());
    };

    init();
  }, [selectedAccount]);

  return (
      <Scrollbars
        style={{
          height: "100vh",
          color: "white",
        }}
        renderThumbVertical={renderThumb}
        autoHide={true}
      >
        <ToastContainer />

        <Suspense fallback={<SuspenseLoader />}>
          <RouterProvider router={router} />
        </Suspense>
      </Scrollbars>
  );
}

export default App;

