import { Drawer, styled } from "@mui/material";

import SideBarContent from "./SideBarContent";

const StyledDrawer = styled(Drawer)`
  z-index: 1000;
`;

const SideBar = ({ toggleDrawer, openDrawer }) => {
  return openDrawer ? (
    <StyledDrawer
      anchor="left"
      open={true}
      onClose={toggleDrawer}
      hideBackdrop={true}
      ModalProps={{
        keepMounted: true,
      }}
      variant="persistent"
      sx={{
        "& .MuiDrawer-paper": {
          width: 230,
          borderRight: "none",
          background: "#F8FAFC",
          // marginTop: "64px",
        },
      }}
    >
      <SideBarContent />
    </StyledDrawer>
  ) : (
    <StyledDrawer
      anchor="left"
      open={true}
      onClose={toggleDrawer}
      hideBackdrop={true}
      ModalProps={{
        keepMounted: true,
      }}
      variant="persistent"
      sx={{
        "& .MuiDrawer-paper": {
          width: 70,
          borderRight: "none",
          background: "#F8FAFC",
          // marginTop: "64px",
          overflow: "hidden",
        },
      }}
    >
      <SideBarContent />
    </StyledDrawer>
  );
};

export default SideBar;
