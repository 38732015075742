import React from "react";
import { Box, Typography, TextField, IconButton } from "@mui/material";
import BadEmoji from "../../Assests/Images/Icons/emojis/bad.png";
import DecentEmoji from "../../Assests/Images/Icons/emojis/decent.png";
import LoveEmoji from "../../Assests/Images/Icons/emojis/love.png";

const iconButtonStyles = (isSelected) => ({
  color: "#5F6A8A",
  borderRadius: 2,
  padding: "8px 16px",
  border: `1px solid ${isSelected ? "#4F46E5" : "#D1D9EF"}`,
  backgroundColor: isSelected ? "#EAE9FC" : "#F5F7FB",
  "&:hover": {
    borderColor: "#4F46E5",
    backgroundColor: "#EAE9FC",
  },
});

const ratingOptions = [
  { label: "Bad", emoji: BadEmoji, value: "bad" }, 
  { label: "Decent", emoji: DecentEmoji, value: "decent" }, 
  { label: "Love it!", emoji: LoveEmoji, value: "love" },
];

const Feedback = ({
  selectedRating,
  setSelectedRating,
  feedback,
  setFeedback,
}) => {
  const handleRatingClick = (value) => {
    setSelectedRating(value);
  };
  return (
    <Box px={2} py={1}>
      <Typography color="#1A1D23" fontSize={18} fontWeight={700} mb={2}>
        Rate your experience
      </Typography>

      <Box display="flex" justifyContent="left" gap={2} mb={3}>
        {ratingOptions.map(({ label, emoji, value }) => (
          <IconButton
            key={value}
            onClick={() => handleRatingClick(value)}
            sx={iconButtonStyles(selectedRating === value)}
          >
            <img src={emoji} alt={label} width={30} height={30} style={{ marginRight: 8 }} />
            <Typography fontSize={13} fontWeight={600} ml={0.5}>
              {label}
            </Typography>
          </IconButton>
        ))}
      </Box>

      <Typography color="#1A1D23" fontSize={18} fontWeight={700} mb={1}>
        How are you feeling?
      </Typography>

      <TextField
        fullWidth
        multiline
        rows={7}
        variant="outlined"
        placeholder="Please tell us about your experience of our product. Your input is valuable in helping us better understand your needs and tailor our service accordingly."
        value={feedback}
        onChange={(e) => setFeedback(e.target.value)}
        sx={{
          "& .MuiOutlinedInput-root": {
            borderRadius: 3,
            "& fieldset": { borderColor: "#D7DCE9" },
            "&:hover fieldset": { borderColor: "#D7DCE9" },
            "& textarea::placeholder": {
              fontSize: 13,
              color: "#8792A7",
            },
          },
        }}
      />
    </Box>
  );
};

export default Feedback;
