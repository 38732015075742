import React from "react";
import Scrollbars from "react-custom-scrollbars";
import { renderThumb } from "./Common";

function CustomScrollbar(props) {
  return (
    <Scrollbars
      style={{
        height: props.height,
        color: props.color || "white",
        minWidth: props.width || "",
      }}
      renderThumbVertical={renderThumb}
      autoHide={true}
      className={props?.className}
    >
      {props.children}
    </Scrollbars>
  );
}

export default CustomScrollbar;
