import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import welcomeImage from "../../Assests/Images/welcome.png";
import OnBoardSummary from "./FirstStage/OnBoardSummary";
import styles from "./FirstStage/dashboardStyles";
import SecondStage from "./SecondStage/SecondStage";
import SecondModalContent from "./SecondStage/ModalContent";
import ThirdModalContent from "./ThirdStage/ModalContent";

const steps = [
  {
    id: 1,
    title: "Initiate Prospecting",
    description: [
      "Find Your ICP",
      "Generate Leads",
      "Initiate Prospecting & Launch Emails",
    ],
  },
  {
    id: 2,
    title: "Activate call simulation",
    description: [
      "Qualifying questions",
      "Call simulation",
      "Finalizing ICP to Lead",
    ],
  },
];

const Welcome = () => {
  const [openSecondStageModal, setOpenSecondStageModal] = useState(false);
  const [openThirdStageModal, setOpenThirdStageModal] = useState(false);
  const [secondStageCompleted, setSecondStageCompleted] = useState(false);

  const initiateNow = () => {
    setOpenSecondStageModal(true);
  };
  const activateNow = () => {
    setOpenThirdStageModal(true);
  };

  const completeSecondStep = () =>{
    setOpenSecondStageModal(false);
    setSecondStageCompleted(true);
  }

  return (
    <>
      <Box sx={styles.container}>
        <img
          src={welcomeImage || ""}
          alt="Welcome"
          style={styles.dashboardImage}
        />
        <Typography
          color="white"
          zIndex={1}
          fontSize={18}
          fontWeight={700}
          mt="4%"
        >
          Hello Buddy!
        </Typography>
        <OnBoardSummary />
        <SecondStage steps={steps} initiateNow={initiateNow} activateNow={activateNow} secondStageCompleted={secondStageCompleted} />
      </Box>
      <SecondModalContent openSecondStageModal={openSecondStageModal} setOpenSecondStageModal={setOpenSecondStageModal} completeSecondStep={completeSecondStep} />
      <ThirdModalContent openThirdStageModal={openThirdStageModal} setOpenThirdStageModal={setOpenThirdStageModal} />
    </>
  );
};

export default Welcome;
