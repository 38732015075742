export const API = {
  PROJECT: {
    PROJECT: "/project",
    GET_PROJECTS: "/projects-all",
  },
  DEALS: {
    GET_DEALS: "/deals",
    GET_DEAL: "/deal",
    TRIGGER_CALL:'/trigger-call'
  },
  COMMON: {
    HUBSPOT_REDIRECT: "/hubspot/callback",
    GMAIL_REDIRECT: "/gmail/callback",
    GET_HUBSPOT_PIPELINE: "/hubspot/pipeline",
    LOGIN: "/login",
    ACCOUNT_SYSTEM_CONSTANT: "/system-constant-account",
    FILE_UPLOAD: "/file-upload",
    START_TRAINING: "/training",
    CALENDER_EVENTS: "/event",
    GLOBAL_SYSTEM_CONSTANT:'/system-constant',
    GET_XPATH_ID:'/xpath',
    RESCHEDULE_EVENT:'/event/reschedule',
    NOTIFICATION_TOKEN:'/notification/token',
    DEALS_HOURLY:'/deals/count/hourly',
    DEALS_WEEKLY:'/deals/count/weekly',
    FLOW_CACHED_DATA:'/flow-cache',
    ACCOUNT:'/account'
  },
  AI:{
    GET_STAGE_DUMMY_DATA:"/prompt/generate_stage",
    GET_TRAINING_SESSION_DATA:"/prompt/training/logs",
    GET_SITEMAP_URL_DATA:"/prompt/list_websites",
    COMPANY_SUMMARY:"/summary/company"
  }
};
