import React from "react";
import confettiSvg from "../../Assests/Images/Backgrounds/CelebrationBackground.svg";
import { Box, Typography } from "@mui/material";

const SuccessBanner = ({ confetti = false, title, backgroundImage }) => {
  return (
    <Box position="relative">
      {confetti && (
        <Box
          component="img"
          src={confettiSvg}
          alt="Confetti"
          sx={{
            position: "absolute",
            top: 0,
            left: "50%",
            transform: "translateX(-50%)",
            width: "100%",
            height: "auto",
            zIndex: 1,
            pointerEvents: "none",
          }}
        />
      )}

      <Box
        my={1}
        backgroundColor="#F7F9FC"
        height={200}
        display="flex"
        flexDirection="column"
        justifyItems="center"
        justifyContent="center"
        gap={2}
        borderRadius={2}
      >
        <Typography fontSize={14} fontWeight={600} textAlign="center">
          {title}
        </Typography>
        <img
          src={backgroundImage}
          alt="successIcon"
          style={{ width: "100%" }}
        />
      </Box>
    </Box>
  );
};

export default SuccessBanner;
