import React from "react";
import { Button } from "@mui/material";
import hubspotIcon from "../../../Assests/Images/hubspot.svg";
import gmailIcon from "../../../Assests/Images/gmail.svg";
import pipedriveIcon from "../../../Assests/Images/pipedrive.svg";

const styles = {
  connectButton: {
    "--button-color": "#6C7594",
    backgroundColor: "white",
    marginBottom: 1.5,
    borderColor: "#D1D9EF",
    borderRadius: 2,
    color: "var(--button-color)",
    fontSize: 14,
    fontWeight: 600,
    padding: "6px 18px",
    textTransform: "none",
    outline: "none",
    "&:hover": {
      "--button-color": "#4F46E5",
      backgroundColor: "#F8F8FE",
      borderColor: "#4F46E5",
    },
    "& img": {
      filter: "brightness(0) saturate(100%) invert(39%) sepia(11%) saturate(748%) hue-rotate(178deg) brightness(88%) contrast(85%)",
      transition: "filter 0.3s ease",
    },
    "&:hover img": {
      filter: "brightness(0) saturate(100%) invert(29%) sepia(97%) saturate(1745%) hue-rotate(216deg) brightness(93%) contrast(100%)",
    },
  },
};

const ConnectButton = ({ label, icon, href, disabled = false }) => (
  <Button
    component="a"
    href={href}
    variant="outlined"
    fullWidth
    sx={styles.connectButton}
    startIcon={icon && <img src={icon} alt={label} />}
    disabled={disabled}
  >
    {label}
  </Button>
);

const ConnectProjectStep = ({ onboardingState }) => {
  const { email, portalId } = onboardingState;
  return (
    <>
      {portalId ? (
        <button className="gradient-button mb-3 w-100">
          <img src={hubspotIcon} height={25} /> Connected to Hubspot : Portal Id{" "}
          {portalId}
        </button>
      ) : (
        <ConnectButton
          label="Connect to Hubspot"
          icon={hubspotIcon}
          href="https://app.hubspot.com/oauth/authorize?client_id=1c046638-6595-4604-a5ac-f3997df36304&scope=crm.objects.carts.write%20crm.schemas.deals.read%20crm.objects.carts.read%20crm.schemas.deals.write%20crm.dealsplits.read_write%20oauth%20crm.objects.owners.read%20crm.objects.contacts.write%20crm.objects.custom.read%20crm.objects.custom.write%20crm.lists.write%20crm.lists.read%20crm.objects.deals.read%20crm.objects.deals.write%20crm.objects.contacts.read&redirect_uri=https://vodex-testing.surge.sh/get-started/redirect-to-vodex?authType=hubspot"
        />
      )}

      {email ? (
        <button className="gradient-button mb-3 w-100">
          <img src={gmailIcon} height={25} /> Connected to Gmail Account :{" "}
          {onboardingState?.email}
        </button>
      ) : (
        <ConnectButton
          label="Connect to Gmail"
          icon={gmailIcon}
          href="https://accounts.google.com/o/oauth2/v2/auth?access_type=offline&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fgmail.readonly%20https%3A%2F%2Fmail.google.com%2F%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile&prompt=consent&response_type=code&client_id=808909189797-sm3gandff008bpn4tc5rb74lqo05u3a3.apps.googleusercontent.com&redirect_uri=https%3A%2F%2Fvodex-testing.surge.sh%2Fget-started%2Fredirect-to-vodex%3FauthType%3Dgmail"
        />
      )}

      <ConnectButton label="Connect to Pipedrive" icon={pipedriveIcon} />
      <ConnectButton label="Empty button" />
    </>
  );
};

export default ConnectProjectStep;
