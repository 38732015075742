import { toast } from "react-toastify";
import {
  fileUploadService,
  getAccountDetailsService,
  getAccountSystemConstantService,
  getCalederSlotsService,
  getDealsHourlyService,
  getDealsWeeklyService,
  getFlowCacheDataService,
  getGlobalSystemConstantService,
  getXpathByProjectIdService,
  loginService,
  promptTrainingStartService,
  registerForNotificationService,
  rescheduleEventService,
  saveCompanyDetailsService,
} from "../../Services/Common.services";
import { accountType } from "../Types/All.types";

export const loginAction = (obj) => async () => {
  let res = await loginService(obj);
  if (res?.status === 200) {
    
    localStorage.setItem("login-data", JSON.stringify(res.data));
    return res.data;
  }
};

export const saveCompanyDeatilsAction = (obj, toastId) => async () => {
  let res = await saveCompanyDetailsService(obj);
  toast.update(toastId, {
    render: res.msg,
    type: "success",
    isLoading: false,
    autoClose: 3000,
  });
};

export const fileUploadAction = (data) => async () => {
  let res = await fileUploadService(data);
  return res;
};

export const promptTrainingStartAction = (projectId, toastId) => async () => {
  let res = await promptTrainingStartService(projectId);
  toast.update(toastId, {
    render: res.msg,
    type: "success",
    isLoading: false,
    autoClose: 3000,
  });
};

export const getCalederSlotsAction = (projectId, toastId) => async () => {
  let res = await getCalederSlotsService(projectId);
  return res;
  
};

export const getGlobalSystemConstantAction = () => async (dispatch) => {
  let res = await getGlobalSystemConstantService();
  await dispatch({
    type: accountType.SET_SYSTEM_CONSTANT,
    payload: res,
  });

  
};

export const getAccountSystemConstantAction= () => async (dispatch) => {
  let res = await getAccountSystemConstantService();
  await dispatch({
    type: accountType.SET_SYSTEM_CONSTANT_ACCOUNT,
    payload: res,
  });

};

export const getXPathIdAction= (projectId) => async () => {
  let res = await getXpathByProjectIdService(projectId);
  return res
};

export const rescheduleEventAction= (data) => async () => {
  let res = await rescheduleEventService(data);
  return res
};

export const setSelectedAccountAction =(data)=>async(dispatch)=>{
  await dispatch({
    type: accountType.SET_SELECTED_ACCOUNT,
    payload: data,
  })
  
}

export const registerForNotificationAction= (data) => async () => {
  let res = await registerForNotificationService(data);
};

export const getDealsHourlyAction= (str) => async () => {
  let res = await getDealsHourlyService(str);
  return res
};

export const getDealsWeeklyAction= (str) => async () => {
  let res = await getDealsWeeklyService(str);
  return res
};


export const getFlowCacheDataAction= () => async () => {
  let res = await getFlowCacheDataService();
  return res
};


export const getAccountDetailsAction = () => async (dispatch) => {
  let res = await getAccountDetailsService();
  
  await dispatch({
    type: accountType.SET_SELECTED_ACCOUNT,
    payload: res,
  });  
};