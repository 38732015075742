import React, { useState } from "react";
import "../../Assests/Css/Common-v.css";
import Line from "../../Assests/Images/Icons/Line 511.png";
import CheckMark from "../../Assests/Images/Icons/Checkmark.svg";
import Logo from "../../Assests/Images/Icons/Logo.png";
import RegisterLogo from "../../Assests/Images/Icons/RegisterLogo.png";
import ArrowRight from "../../Assests/Images/Icons/ArrowRight.png";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";

import {
  Box,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  styled,
} from "@mui/material";

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    padding: "8px 3px",
    border: state.isFocused ? "1px solid #4280ff" : "1px solid #ced4da",
    borderRadius: "8px",
    outline: "none",
    boxShadow: "none",
    "&:hover": {
      border: "1px solid #4280ff",
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: "13px",
    fontWeight: 500,
    color: "#8792a7",
  }),
};

// Stepper
const MyStepper = ({ activeStep, steps }) => {
  const CustomConnector = styled(StepConnector)(({ theme }) => ({
    [`& .${StepConnector.line}`]: {
      borderColor: "#000",
      borderTopWidth: 2,
    },
  }));

  return (
    <Box sx={{ width: "300px" }}>
      <Stepper activeStep={activeStep} connector={<CustomConnector />}>
        {steps.map((label, index) => {
          // step icon styling
          const isStepActive = activeStep === index;
          return (
            <Step key={label}>
              <StepLabel
                StepIconComponent={() => (
                  <Box
                    sx={{
                      backgroundColor: isStepActive ? "#3f51b5" : "#f4f6f8",
                      color: isStepActive ? "white" : "#8792a7",
                      border: isStepActive ? "none" : "1px solid #dfe3e8",
                    }}
                    className="stepper-box"
                  >
                    {index + 1}
                  </Box>
                )}
                sx={{
                  ".MuiStepLabel-label": {
                    display: isStepActive ? "block" : "none",
                    color: isStepActive ? "#000000" : "#8792a7",
                    fontWeight: 500,
                    fontSize: "14px",
                    fontFamily: "Dm sans",
                  },
                }}
              >
                {label}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
};

const Register = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [show, setShow] = useState(true);
  const steps = ["General Info", "Business Info"];
  const loading = true

  const navigate = useNavigate();

  const handleNext = () => {
    if (activeStep < steps.length - 1) {
      setActiveStep((prevStep) => prevStep + 1);
    }
  };
  return (
    <Box
      height="100vh"
      sx={{
        width: { xs: "95%", sm: "70%", lg: "100%" },
        display: { xs: "block", lg: "flex" },
        margin: { xs: "auto", lg: 0 },
      }}
      className="main-login-container"
    >
      <Box
        sx={{
          display: { xs: "none", lg: "block" },
          width: { lg: "60%" },
        }}
        className="img-container"
      >
        <div className="logo-container">
          <img src={Logo} alt="" />
          <h2>ZEN</h2>
        </div>
        <div className="img-overlay mt-100 ml-11">
          <h2>
            <span className="super-text">Supercharge</span> your Business with
            Sales, Trust{" "}
            <span style={{ color: "#6158F7", fontWeight: 700 }}>ZEN</span>, your
            most needed AI SDR.
          </h2>
          <span>
            <img src={Line} alt="" />
          </span>
          <div className="list-item-cont mt-1">
            <p className="list-items">
              <span className="mr-11">
                <img src={CheckMark} />
              </span>
              Unlimited Lead Generation
            </p>
            <p className="list-items">
              <span className="mr-11">
                <img src={CheckMark} />
              </span>
              Powerful AI Prospecting
            </p>
            <p className="list-items">
              <span className="mr-11">
                <img src={CheckMark} />
              </span>
              Easy Integration
            </p>
          </div>
        </div>
      </Box>

      {show === true && (
        <Box className="input-container mt-24">
          <Box
            sx={{
              height: "595px",
              width: { xs: "95%", md: "443px" },
            }}
          >
            <MyStepper activeStep={activeStep} steps={steps} />
            {activeStep === 0 && (
              <div>
                <div>
                  <h3 className="heading-main mt-10">General Information</h3>
                </div>
                <div className="form-container">
                  <div className="w-100 mt-5 mb-5 ">
                    <label className="input-label">Company Name</label>
                    <input
                      className="custom-input ip-placeholder"
                      type="text"
                      placeholder="Enter your company name"
                    />
                  </div>
                  <div className="w-100 mt-5 mb-5 ">
                    <label className="input-label">Email</label>
                    <input
                      className="custom-input ip-placeholder"
                      type="text"
                      placeholder="Enter your registered email"
                    />
                  </div>

                  <div className="w-100 mt-5">
                    <label className="input-label">Industry</label>
                    <Select
                      styles={customStyles}
                      className="basic-single"
                      classNamePrefix="select"
                      isSearchable={true}
                      placeholder="Select an industry type"
                    />
                  </div>

                  <button className="custom-button mt-10" onClick={handleNext}>
                    Next
                  </button>
                  <p className="small-text font-weight-500 mt-6">
                    I've already an account?{" "}
                    <span
                      onClick={() => navigate("/new-login")}
                      className="link-text-underline"
                    >
                      Login
                    </span>
                  </p>
                </div>
              </div>
            )}

            {activeStep === 1 && (
              <div>
                <div>
                  <h3 className="heading-main mt-10">Business Information</h3>
                </div>
                <div className="form-container">
                  <div className="w-100 mt-5">
                    <label className="input-label">Sales Employees</label>
                    <Select
                      styles={customStyles}
                      className="basic-single"
                      classNamePrefix="select"
                      isSearchable={true}
                      placeholder="Select number of sales employees in your organization"
                    />
                  </div>
                  <div className="w-100 mt-5 ">
                    <label className="input-label">Revenue</label>
                    <div className="currency-input-container w-100">
                      <span className="currency-box font-size-13 font-weight-500">
                        USD
                      </span>
                      <input
                        className="password-input ip-placeholder ml-2"
                        type="text"
                        placeholder="Enter your revenue in dollars"
                      />
                    </div>
                  </div>
                  <button
                    onClick={() => setShow(false)}
                    className="custom-button dis-flex-center mt-10"
                  >
                    {loading && (
                      <CircularProgress
                        size={24}
                        style={{ color: "white", marginRight: "10px" }}
                      />
                    )}{" "}
                    Request Access
                  </button>
                  <p className="small-text font-weight-500 mt-6">
                    I've already an account?{" "}
                    <span
                      onClick={() => navigate("/new-login")}
                      className="link-text-underline"
                    >
                      Login
                    </span>
                  </p>
                </div>
              </div>
            )}
          </Box>
        </Box>
      )}
      {show === false && (
        <div className="small-main-conatiner mt-65">
          <div className="small-register-container">
            <div>
              <img src={RegisterLogo} alt="" />
            </div>
            <div>
              <h2 className="heading-main">Access link sent to your mail</h2>
              <p className="heading-small">
                Will notify you the access link for the dashboard, through your
                registered email if you are eligible. kindly visit your mail
              </p>
              <p className="link-text font-weight-400 font-size-13">
                Go To Link{" "}
                <span className="ml-1">
                  <img src={ArrowRight} />
                </span>
              </p>
            </div>
          </div>
        </div>
      )}
    </Box>
  );
};

export default Register;
