import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Typography,
  TextField,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import CustomScrollbar from "../../../Components/CommonComponents/CustomScrollbar";
import Markdown from "react-markdown";
import star from "../../../Assests/Images/Icons/star.svg";

const emailSteps = [
  "Email short subject 1",
  "Email short subject 2",
  "Email short subject 3",
  "Email short subject 4",
  "Email short subject 5",
  "Email short subject 6",
  "Email short subject 7",
];

const EmailCadence = () => {
  const [selectedStep, setSelectedStep] = useState(0);
  const [subject, setSubject] = useState("");
  const [emailBody, setEmailBody] = useState("");
  useEffect(() => {
    handleStepClick(0);
  }, []);

  const handleStepClick = (index) => {
    setSelectedStep(index);
    setSubject(`Sample subject for step ${index + 1}`);
    setEmailBody(
      `Dear [Customer Name],\n\nThis is a sample email content for step ${
        index + 1
      }.\n\nBest regards,\nYour Company`
    );
  };

  return (
    <Box p={1.5}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box width="30%" >
          <Typography
            color="#1A1D23"
            fontSize={18}
            fontWeight={700}
            gutterBottom
          >
            Email cadence
          </Typography>
          <Typography variant="body2" color="textSecondary">
            You can do 7 email cadence
          </Typography>
        </Box>
        <Button
          variant="outlined"
          startIcon={<img src={star} alt="Pin Icon" style={{ width: 16, height: 16 }} />}
          sx={{
            color: "#5F6A8A",
            borderColor: "#D1D9EF",
            textTransform: "none",
            fontSize: 12,
            fontWeight: 500,
          }}
        >
          Regenerate Email
        </Button>
      </Box>
      <Box display="flex">
        <Box width="30%" mr={2} mt={3}>
          <CustomScrollbar color="inherit" height="100%">
            <List>
              {emailSteps.map((step, index) => (
                <ListItem
                  key={index}
                  onClick={() => handleStepClick(index)}
                  selected={index === selectedStep}
                  sx={{
                    borderRadius: 2,
                    cursor: "pointer",
                    height: 40,
                    mb: 1,
                    color: "#656E7F",
                    border: `1px solid ${index === selectedStep ? "#4F46E5" : "#D1D9EF"}`,
                    backgroundColor: index === selectedStep ? "#4F46E5" : "#transparent",
                  }}
                >
                  <Box
                    sx={{
                      display: "inline-flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: 23,
                      height: 20,
                      borderRadius: "50%",
                      border: "1px solid #4F46E5",
                    }}
                  >
                    <Typography
                      sx={{
                        textAlign: "center",
                        fontSize: 14,
                        fontWeight: 500,
                      }}
                    >
                      {index + 1}
                    </Typography>
                  </Box>
                  <ListItemText
                    primary={
                      <Typography
                        noWrap
                        sx={{
                          color: "#656E7F",
                          fontSize: 14,
                          fontWeight: 600,
                          ml: 1,
                        }}
                      >
                        {step}
                      </Typography>
                    }
                  />
                </ListItem>
              ))}
            </List>
          </CustomScrollbar>
        </Box>

        <Box width="70%">
          <Typography variant="body2" color="textSecondary">
            Subject
          </Typography>

          <Box
            display="flex"
            flexDirection="column"
            gap={2}
            justifyContent="space-between"
            alignItems="center"
            mt={1}
          >
            <TextField
              fullWidth
              variant="outlined"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              size="small"
              placeholder="Enter subject"
            />
            <Box
              width="100%"
              p={2}
              border="1px solid #D1D9EF"
              borderRadius={2}
              bgcolor="#FAFAFA"
              sx={{ minHeight: 200 }}
            >
              <Markdown>{emailBody}</Markdown>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default EmailCadence;
