import React from "react";
import { Typography } from "@mui/material";

const HeaderContent = ({ title, subtitle }) => {
  return (
    <>
      {title && (
        <Typography
          align="left"
          sx={{
            fontWeight: 700,
            fontSize: 18,
            color: "#1A1D23",
          }}
        >
          {title}
        </Typography>
      )}
      {subtitle && (
        <Typography
          align="left"
          sx={{
            fontSize: 13,
            color: "#6B7280",
          }}
        >
          {subtitle}
        </Typography>
      )}
    </>
  );
};

export default HeaderContent;
