import React, { useEffect, useRef, useState } from "react";
import { Button, Modal, Box, Typography, TextField } from "@mui/material";
import CustomScrollbar from "./CustomScrollbar";
import { BootStrapInput, BootStrapInputClick } from "./Input";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  borderRadius: 3,
  p: 0,
  minWidth: "50vw",
};

const CustomModalInput = ({
  value,
  onChange,
  open,
  headerText,
  subheaderText,
  label,
  name,
  className,
  toggle,
  required,
}) => {
  const inputRef = useRef(null);
  const [againopen, setAgainOpen] = useState(false);
  useEffect(() => {
    let timeout = setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
        const length = inputRef.current.value.length;
        inputRef.current.setSelectionRange(length, length);
      }
    });

    return () => clearTimeout(timeout);
  }, [againopen, inputRef]);
  return (
    <div>
      <BootStrapInputClick
        onClick={() => {
          toggle();
          setAgainOpen(!againopen);
        }}
        label={label}
        value={value}
        name={name}
        className={className}
        required={required}
      ></BootStrapInputClick>
      <Modal
        open={open}
        onClose={toggle}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={style}>
          <div className="modal-header-bg custom-header">
            <Typography
              id="modal-title"
              className="font-size-17"
              sx={{ fontWeight: "bold" }}
            >
              {headerText} <span style={{ color: "red" }}>*</span>
            </Typography>
            <Typography
              className="font-size-14"
              sx={{ mt: 1, color: "#6c757d" }}
            >
              {subheaderText}
            </Typography>
          </div>
          <CustomScrollbar height="50vh">
            <TextField
              inputRef={inputRef}
              autoFocus
              name={name}
              value={value}
              onChange={onChange}
              fullWidth
              multiline
              variant="standard"
              sx={{
                padding: "15px 25px 25px 25px",
                mt: 2,
                border: "none", // Remove border
                "& .MuiInputBase-root": {
                  padding: 0,
                  "&:before": {
                    // Remove the bottom border underline
                    borderBottom: "none",
                  },
                  "&:after": {
                    // Remove the active bottom border underline
                    borderBottom: "none",
                  },
                },
                "& .MuiInputBase-input": {
                  padding: 0, // No padding to avoid any unwanted space
                  fontSize: "14px",
                  lineHeight: "1.5",
                  height: "auto",
                  resize: "none", // Ensure the text area can't be resized manually
                },
                "& textarea": {
                  overflow: "hidden", // Ensure no scrollbar appears
                  resize: "none",
                },
              }}
              InputProps={{
                disableUnderline: true, // Ensure no underline for input
              }}
              placeholder="Lorem Ipsum is simply dummy text of the printing and typesetting industry..."
            />
          </CustomScrollbar>

          <Box sx={{ display: "flex", justifyContent: "end", mt: 3, mb: 3 }}>
            <button onClick={toggle} className="skip-button mr-4">
              Close
            </button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default CustomModalInput;
