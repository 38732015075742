import React from "react";
import PersonIcon from "@mui/icons-material/Person";
import { styled, Typography } from "@mui/material";
import CallIcon from "@mui/icons-material/Call";
import InfoIcon from "@mui/icons-material/Info";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import AddIcon from "@mui/icons-material/Add";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
const ProfileIcon = styled(PersonIcon)({
  color: "#fff",
  fontSize: "40px",
  background: "#005C7B",
  borderRadius: "50%",
  padding: "10px",
});
const ProfileIconWhite = styled(PersonIcon)({
  color: "#fff",
  fontSize: "40px",
});
const CallIconWhite = styled(CallIcon)({
  color: "#fff",
  fontSize: "25px",
});
const FormHeader = styled(Typography)({
  marginBottom: "20px",
  fontWeight: "bold",
});

export {
  ProfileIcon,
  ProfileIconWhite,
  CallIconWhite,
  InfoIcon,
  FormHeader,
  ArrowBackIcon,
  CloudUploadIcon,
  AddIcon,
  AccessTimeIcon,
};
