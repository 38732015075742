import FolderSharedIcon from "@mui/icons-material/FolderShared";
import ArticleIcon from "@mui/icons-material/Article";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import SettingsIcon from "@mui/icons-material/Settings";
import PsychologyIcon from "@mui/icons-material/Psychology";
import { Dashboard } from "@mui/icons-material";
export const SIDEBAR_DATA = [
  {
    name: "projects",
    title: "Projects",
    icon: ArticleIcon,
  },
  {
    name: "deals",
    title: "Deals",
    icon: FolderSharedIcon,
  },
  {
    name: "calender",
    title: "Calender",
    icon: CalendarMonthIcon,
  },

  {
    name: "training",
    title: "Training Logs",
    icon: PsychologyIcon,
  },
];

export const SIDEBAR_DATA_ACCOUNT = [
  {
    name: "dashboard",
    title: "Dashboard",
    icon: Dashboard,
  },
  {
    name: "projects",
    title: "Projects",
    icon: ArticleIcon,
  },
];
