import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  CircularProgress,
} from "@mui/material";

import logo from "../../Assests/Images/vodexLogo.svg";
import collapsibleMenu from "../../Assests/Images/Icons/collapsible-menu.svg";
import dashboardIcon from "../../Assests/Images/Icons/dashboardIcon.svg";
import projectsIcon from "../../Assests/Images/Icons/projectsIcon.svg";
import dealsIcon from "../../Assests/Images/Icons/dealsIcon.svg";
import botSettingsIcon from "../../Assests/Images/Icons/botSettingsIcon.svg";
import trainingLogsIcon from "../../Assests/Images/Icons/trainingLogsIcon.svg";
import settingsIcon from "../../Assests/Images/Icons/settingsIcon.svg";
import bellIcon from "../../Assests/Images/Icons/bellIcon.svg";
import profileIcon from "../../Assests/Images/Icons/profileIcon.svg";
import searchIcon from "../../Assests/Images/Icons/searchIcon.svg";
import ellipsisIcon from "../../Assests/Images/Icons/ellipsisIcon.svg";
import chevronLeft from "../../Assests/Images/Icons/chevronLeft.svg";
import gettingStarted from "../../Assests/Images/Icons/getting-started.svg";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import CallSettings from "../../Components/ProjectSection/Projects/CallSettingsScreen";
import CommanModal from "../../Components/CommonComponents/CommanModal";
import NotificationPanel from "../../Components/Pages/Dashboard/Notifications";
import AgentSettings from "../../Components/Pages/AgentSettings";

const styles = {
  logo: {
    marginRight: "0.5rem",
    maxWidth: "100%",
    height: "20px",
    width: "20px",
  },
  sidebarContainer: {
    bgcolor: "background.default",
    height: "100vh",
    boxShadow: 1,
    padding: 1,
    display: "flex",
    flexDirection: "column",
    color: "text.primary",
    backgroundColor: "#1E1E2D",
    transition: "width 0.3s ease",
    position: "relative",
  },
  searchbar: {
    "& .MuiOutlinedInput-root": {
      bgcolor: "#2F353E",
      borderRadius: 2,
      color: "white",
      paddingLeft: "6px",
      "& fieldset": {
        border: "none",
      },
      "&:hover fieldset": {
        border: "none",
      },
      "&.Mui-focused fieldset": {
        border: "none",
      },
    },
    "& .MuiInputBase-input": {
      color: "white",
    },
    "& .MuiInputBase-input::placeholder": {
      color: "#9E9FA1",
    },
  },
  logoContainer: {
    display: "flex",
    alignItems: "center",
    p: 1,
  },
  sideNavList: {
    borderRadius: "8px",
    paddingY: 0.75,
    marginBottom: "4px",
    "&:hover": {
      backgroundColor: "#33334A",
      color: "white",
      "& img": {
        filter: "brightness(0) invert(1)",
      },
    },
  },
};

const Sidebar = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [settingModal, setSettingModal] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const projectId = params["*"]?.split("/")[1];
  const progress = 25;

  const [openDrawer, setOpenDrawer] = useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpenDrawer(newOpen);
  };

  const NAVIGATION = [
    {
      segment: "main/getting-started",
      title: "Getting Started",
      icon: gettingStarted,
    },
    { segment: "main/dashboard", title: "Dashboard", icon: dashboardIcon },
    { segment: "main/projects", title: "Projects", icon: projectsIcon },
    {
      segment: `main/projects/${projectId}/deals`,
      title: "Deals",
      icon: dealsIcon,
    },
    {
      segment: `main/${projectId}/calendar`,
      title: "Calendar",
      icon: <CalendarMonthIcon />,
    },
    {
      segment: "bot",
      title: "Bot Settings",
      icon: botSettingsIcon,
    },
    {
      segment: `main/projects/${projectId}/training-logs`,
      title: "Training Logs",
      icon: trainingLogsIcon,
    },
    { segment: "settings", title: "Settings", icon: settingsIcon },
    { segment: "notification" },
  ];

  const activeSegment = NAVIGATION.find(
    (item) => `/${item.segment}` === location.pathname
  )?.segment;

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  const toggle = () => {
    setSettingModal(!settingModal);
  };

  return (
    <Box
      sx={{
        width: isCollapsed ? 60 : 240,
        minWidth: isCollapsed ? 60 : 240,
        ...styles.sidebarContainer,
      }}
    >
      <Box
        sx={{
          ...styles.logoContainer,
          justifyContent: isCollapsed ? "center" : "space-between",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {!isCollapsed && <img src={logo} alt="logo" style={styles.logo} />}
          <Typography
            sx={{
              display: isCollapsed ? "none" : "block",
              color: "white",
              fontSize: "14px",
              fontWeight: "500",
            }}
          >
            ZEN
          </Typography>
        </Box>

        <IconButton onClick={toggleSidebar} sx={{ color: "white" }}>
          <img
            src={collapsibleMenu}
            alt="collapsible menu"
            style={{
              height: "20px",
              width: "20px",
            }}
          />
        </IconButton>
      </Box>

      {!isCollapsed && (
        <Box sx={{ px: 2, py: 1 }}>
          <TextField
            placeholder="Search"
            variant="outlined"
            size="small"
            fullWidth
            sx={styles.searchbar}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton sx={{ color: "#9E9FA1" }}>
                    <img
                      src={searchIcon}
                      alt="search icon"
                      style={{
                        height: "14px",
                        width: "14px",
                      }}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
      )}

      <Box sx={{ height: "1px", bgcolor: "#242931", mx: 2, mt: 2 }} />
      {!projectId && (
        <List>
          {NAVIGATION.slice(0, 1).map((item) => (
            <ListItem
              button
              key={item.segment}
              onClick={() => navigate(`/${item.segment}`)}
              sx={{
                color: activeSegment === item.segment ? "white" : "#9E9FA1",
                justifyContent: isCollapsed ? "center" : "flex-start",
                backgroundColor:
                  activeSegment === item.segment ? "#33334A" : "transparent",
                ...styles.sideNavList,
              }}
            >
              <Box
                position="relative"
                display="inline-flex"
                sx={{ marginRight: "8px" }}
              >
                <svg width="0" height="0">
                  <defs>
                    <linearGradient
                      id="linearColors"
                      x1="0%"
                      y1="0%"
                      x2="100%"
                      y2="100%"
                    >
                      <stop
                        offset="0%"
                        style={{ stopColor: "#4F46E5", stopOpacity: 1 }}
                      />
                      <stop
                        offset="100%"
                        style={{ stopColor: "#B6B6F3", stopOpacity: 1 }}
                      />
                    </linearGradient>
                  </defs>
                </svg>

                <CircularProgress
                  variant="determinate"
                  value={100}
                  sx={{
                    color: "#525B68",
                    position: "absolute",
                    borderRadius: "50%",
                    zIndex: 0,
                    top: 0,
                    left: 0,
                    width: isCollapsed ? "24px !important" : "28px !important",
                    height: isCollapsed ? "24px !important" : "28px !important",
                  }}
                />

                <CircularProgress
                  variant="determinate"
                  value={progress}
                  sx={{
                    color: "transparent",
                    position: "absolute",
                    borderRadius: "50%",
                    zIndex: 1,
                    top: 0,
                    left: 0,
                    width: isCollapsed ? "24px !important" : "28px !important",
                    height: isCollapsed ? "24px !important" : "28px !important",
                    "& .MuiCircularProgress-circle": {
                      stroke: "url(#linearColors)",
                      strokeLinecap: "round",
                    },
                  }}
                />

                <ListItemIcon
                  sx={{
                    color: "#EBA20D66",
                    minWidth: isCollapsed ? 0 : 30,
                    "& img": {
                      height: isCollapsed ? "16px" : "20px",
                      width: isCollapsed ? "16px" : "20px",
                      marginLeft: "4px",
                      marginTop: "4px",
                    },
                  }}
                >
                  <img src={item.icon} alt={item.title} />
                </ListItemIcon>
              </Box>

              {!isCollapsed && (
                <ListItemText
                  primary={item.title}
                  primaryTypographyProps={{
                    sx: {
                      color:
                        activeSegment === item.segment ? "white" : "#9E9FA1",
                      fontSize: "14px",
                      fontWeight: 500,
                    },
                  }}
                />
              )}
              {!isCollapsed && (
                <img
                  src={chevronLeft}
                  alt="chevron left"
                  style={{
                    height: "16px",
                    width: "16px",
                    marginLeft: "auto",
                    filter:
                      activeSegment === item.segment
                        ? "brightness(0) invert(1)"
                        : "none",
                  }}
                />
              )}
            </ListItem>
          ))}
        </List>
      )}
      <List>
        {NAVIGATION.slice(1, 3).map((item) => (
          <ListItem
            button
            key={item.segment}
            onClick={() => navigate(`/${item.segment}`)}
            sx={{
              color: activeSegment === item.segment ? "white" : "#9E9FA1",
              justifyContent: isCollapsed ? "center" : "flex-start",
              backgroundColor:
                activeSegment === item.segment ? "#33334A" : "transparent",
              ...styles.sideNavList,
            }}
          >
            <ListItemIcon
              sx={{
                color: activeSegment === item.segment ? "white" : "#9E9FA1",
                minWidth: isCollapsed ? 0 : 30,
                "& img": {
                  height: "16px",
                  width: "16px",
                  filter:
                    activeSegment === item.segment
                      ? "brightness(0) invert(1)"
                      : "none",
                },
              }}
            >
              <img src={item.icon} alt={item.title} />
            </ListItemIcon>
            {!isCollapsed && (
              <ListItemText
                primary={item.title}
                primaryTypographyProps={{
                  sx: {
                    color: activeSegment === item.segment ? "white" : "#9E9FA1",
                    fontSize: "14px",
                    fontWeight: 500,
                  },
                }}
              />
            )}
          </ListItem>
        ))}
      </List>
      {projectId && (
        <List>
          {NAVIGATION.slice(3, 7).map((item) => (
            <ListItem
              button
              key={item.segment}
              onClick={() =>
                item.segment === "bot" ? toggle() : navigate(`/${item.segment}`)
              }
              sx={{
                color: activeSegment === item.segment ? "white" : "#9E9FA1",
                justifyContent: isCollapsed ? "center" : "flex-start",
                backgroundColor:
                  activeSegment === item.segment ? "#33334A" : "transparent",
                ...styles.sideNavList,
              }}
            >
              <ListItemIcon
                sx={{
                  color: activeSegment === item.segment ? "white" : "#9E9FA1",
                  minWidth: isCollapsed ? 0 : 30,
                  "& img": {
                    height: "16px",
                    width: "16px",
                    filter:
                      activeSegment === item.segment
                        ? "brightness(0) invert(1)"
                        : "none",
                  },
                }}
              >
                <img src={item.icon} alt={item.title} />
              </ListItemIcon>

              {!isCollapsed && (
                <ListItemText
                  primary={item.title}
                  primaryTypographyProps={{
                    sx: {
                      color:
                        activeSegment === item.segment ? "white" : "#9E9FA1",
                      fontSize: "14px",
                      fontWeight: 500,
                    },
                  }}
                />
              )}
            </ListItem>
          ))}
        </List>
      )}

      <Box sx={{ mt: "auto" }}>
        <List>
          {NAVIGATION.slice(7, 9).map((item) => (
            <ListItem
              button
              key={item.segment}
              onClick={item.segment === "settings" ? toggleDrawer(true): () => item.segment}
              sx={{
                color: activeSegment === item.segment ? "white" : "#9E9FA1",
                justifyContent: isCollapsed ? "center" : "flex-start",
                backgroundColor:
                  activeSegment === item.segment ? "#33334A" : "transparent",
                ...styles.sideNavList,
              }}
            >
              <ListItemIcon
                sx={{
                  color: activeSegment === item.segment ? "white" : "#9E9FA1",
                  minWidth: isCollapsed ? 0 : 30,
                  "& img": {
                    height: "16px",
                    width: "16px",
                    filter:
                      activeSegment === item.segment
                        ? "brightness(0) invert(1)"
                        : "none",
                  },
                }}
              >
                {item.segment === "notification" || (
                  <img src={item.icon} alt={item.title} />
                )}
                {item.segment === "notification" && <NotificationPanel />}
              </ListItemIcon>
              {!isCollapsed && (
                <ListItemText
                  primary={item.title}
                  primaryTypographyProps={{
                    sx: {
                      color:
                        activeSegment === item.segment ? "white" : "#9E9FA1",
                      fontSize: "14px",
                      fontWeight: 500,
                    },
                  }}
                />
              )}
            </ListItem>
          ))}
        </List>
        <ListItem
          button
          sx={{
            color: "white",
            justifyContent: isCollapsed ? "center" : "flex-start",
            borderRadius: "8px",
            backgroundColor: "#1F252D",
            gap: 0.5,
          }}
        >
          <ListItemIcon
            sx={{
              color: "#9E9FA1",
              minWidth: isCollapsed ? 0 : 30,
              "& img": {
                height: "24px",
                width: "24px",
              },
            }}
          >
            <img src={profileIcon} alt="profile icon" />
          </ListItemIcon>
          {!isCollapsed && (
            <ListItemText
              primary="Zen’s Buddy [ You ]"
              primaryTypographyProps={{
                sx: {
                  color: "white",
                  fontSize: "14px",
                  fontWeight: 500,
                },
              }}
            />
          )}
          {!isCollapsed && (
            <img
              src={ellipsisIcon}
              alt="ellipsis"
              style={{
                height: "16px",
                width: "16px",
                marginLeft: "auto",
              }}
            />
          )}
        </ListItem>
      </Box>
      <CommanModal
        body={<CallSettings toggle={toggle} />}
        headerText={"Bot Settings"}
        open={settingModal}
        subheaderText={""}
        toggle={toggle}
        minWidth={"40vw"}
        footer={false}
      />
      <AgentSettings open={openDrawer} toggleDrawer={toggleDrawer} />
    </Box>
  );
};

export default Sidebar;
