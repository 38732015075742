const styles = {
  dashboardImage: {
    width: "100%",
    height: "auto",
    position: "absolute",
    top: 0,
    zIndex: 0,
  },
  container: {
    flex: "3",
    bgcolor: "white",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%",
    position: "relative",
    color: "#1C233E",
  },
  box: {
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
    borderRadius: "10px",
    padding: 2.5,
    zIndex: 1,
    marginTop: "10%",
    width: {
      xs: "100%",
      sm: "80%",
      lg: "50%",
    },
    bgcolor: "white",
  },
  progress: {
    height: 8,
    borderRadius: 5,
    backgroundColor: "#E7EAF1",
    marginTop: 2,
    marginBottom: 3,
    "& .MuiLinearProgress-bar": {
      background: "linear-gradient(to right, #4F46E5, #8D8CD8)",
    },
  },
  stepBox: {
    border: "1px solid #EDF5F2",
    borderRadius: "8px",
    overflow: "hidden",
  },
  completedButton: {
    padding: "2px 4px",
    borderRadius: "6px",
    bgcolor: "#F5FFFA",
    color: "#28764B",
    fontSize: "11px",
    fontWeight: "700",
    border: "2px dotted #C3E2D1",
  },
};

export default styles;
