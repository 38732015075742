import React, { useEffect, useState } from "react";
import { Box, Dialog, DialogContent } from "@mui/material";
import Welcome from "./Welcome";
import { useDispatch, useSelector } from "react-redux";
import {
  getProjectByIdAction,
  saveProjectsAction,
  updateProjectsAction,
} from "../../Redux/Actions/Project.action";
import { fileUploadAction } from "../../Redux/Actions/Common.actions";
import { getSitemapUrlsAction } from "../../Redux/Actions/AI.actions";
import { debounce } from "../../Components/CommonComponents/Common";
import AIDetailsStep from "./FirstStage/AIDetailsStep";
import PersonalizeAIDetailsStep from "./FirstStage/PersonalizeAIDetailsStep";
import ConnectProjectStep from "./FirstStage/ConnectProjectStep";
import FinalizeAIDetailsStep from "./FirstStage/FinalizeAIDetailsStep";
import HeaderContent from "../Shared/HeaderContent";
import FooterContent from "./FooterContent";
import { useLocation } from "react-router-dom";
import Steps from "../Shared/Steps";

const styles = {
  dialogBox: {
    "& .MuiBackdrop-root": {
      background: "#14192FCC",
      backdropFilter: "blur(30px)",
    },
  },
  dialogContent: {
    display: "flex",
    flexDirection: "column",
    paddingBottom: 2.5,
    minHeight: 400,
    borderRadius: 4,
    position: "relative",
  },
};

const headerContent = [
  {
    title: "Excited to see your AI SDR",
    subtitle: "Start by telling us",
  },
  {
    title: "Personalize your AI SDR",
    subtitle: "We want to know more about your company",
  },
  {
    title: "Connect Project",
    subtitle: "Great, now connect your project",
  },
  {
    title: "Hey, I’am your Luna AI SDR 👋🏻",
    subtitle: "",
  },
];

const headerSteps = [
  {
    step: 1,
    label: "Project Details",
  },
  {
    step: 2,
    label: "Personalization",
  },
  {
    step: 3,
    label: "Connect",
  },
  {
    step: 4,
    label: "Connected",
  },
];

const extractMainUrl = (urls) => {
  if (!urls.length) return "";
  const url = new URL(urls[0]);
  return `${url.protocol}//${url.hostname}`;
};

const GettingStarted = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const stepFromQuery = parseInt(queryParams.get("step"), 10);
  const projectUpdate = queryParams.get("update");  
  const [step, setStep] = useState(stepFromQuery || 0);
  const [openModal, setOpenModal] = useState(true);
  const [files, setFiles] = useState([]);
  const [websiteUrls, setWebsiteUrls] = useState([]);
  const [onboardingState, setOnboardingState] = useState({
    projectId: localStorage.getItem("newProjectId") || "",
    projectName: "",
    agentName: "",
    pdfs: [],
    textFiles: [],
    mainUrl: "",
  });
  const projectList = useSelector((state) => state.ProjectReducer.projectList);

  useEffect(() => {
    if (!isNaN(stepFromQuery)) {
      setStep(stepFromQuery);
      setOpenModal(true);
    }
  }, [stepFromQuery]);

  const fetchData = async () => {
    let projectData;
    if (projectList.length > 0) {
      projectData = projectList.find(
        (project) => project._id === onboardingState?.projectId
      );
    } else {
      projectData = await dispatch(
        getProjectByIdAction(onboardingState?.projectId)
      );
    }
    if (!projectData) return;
    const { websites = [], pdfs } = projectData;

    const mainUrl = extractMainUrl(websites);

    setOnboardingState((prev) => ({
      ...prev,
      ...projectData,
      mainUrl,
    }));
    setFiles(pdfs);
    setWebsiteUrls(websites.map((url) => url.split(mainUrl)[1]));
  };  

  useEffect(() => {
    if (onboardingState?.projectId) fetchData();
  }, []);

  const closeModal = () => {
    setOpenModal(false);
    localStorage.removeItem("newProjectId");
  };

  const uploadFiles = async () => {
    const newFiles = files.filter((file) => !file.url);
  
    if (newFiles.length > 0) {
      const updatedPdfs = [...onboardingState.pdfs];
      const updatedTextFiles = [...onboardingState.textFiles];
      const formData = new FormData();
  
      for (const file of newFiles) {
        formData.append("file", file);
        formData.append("fileName", "FileId");
  
        try {
          const response = await dispatch(fileUploadAction(formData));
          const fileUrl = response.URL;
  
          const fileList = fileUrl.includes(".pdf") ? updatedPdfs : updatedTextFiles;
          fileList.push({ name: file.name, url: fileUrl });
        } catch (error) {
          console.error(`Error uploading file ${file.name}:`, error);
        }
  
        formData.delete("file");
        formData.delete("fileName");
      }
  
      setOnboardingState((prev) => ({
        ...prev,
        pdfs: updatedPdfs,
        textFiles: updatedTextFiles,
      }));
  
      return { pdfFiles: updatedPdfs, otherFiles: updatedTextFiles };
    }
  
    const pdfFiles = files.filter(file => file.name.endsWith('.pdf'));
    const otherFiles = files.filter(file => !file.name.endsWith('.pdf'));
  
    return {
      pdfFiles: pdfFiles.length < onboardingState.pdfs.length ? pdfFiles : onboardingState.pdfs,
      otherFiles: otherFiles.length < onboardingState.textFiles.length ? otherFiles : onboardingState.textFiles,
    };
  };

  const getWebsiteUrls = () => websiteUrls.map((url) => `${onboardingState.mainUrl}${url}`);

  const saveProject = async () => {
    try {
      let updatedOnboardingState = { ...onboardingState };
  
      if (step === 0 && !updatedOnboardingState?.projectId) {
        const res = await dispatch(saveProjectsAction(updatedOnboardingState));
        localStorage.setItem("newProjectId", res._id);
        updatedOnboardingState = { ...updatedOnboardingState, projectId: res._id };
      } else {
        await dispatch(
          updateProjectsAction(
            updatedOnboardingState,
            onboardingState.projectId
          )
        );
      }
      if (step === 1) {
        const { pdfFiles, otherFiles } = await uploadFiles(files);
        updatedOnboardingState = {
          ...updatedOnboardingState,
          pdfs: pdfFiles.map((file) => ({
            name: file.name,
            url: file.url,
          })),
          textFiles: otherFiles,
          websites: getWebsiteUrls(),
        };
        await dispatch(
          updateProjectsAction(
            updatedOnboardingState,
            onboardingState.projectId
          )
        );
      }

      setOnboardingState(updatedOnboardingState);
      if (step < 3) setStep(step + 1);
    } catch (error) {
      console.error(error);
    }
  };

  const handleBack = () => {
    if (step > 0) setStep(step - 1);
  };

  const fetchWebsitesData = async (value) => {
    let obj = {
      website: value,
      skip_ethics: false,
    };
    let res = await dispatch(getSitemapUrlsAction(obj));
    if (res?.urls?.length > 0) {
      let localUrls = res.urls.map((url) => url.split(value)[1]);
      setWebsiteUrls([...localUrls]);
    }
  };

  const debouncedFetchData = debounce(fetchWebsitesData, 4000);

  const handleChange = (e) => {
    if (e.target.name === "mainUrl") {
      debouncedFetchData(e.target.value);
    }
    setOnboardingState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const renderStepContent = () => {
    switch (step) {
      case 0:
        return (
          <AIDetailsStep
            projectUpdate={projectUpdate}
            onboardingState={onboardingState}
            handleChange={handleChange}
          />
        );
      case 1:
        return (
          <PersonalizeAIDetailsStep
            onboardingState={onboardingState}
            websiteUrls={websiteUrls}
            setWebsiteUrls={setWebsiteUrls}
            handleChange={handleChange}
            files={files}
            setFiles={setFiles}
          />
        );
      case 2:
        return <ConnectProjectStep onboardingState={onboardingState} />;
      case 3:
        return <FinalizeAIDetailsStep />;
      default:
        return null;
    }
  };

  return (
    <>
      <Welcome />
      <Dialog
        open={openModal}
        fullWidth
        disableEscapeKeyDown
        sx={styles.dialogBox}
      >
        <DialogContent sx={styles.dialogContent}>
          <Box pb={1}>
            <Steps
              currentStep={step}
              setCurrentStep={setStep}
              steps={headerSteps}
              closeModal={closeModal}
            />
          </Box>
          <HeaderContent title={headerContent[step].title} subtitle={headerContent[step].subtitle} />
          <Box
            style={{
              flexGrow: 1,
              overflowY: "auto",
              marginBottom: 3,
              marginTop: 16,
            }}
          >
            {renderStepContent()}
          </Box>
          {step !== 2 && (
            <FooterContent
              currentStep={step}
              handleBack={handleBack}
              handleNext={saveProject}
              showBackButton={step === 1 ? true : false}
              nextButtonLabel={step === 3 ? "Okay, I’ll wait" : "Save & Next"}
            />
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default GettingStarted;
