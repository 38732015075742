import { API } from "../Constants/ApiRoutes";
import { BASEURL, axiosClient } from "../Constants/Axios";

export const hubspotRedirectService = async (code, projectId) => {
  try {
    let res = await axiosClient.get(
      `${BASEURL}${API.COMMON.HUBSPOT_REDIRECT}?code=${code}&projectId=${projectId}`
    );
    return res;
  } catch (err) {
    console.log(err);
    throw err;
  }
};
export const emailRedirectService = async (code, projectId) => {
  try {
    let res = await axiosClient.get(
      `${BASEURL}${API.COMMON.GMAIL_REDIRECT}?code=${code}&projectId=${projectId}`
    );
    return res;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getHubspotPipelineService = async () => {
  try {
    let res = await axiosClient.get(
      `${BASEURL}${API.COMMON.GET_HUBSPOT_PIPELINE}`
    );
    return res;
  } catch (err) {
    console.log(err);
    throw err;
  }
};
