import { projectsType } from "../Types/All.types";

const initialState = {
  projectList: [],
  selectedProject: JSON.parse(localStorage.getItem("selectedProject")) || {},
};

function ProjectReducer(state = initialState, action) {
  switch (action.type) {
    case projectsType.GET_PROJECTS_LIST: {
      return { ...state, projectList: action.payload };
    }
    case projectsType.GET_SELECTED_PROJECT: {
      return { ...state, selectedProject: action.payload };
    }
    default:
      return state;
  }
}

export default ProjectReducer;
